import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { ModalService } from "./modal.service";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() modalType: string; // 'profile-marks',
  @Input() showBackBtn: boolean = true;
  @Input() fullHeight: boolean = false;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() svgImage? : string;
  @Input() confirmButton: boolean = false;
  @Input() confirmButtonPlaceholder: string;
  @Input() rejectButton: boolean = false; 
  @Input() rejectButtonPlaceholder?: string;
  @Input() isLoading: boolean = false;

  @Output() onOutsideModalClicked = new EventEmitter<any>();
  @Output() onConfirmButtonClicked = new EventEmitter<any>();
  @Output() onRejectButtonClicked = new EventEmitter<any>();
  private element: any;

  @ViewChild("modalEl") modalEl;
  focusedElBeforeOpen: any;

  modalStyle: any = {};
  modalBodyStyle: any = {};

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;
    // Devo assicurarmi di avere l'id della modale
    if (!this.id) {
      return;
    }

    // Affinché venga resa visibile ovunque, la appendo appena dopo la chiusura del </body>
    document.body.appendChild(this.element);

    // Chiudo la modale al click sullo sfondo
    this.element.addEventListener("click", (e: any) => {
      if (e.target.className === "modal") {
        modal.close();
        this.onOutsideModalClicked.emit()
      }
    });

    // Aggiungo la modale al servizio affinché venga resa accessibile dai vari componenti
    this.modalService.add(this);

    this.modalStyle = {
      padding: this.fullHeight ? 0 : "40px 15px",
    };

    this.modalBodyStyle = {
      "max-width": this.fullHeight ? "100%" : "600px",
      height: this.fullHeight ? "100%" : "auto",
      "background-color": this.fullHeight ? "transparent" : "#FFFFFF",
    };
  }

  // Apre la modale
  open(): void {
    this.focusedElBeforeOpen = document.activeElement;
    this.element.style.display = "block";
    document.body.classList.add("modal-open");
    setTimeout(() => {
      this.modalEl.nativeElement.focus();
    }, 500);
  }

  // Chiude la modale
  close(): void {
    this.element.style.display = "none";
    document.body.classList.remove("modal-open");
  }

  confirmButtonClicked(){
    this.onConfirmButtonClicked.emit();
  }

  rejectButtonClicked(){
    this.onRejectButtonClicked.emit();
  }

  // Quando la direttiva è distrutta, tolgo la modale dal servizio
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    if (typeof this.element.remove === "function") {
      this.element.remove();
    } else {
      this.element.parentNode.removeChild(this.element);
    }
  }
}
