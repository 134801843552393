/*
 * Guard utilizzato per ogni route dell'applicazione. Esso controlla se il browser e il device dell'utente sono supportati.
 * Qualora uno dei due non lo fosse, c'è un redirect automatico alla pagina di errore dedicata
*/

import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, map, tap } from 'rxjs/operators';

// Import dello State dell'applicativo
import * as fromApp from '../../ngrx/app.reducers';

@Injectable()
export class CompatibilityGuard  {
    constructor(private store: Store<fromApp.AppState>, private route: ActivatedRoute, private router: Router) {
    }

    // Verifica il browser utilizzato dall'utente
    browserNameAndVersion = () => {
        let ua = navigator.userAgent;
        let tem = null;
        let M = ua.match(/(crios|opera|chrome|safari|firefox|msie|trident|netscape(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M;
    }

    // Controlla se l'utente sta usando un device non supportato
    isBlockedDevice = () => {
        // Adapted from http://www.detectmobilebrowsers.com
        let ua = window['navigator']['userAgent'] || window['navigator']['vendor'] || window['chrome'];
        // Checks for iOs, Blackberry, Opera Mini, and Windows mobile devices
        return (/Silk|BlackBerry|Opera Mini|IEMobile/).test(ua);
    }

    // Verifica se il browser utilizzato dall'utente non è supportato
    isBlockedBrowser = () => {
        // Prima recupero il nome del browser
        let userBrowser = this.browserNameAndVersion();
        // Di default, non è bloccato
        let isBrowserBlocked = false;

        if (userBrowser[0] === 'Netscape') {
            // Per iPad da dentro Crodova (5+)
            if (parseInt(userBrowser[1].substring(0, 2), 10) < 5) {
                isBrowserBlocked = true;
            }
        }
        else if (userBrowser.indexOf('Opera') != -1) {
            // Opera
            isBrowserBlocked = true;
        }
        /*
        else if (userBrowser.indexOf('Safari') != -1) {
            isBrowserBlocked = true;
        }
        */
        else if (userBrowser[0] === 'MSIE') {
            // IE 10
            if (parseInt(userBrowser[1], 10) <= 10) {
                isBrowserBlocked = true;
            }
        }
        else if (userBrowser[0] === 'Edge') {
            // Microsoft EDGE
            if (parseInt(userBrowser[1], 10) < 12) {
                isBrowserBlocked = true;
            }
        }
        return isBrowserBlocked;
    }

    // Il route non è attivato se il browser oppure il device che sta utilizzando l'utente non è supportato. In questi casi effetto un redirect alla pagina di errore compatibilità
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.isBlockedBrowser() || this.isBlockedDevice()) {
            this.router.navigate(['/compatibilityError']);
            return false;
        } else {
            return true;
        }
    }
}