import { Injectable } from '@angular/core';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";
import { Store } from "@ngrx/store";

@Injectable()
export class CanDeactivateDocumentDetailsService {
    constructor(
        private store: Store<fromApp.AppState>) { }

    canDeactivate(): boolean | Promise<boolean> {
        this.store.dispatch(new CoreActions.SetSelectedDocumentDetails(null));
        this.store.dispatch(new CoreActions.SetSelectedDocumentDetailsMainActionValue(null));
        this.store.dispatch(new CoreActions.SetSelectedDocumentDetailsMainActionText(null));
        return true;
    }
}