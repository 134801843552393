import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from "@angular/core";
import {
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlNativeDateTimeModule,
  OwlDateTimeIntl,
  MomentDateTimeAdapter,
  DateTimeAdapter,
} from '@busacca/ng-pick-datetime';

import { AppTranslateModule } from "../translate/translate.module";
import { AuthGuard } from "../auth/services/auth-guard.service";
import { AuthService } from "../auth/services/auth.service";
import { CompatibilityErrorComponent } from "./compatibility-error/compatibility-error.component";
import { CompatibilityGuard } from "../shared/services/compatibility-guard.service";
import { ErrorPageComponent } from "./error/error-page.component";
import { HeaderComponent } from "./header/header.component";
import { HrbpGuard } from "../shared/services/hrbp-guard.service";
import { GuruGuard } from "../shared/services/guru-guard.service";
import { RecruiterGuard } from "../shared/services/recruiter-guard.service";
import { KalturaPlayerComponent } from "./kaltura-player/kaltura-player.component";
import { LangsService } from "./services/langs.service";
import { LoginGuard } from "../shared/services/login-guard.service";
import { NgxPaginationModule } from "ngx-pagination";
import { PageNotAuthorizedComponent } from "./page-not-authorized/page-not-authorized.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PlayerService } from "./kaltura-player/player.service";
import { RedirectService } from "../shared/services/redirect.service";
import { SharedModule } from "../shared/shared.module";
import { UrlService } from "../shared/services/url.service";
import { HrbpService } from '../hrbp/services/hrbp.service';
import { CanDeactivateDocumentDetailsService } from './services/can-deactivate-document-details.service';
import { GuruService } from "../guru/services/guru.service";
import { RecruiterService } from "../recruiter/services/recruiter.service";
import { ManagerGuard } from "../shared/services/manager-guard.service";
import { DefaultPageComponent } from './defaultPage/default-page.component';

export const MY_NATIVE_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD/MM/YYYY hh:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'MMMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
  upSecondLabel = 'Aggiungi un secondo';
  downSecondLabel = 'Un secondo in meno';
  upMinuteLabel = 'Aggiungi un minuto';
  downMinuteLabel = 'Un minuto in meno';
  upHourLabel = 'Aggiungi un\'ora';
  downHourLabel = 'Un\'ora in meno';
  prevMonthLabel = 'Mese precedente';
  nextMonthLabel = 'Prossimo mese';
  prevYearLabel = 'Anno precedente';
  nextYearLabel = 'Prossimo anno';
  prevMultiYearLabel = '21 anni precedenti';
  nextMultiYearLabel = 'Prossimi 21 anni';
  switchToMonthViewLabel = 'Cambia visuale';
  switchToMultiYearViewLabel = 'Scegli mese e anno';
  cancelBtnLabel = 'Annulla';
  setBtnLabel = 'Conferma';
  rangeFromLabel = 'Da';
  rangeToLabel = 'A';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
}

@NgModule({
  declarations: [
    HeaderComponent,
    PageNotFoundComponent,
    PageNotAuthorizedComponent,
    CompatibilityErrorComponent,
    ErrorPageComponent,
    KalturaPlayerComponent,
    DefaultPageComponent
  ],
  imports: [SharedModule, AppTranslateModule, NgxPaginationModule, OwlNativeDateTimeModule,],
  exports: [HeaderComponent, ErrorPageComponent, KalturaPlayerComponent],
  providers: [
    UrlService,
    RedirectService,
    AuthService,
    AuthGuard,
    CanDeactivateDocumentDetailsService,
    CompatibilityGuard,
    LoginGuard,
    HrbpService,
    RecruiterService,
    GuruService,
    HrbpGuard,
    ManagerGuard,
    GuruGuard,
    RecruiterGuard,
    LangsService,
    PlayerService,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule { }
