import * as AuthActions from "./auth/ngrx/auth.actions";
import * as CoreActions from "./core/ngrx/core.actions";
import * as fromApp from "./ngrx/app.reducers";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription, combineLatest } from "rxjs";

import { AuthService } from "./auth/services/auth.service";
import { IAppDocument } from "src/app/utils";
import { JwtPayload } from "../cm2-commonclasses";
import { RedirectService } from "./shared/services/redirect.service";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { ModalService } from "./shared/components/modal/modal.service";

var moment = require("moment");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  showApplicationLoader: boolean;
  isFetchingLangs$: Observable<boolean>;
  isAuthenticated: boolean;
  result$: Subscription;
  routerEvents: Subscription;
  isMainMenuSidebarOpened: boolean;
  avatar: string;
  loggedUser: JwtPayload;
  isFooterOpened: boolean = false;
  now = new Date();
  initialAnimateEnabled: boolean = false;
  libraryUrl: any;
  notificationList;
  isTouchpointOpened: boolean = false;
  currentYear: string = moment().format("YYYY");

  selectedDocumentDetailsName: string;
  selectedDocumentDetailsMainActionText: string;
  selectedDocumentDetailsMainActionValue: string;

  showFooter: boolean = true;
  hideTopNavigation: boolean = false;
  filePreview: IAppDocument;

  isUsingInternetExplorer: boolean;


  constructor(
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    public authService: AuthService,
    private modalService: ModalService
  ) {
    // Creo e carico lo script di Google Tag Manager per Google Analytics
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${environment.googleTagManagerCode}')`
    document.head.insertBefore(script, null);


    // Recupero le informazioni sull'utente loggato dallo Store applicativo
    let loggedUser$: Observable<any> = this.store.select(fromApp.getLoggedUser);
    // Nome dell'eventuale documento di cui sto guardando i dettagli
    const getSelectedDocumentDetails$: Observable<string> = this.store.select(
      fromApp.getSelectedDocumentDetails
    );
    // Nome dell'azione principale nella pagina del documento di cui sto guardando i dettagli
    const getSelectedDocumentDetailsMainActionText$: Observable<string> = this.store.select(
      fromApp.getSelectedDocumentDetailsMainActionText
    );
    // Valore dell'azione principale nella pagina del documento di cui sto guardando i dettagli
    const getSelectedDocumentDetailsMainActionValue$: Observable<string> = this.store.select(
      fromApp.getSelectedDocumentDetailsMainActionValue
    );
    const getShowFooter$: Observable<boolean> = this.store.select(
      fromApp.getShowFooter
    );
    // Mostro il loader mentre sto caricando le lingue
    this.isFetchingLangs$ = this.store.select(fromApp.isFetchingLangs);
    // Mostro il loader se esplicitato nello Store
    let showApplicationLoader$ = this.store.select(
      fromApp.showApplicationLoader
    );
    // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di sinistra contente il menu principale è aperta
    let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(
      fromApp.isMainMenuSidebarOpened
    );

    const getHideTopNavigation$: Observable<boolean> = this.store.select(
      fromApp.getHideTopNavigation
    );
    const getFilePreview$: Observable<IAppDocument> = this.store.select(
      fromApp.getFilePreview
    );
    // Quando il componente è stato inizializzato, recupero la parte relativa all'autorizzazione dallo state applicativo e l'oggetto globale "globalApplicationData"
    let isAuthenticated$ = this.store.select(fromApp.isAuthenticated);
    // Combito tutti gli observable
    let combinedSelectes$ = combineLatest(
      loggedUser$,
      isAuthenticated$,
      showApplicationLoader$,
      isMainMenuSidebarOpened$,
      getSelectedDocumentDetails$,
      getSelectedDocumentDetailsMainActionText$,
      getSelectedDocumentDetailsMainActionValue$,
      getShowFooter$,
      getHideTopNavigation$,
      getFilePreview$
    );
    this.result$ = combinedSelectes$.subscribe(
      ([
        loggedUser,
        isAuthenticated,
        showApplicationLoader,
        isMainMenuSidebarOpened,
        selectedDocumentDetailsName,
        selectedDocumentDetailsMainActionText,
        selectedDocumentDetailsMainActionValue,
        showFooter,
        hideTopNavigation,
        filePreview,
      ]) => {
        this.loggedUser = loggedUser;
        this.isAuthenticated = isAuthenticated;
        this.showApplicationLoader = showApplicationLoader;
        this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
        setTimeout(() => {
          this.selectedDocumentDetailsName = selectedDocumentDetailsName;
        });
        this.selectedDocumentDetailsMainActionText = selectedDocumentDetailsMainActionText;
        this.selectedDocumentDetailsMainActionValue = selectedDocumentDetailsMainActionValue;
        this.showFooter = showFooter;
        this.hideTopNavigation = hideTopNavigation;
        this.filePreview = filePreview;

        this.isUsingInternetExplorer = this.isInternetExplorerBrowser();

        // Se l'utente usa explorer, apro la modale che suggerisce di cambiare browser
        const switchBrowserModalViewed: string = sessionStorage.getItem('switchBrowserModalViewed');
        if (this.isUsingInternetExplorer && this.loggedUser && this.isAuthenticated && !switchBrowserModalViewed) {
          this.openSwitchBrowserModal();
        }
      }
    );

    // Recupero l'avatar dell'utente loggato dal suo payload, con una foto di default qualora mancasse
    this.avatar =
      (this.loggedUser &&
        this.loggedUser.user &&
        this.loggedUser.user.userOptions &&
        this.loggedUser.user.userOptions.avatarImage) ||
      (this.loggedUser &&
        this.loggedUser.user &&
        this.loggedUser.user.chiaveSesso == "M"
        ? "assets/img/placeholder.svg"
        : "assets/img/placeholder_female.svg");
  }

  isInternetExplorerBrowser() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }
    return false;
  }

  openSwitchBrowserModal() {
    this.modalService.open("switch-browser-modal");
  }

  closeSwitchBrowserModal() {
    sessionStorage.setItem('switchBrowserModalViewed', "true");
    this.modalService.close("switch-browser-modal");
  }

  // Gestisce il click sul'azione principale dell'header nella pagina di dettaglio di un documento
  onDocumentDetailsHeaderMainActionClicked() {
    if (this.selectedDocumentDetailsMainActionValue) {
      if (this.selectedDocumentDetailsMainActionValue === "GO_BACK") {
        this.redirectService.goBack();
      } else {
        this.router.navigate([this.selectedDocumentDetailsMainActionValue]);
      }
    }
  }

  // Attiva l'animazione dell'apertura/chiusura delle sidenav
  activateAnimation(): void {
    if (!this.initialAnimateEnabled) {
      this.initialAnimateEnabled = true;
    }
  }

  // Verifica in quale pagina sono
  isThisCurrentPage(page: string) {
    if (
      page &&
      this.router &&
      this.router.url &&
      this.router.url.indexOf(page) !== -1
    ) {
      return true;
    }

    return false;
  }

  // Ritorna il nome di una pagina
  getPageName(): string {
    let pageName: string = null;
    if (this.isThisCurrentPage("hrbp")) {
      pageName = null; //this.translate.instant("performance.PERFORMANCE_MANAGEMENT");
    }
    return pageName;
  }

  // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav (col menu principale) nello Store
  onMainMenuSidenavClose(): void {
    this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
  }

  touchpointClicked() {
    this.isTouchpointOpened = !this.isTouchpointOpened;
  }

  // Quando il componente è distrutto, eseguo anche l'unsubscribe del cambio di route
  ngOnDestroy() {
    this.routerEvents.unsubscribe();
    this.result$.unsubscribe();
  }

  // Logout dall'applicazione
  onLogout() {
    // Al click sul logout, eseguo il dispatch dell'action che disautentica l'utente, cancella il token (sia dal session storage che dallo State), e cancella il Payload (dato dalla decodifica del Token) dentro lo State
    this.store.dispatch(new AuthActions.Logout());
    // Redirect alla pagina di login
    this.router.navigate(["/login"]);
  }

  // Quando il componente si è inizializzato ed è pronto, devo settare i parametri applicativi come il base URL dell'applicazione. Di questo se ne occuperà il side effect una volta lanciata l'action per recuperare le lingue disponibili a sistema.
  ngOnInit() {
    // Recupero le lingue disponibili
    this.store.dispatch(new CoreActions.GetAvailableLangs());
  }

  onPreviewClose() {
    this.store.dispatch(new CoreActions.SetHideTopNavigation(false));
    this.store.dispatch(new CoreActions.SetFilePreview(null));
  }
}
