<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div class="login-page theme-primary" fxLayout="row" *ngIf="langs && langs.length" fxFlexFill>
    <div class="login-column" fxFlex.sm="50" fxFlex.gt-sm="40" fxFlexFill-xs fxLayout="column" fxLayoutAlign="center center">
        <img width="66" src="assets/img/icons/logo_generali.svg" alt="{{'accessibilityAttributes.GENERALI_LOGO' | translate}}" title="{{'accessibilityAttributes.GENERALI_LOGO' | translate}}">
        <div *ngIf="forgotPassword == false" class="full-width" fxLayout="column" fxLayoutAlign="center center">
            <login-form (onLogin)="onLogin($event)"></login-form>

            <p class="link-red" (click)="forgotPassword = true" style="color: #C5281C!important;text-decoration: underline;">
                {{'generic.FORGOT_PASSWORD' | translate}}
            </p>
        </div>
        <!-- <a (click)="signUp()">{{'login.SIGN_UP' | translate}}</a> -->
        
        <div *ngIf="forgotPassword == true && emailSent == false" class="full-width" fxLayout="column" fxLayoutAlign="center center">

            <p class="label-title margin-top40" style="font-size: 24px">
                {{ 'login.RECOVERY_PASSWORD' | translate }}
            </p>
            <p class="label-desc text-center" (click)="sendMail()">
                {{ 'login.SEND_MAIL_FOR_RECOVERY' | translate }}
            </p>

            <!-- Email -->
            <new-input label="generic.EMAIL" placeholder='generic.WRITE' [value]="email" type="email"
                (onTextChange)="email = $event; emailValid();" style="margin: 52px 0px;">
            </new-input>
            
            <!-- Errore di email non valida -->
            <!--<div *ngIf="!email.valid" class="input-error" fxLayout="row" fxLayoutAlign="start center">
                <span translate="errors.EMAIL_NOT_VALID"></span>
            </div>-->

            <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
                <button class="btn-primary-1 large" role="button" (click)="sendMail()" [disabled]="!emailValid()">
                    <p translate="generic.CONFIRM"></p>
                </button>
                <button class="btn-secondary-1 large" role="button" (click)="forgotPassword = false">
                    <p translate="generic.CANCEL"></p>
                </button>
            </div>
        </div>

        <!-- visible when the mail for the recovery of password is sent -->
        <div *ngIf="emailSent == true" class="full-width" fxLayout="column" fxLayoutAlign="center center">
            <svg-icon src="assets/img/icons/mail.svg" style="margin: 20px 0px"></svg-icon>
            <p class="label-title" style="font-size: 24px">
                {{ 'login.WE_ARE_NEAR' | translate }}
            </p>
            <p class="label-desc text-center">
                {{ 'login.SENT_EMAIL_RECOVERY' | translate }}
            </p>

            <p class="label-desc" style="margin-top: 80px;">
                {{ 'login.EMAIL_NOT_RECEIVED' | translate }}
            </p>
            <p class="link-red" (click)="sendMail()" style="color: #C5281C!important;text-decoration: underline;">
                {{'login.RESUBMIT' | translate}}
            </p>

        </div>
    </div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
    <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>