import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "item-document",
  templateUrl: "./item-document.component.html",
  styleUrls: ["./item-document.component.scss"],
})
export class ItemDocumentComponent implements OnInit {
  @Input() documentName: string;
  @Input() iconRight: string;
  @Input() iconRightAlert?: string;
  @Input() imageSrc: string;
  @Input() isCheckbox?: boolean = false;
  @Input() isChecked: boolean = false;
  @Input() isToggle?: boolean = false;
  @Input() isDisabled?: boolean = false;
  @Input() rightSvg: string = "";

  @Input() textContent?: string;
  @Input() date: string;
  @Input() rightText?: string;
  @Input() statusDocument?: string;
  @Input() isSignUp: any;

  @Input() isToggleChecked: boolean = false;
  @Input() hideBottom: boolean = false;
  @Input() customTitleStyle: any = {};
  @Input() lowerCaseTitle: any = {};

  @Output() onInfoDocClicked = new EventEmitter<any>();
  @Output() onCheckboxClicked = new EventEmitter<any>();
  @Output() onToggleClicked = new EventEmitter<any>();

  @Input() demo: true; //temp var

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  emitOnItemClicked() {
    this.onInfoDocClicked.emit();
  }

  checkboxClicked() {
    this.onCheckboxClicked.emit();
  }

  toggleClicked() {
    if (!this.isDisabled) this.onToggleClicked.emit();
  }
}
