import { BehaviorSubject, Observable } from "rxjs";
import { Client, Message } from "@stomp/stompjs";
import { distinctUntilChanged, pluck } from "rxjs/operators";

import { HrbpService } from "./../../../hrbp/services/hrbp.service";
import { Injectable } from "@angular/core";
import { UserService } from "./../../../users/services/user.service";
import { queue } from "rxjs/internal/scheduler/queue";
import { v4 as uuidv4 } from "uuid";
import { RecruiterService } from "src/app/recruiter/services/recruiter.service";

@Injectable({ providedIn: "root" })
export class ChatService {
  private readonly TAG: string = "__onboarding__uuid";

  private _uuid: string = "";
  private _client: any;

  private subject: BehaviorSubject<any> = new BehaviorSubject({});
  private counter: BehaviorSubject<number> = new BehaviorSubject(0);
  private counterByQueue: BehaviorSubject<{
    [key: string]: number;
  }> = new BehaviorSubject({});

  //_marksDate[id] data del mark dello user(id)
  private _service: UserService | HrbpService | RecruiterService;
  private _queues: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private _isMarkUpdating: boolean = false;
  private readonly UPDATE_MARK_TIMEOUT: number = 7000;
  private _timer: any;

  isInitialized: boolean = false;
  loadedRecords: any = {};

  constructor() {}

  init() {
    this._uuid = uuidv4();
    sessionStorage.setItem(this.TAG, this._uuid);

    return this._uuid;
  }

  //ritorna lo uuid dello user loggato
  getUuid() {
    const uuid = sessionStorage.getItem(this.TAG);
    if (!uuid) {
      return this.init();
    }
    this._uuid = uuid;
    return this._uuid;
  }

  /**
   * Funzione che si sottoscrive alle code passate in input.
   * Attenzione: Prende come endPointWebSocket il valore del primo elemento
   *             dell'array delle code passato in input.
   *             ...anche per il virtual host.
   */
  stompSubscribe(queues: any[]): Promise<void> {
    //* USE @stomp/stompjs START
    return new Promise((resolve: any, reject: any) => {
      if (!queues) {
        return reject({ message: "No queues" });
      }
      this._queues.next(this._sortQueues(queues));
      //No reject?
      if (queues.length === 0) return;

      const options = {
        brokerURL: queues[0].endPointWebSocket,
        connectHeaders: {
          host: queues[0].virtualHost,
          login: sessionStorage.getItem("token"),
          passcode: null,
        },
        debug: (_str) => {
          //   console.log("STOMP DEBUG: ", str);
        },
        reconnectDelay: 20000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
      };
      this._client = new Client(options);

      //Ridefinire la funzione onConnect del client
      this._client.onConnect = async (frame) => {
        //'for' per la sottoscrizione ad ogni coda di queues(l'input)
        for (let q of queues) {
          const parsed = q.queueName.split(".");
          const userId = parsed[3];

          this.subject.next({
            ...this.subject.value,
            [userId]: [],
          });
          this.counterByQueue.next({
            ...this.counterByQueue.value,
            [userId]: 0,
          });
          this.loadedRecords[userId] = 0;
          this._client.subscribe(
            `/amq/queue/${q.queueName}`,
            (message: Message) => {
              if (message.body) {
                const body = JSON.parse(message.body);
                body.isNew = true;
                const index = this.subject.value[userId].findIndex((m) => {
                  const d = new Date(m.messageDate);
                  const d1 = new Date(m.messageDate);
                  const d2 = new Date(m.messageDate);
                  d1.setSeconds(d.getSeconds() - 1);
                  d2.setSeconds(d.getSeconds() + 1);
                  return (
                    d1.getTime() <= new Date(body.messageDate).getTime() &&
                    d2.getTime() >= new Date(body.messageDate).getTime() &&
                    m.senderUserId === body.senderUserId &&
                    m.text === body.text
                  );
                });
                if (index === -1) {
                  let messages = [...this.subject.value[userId], body];
                  messages.sort((a, b) => {
                    if (
                      new Date(a.messageDate).getTime() <=
                      new Date(b.messageDate).getTime()
                    ) {
                      return -1;
                    } else {
                      return 1;
                    }
                  });

                  this.subject.next({
                    ...this.subject.value,
                    [userId]: messages,
                  });

                  this._updateQueuesCounter();
                  this._updateTotalCounter();
                  this._updateLastQueueMessage(
                    messages[messages.length - 1],
                    userId
                  );
                }
              }
              resolve();
            }
          );

          this._client.onStompError = (frame) => {
            return reject({
              message: "Error initializing queue: " + q.queueName,
              queue: q.queueName,
              userId,
              error: frame,
            });
          };
          //Funzione che imposta un marker per ogni user
          //   this._marksDate[userId] = new Date().getTime();
          //   this._setMarkerForQueue(userId);
        }
        this.isInitialized = true;

        resolve();
      };

      this._client.activate();
      //* USE @stomp/stompjs END
    });
  }

  /**
   * Imposta i messaggi di un dato user.
   * @param userId l'id dello user
   * @param messages i messaggi da impostare per lo user
   */
  setMessages(userId: string, messages: any[]) {
    if (!messages || !messages.length) return;

    let list = [...this.subject.value[userId]];
    for (const message of messages) {
      const index = this.subject.value[userId].findIndex((m) => {
        const d = new Date(m.messageDate);
        const d1 = new Date(m.messageDate);
        const d2 = new Date(m.messageDate);
        d1.setSeconds(d.getSeconds() - 1);
        d2.setSeconds(d.getSeconds() + 1);
        return (
          d1.getTime() <= new Date(message.messageDate).getTime() &&
          d2.getTime() >= new Date(message.messageDate).getTime() &&
          m.senderUserId === message.senderUserId &&
          m.text === message.text
        );
      });
      if (index === -1) {
        list.push(message);
      }
    }
    list.sort((a, b) => {
      if (
        new Date(a.messageDate).getTime() <= new Date(b.messageDate).getTime()
      ) {
        return -1;
      } else {
        return 1;
      }
    });

    this.subject.next({
      ...this.subject.value,
      [userId]: list,
    });
  }

  /**
   * CHECK
   * @param name
   */
  watch<T>(name: string): Observable<any> {
    return this.subject
      .pipe(pluck<any, T>(name))
      .pipe(distinctUntilChanged<T>());
  }

  countWatch(): Observable<number> {
    return this.counter.pipe(distinctUntilChanged());
  }

  watchQueueCounter<T>(name: string): Observable<any> {
    return this.counterByQueue
      .pipe(pluck<any, T>(name))
      .pipe(distinctUntilChanged<T>());
  }

  watchQueues(): Observable<any[]> {
    return this._queues.pipe(distinctUntilChanged());
  }

  resetQueueCount(name: string) {
    // tslint:disable-next-line:prefer-for-of
    if (this.subject.value && this.subject.value[name]) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.subject.value[name].length; i++) {
        this.subject.value[name][i].isNew = false;
      }
    }
    this.subject.next({
      ...this.subject.value,
    });
    this._updateQueuesCounter();
    this._updateTotalCounter();
  }

  setService(service: UserService | HrbpService | RecruiterService) {
    this._service = service;
  }

  addChat(_queue, response) {
    const userId = response.userId;
    const q = {
      endPointWebSocket: response.chatResult.endPointWebSocket,
      errorMessage: response.chatResult.errorMessage,
      exchangeName: response.chatResult.exchangeName,
      queueName: response.chatResult.queueName,
      recipientId: response.chatResult.recipientId,
      routingKey: response.chatResult.routingKey,
      virtualHost: response.chatResult.virtualHost,
      recipient: {
        avatarImage: null,
        chiaveSesso: response.chiaveSesso,
        creationDate: new Date(),
        forename: response.forename,
        surname: response.surname,
        recipientId: response.userId,
      },
    };
    this._queues.next(this._sortQueues([...this._queues.value, q]));
    this.subject.next({
      ...this.subject.value,
      [response.userId]: [],
    });
    this.counterByQueue.next({
      ...this.counterByQueue.value,
      [userId]: 0,
    });
    this._client.subscribe(`/amq/queue/${q.queueName}`, (message: Message) => {
      if (message.body) {
        const body = JSON.parse(message.body);
        body.isNew = true;
        const index = this.subject.value[userId].findIndex((m) => {
          const d = new Date(m.messageDate);
          const d1 = new Date(m.messageDate);
          const d2 = new Date(m.messageDate);
          d1.setSeconds(d.getSeconds() - 1);
          d2.setSeconds(d.getSeconds() + 1);
          return (
            d1.getTime() <= new Date(body.messageDate).getTime() &&
            d2.getTime() >= new Date(body.messageDate).getTime() &&
            m.senderUserId === body.senderUserId &&
            m.text === body.text
          );
        });
        if (index === -1) {
          let messages = [...this.subject.value[userId], body];
          messages.sort((a, b) => {
            if (
              new Date(a.messageDate).getTime() <=
              new Date(b.messageDate).getTime()
            ) {
              return -1;
            } else {
              return 1;
            }
          });

          this.subject.next({
            ...this.subject.value,
            [userId]: messages,
          });

          this._updateQueuesCounter();
          this._updateTotalCounter();
          this._updateLastQueueMessage(messages[messages.length - 1], userId);
        }
      }
    });
  }

  /** Aggiorna il marker dei messaggi nel be.
   * Input: id dello user
   */
  async updateMarkDate(recipientId, force: boolean = false) {
    try {
      if (force || !this._isMarkUpdating) {
        this._isMarkUpdating = true;
        this._timer = setTimeout(async () => {
          const res = await this._service
            .chat_updateMarkMessage(recipientId)
            .toPromise();
          this._isMarkUpdating = false;
          if (res.error) {
            window.console.error(res.error);

            return;
          }
        }, this.UPDATE_MARK_TIMEOUT);
      }
      this.resetQueueCount(recipientId);
    } catch (e) {
      window.console.error(e);
    }
  }

  async forceUpdate(recipientId) {
    try {
      if (this._timer) {
        clearTimeout(this._timer);
      }
      await this._service.chat_updateMarkMessage(recipientId).toPromise();
    } catch (error) {
      console.error("ERROR UPDATING CHECK MARK");
    }
  }

  private _updateTotalCounter() {
    let total = 0;
    // tslint:disable-next-line: forin
    for (const key in this.counterByQueue.value) {
      total += this.counterByQueue.value[key];
    }
    this.counter.next(total);
  }

  private _updateQueuesCounter() {
    // tslint:disable-next-line: forin
    for (const queue in this.subject.value) {
      let total = 0;
      for (const message of this.subject.value[queue]) {
        if (message.isNew) {
          total += 1;
        }
      }

      this.counterByQueue.next({
        ...this.counterByQueue.value,
        [queue]: total,
      });
    }
  }

  private _updateLastQueueMessage(message: any, recipientId: string) {
    const queues = [...this._queues.value];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < queues.length; i++) {
      if (recipientId === queues[i].recipientId) {
        queues[i].lastMessage = {
          date: message.messageDate,
          recipientId: message.targetUserId,
          senderId: message.senderUserId,
          text: message.text,
        };

        this._queues.next([...this._sortQueues(queues)]);

        return;
      }
    }
  }

  private _sortQueues(queues) {
    if (!queues || queues.length === 0) {
      return queues;
    }
    queues.sort((a, b) => {
      if (!a.lastMessage && !b.lastMessage) {
        return -1;
      }
      if (!a.lastMessage && b.lastMessage) {
        return 1;
      }
      if (a.lastMessage && !b.lastMessage) {
        return -1;
      }
      if (
        new Date(a.lastMessage.date).getTime() <=
        new Date(b.lastMessage.date).getTime()
      ) {
        return 1;
      } else {
        return -1;
      }
    });

    return queues;
  }
}