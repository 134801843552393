<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div class="login-page theme-primary" fxLayout="row" *ngIf="langs && langs.length && token && userId" fxFlexFill>
	<div class="login-column" fxFlex.sm="50" fxFlex.gt-sm="40" fxFlexFill-xs fxLayout="column"
		fxLayoutAlign="center center">
		<img width="66" src="assets/img/icons/logo_generali.svg"
			alt="{{'accessibilityAttributes.GENERALI_LOGO' | translate}}"
			title="{{'accessibilityAttributes.GENERALI_LOGO' | translate}}">

		<!-- Pannello di recupero password (e qui bisogna mettere una nuova password) -->
		<form class="login-wrapper" (ngSubmit)="onRecoverPassword(newPswForm)" #newPswForm="ngForm">
			<!-- Nascondo il form se sto facendo la chiamata ai servizi -->
			<ng-container *ngIf="!showLoader">
				<!-- Password -->
				<div fxLayout="row" class="full-width" fxLayoutAlign="center center">
					<div class="input-wrapper-style-guides">
						<div class="title-input margin-bottom8" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
							<h5 translate="generic.NEW_PASSWORD"></h5>
						</div>
						<div class="input-bar" fxLayout="row" fxLayoutAlign="center center" style="position: relative;">
							<input class="full-width input-box"
								pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !#$%&'()*+,-./:;<=>?@^_`{|}~])[A-Za-z\d$@$!%*?&].{8,}"
								attr.aria-label="{{'errors.TYPE_PASSWORD' | translate}}" [type]="passwordType" id="newPsw"
								name="newPsw" placeholder="{{'errors.TYPE_PASSWORD' | translate}}" ngModel
								#newPsw="ngModel" required>
							<span class="show-password" (click)="changePasswordType()">
								<span *ngIf="passwordType === 'password'" class="strikethrough"></span>
								<span class="material-icons">remove_red_eye</span>
							</span>
						</div>
						<!-- Errori form -->
						<div *ngIf="!newPsw.valid && (newPsw.touched || newPsw.dirty)" class="input-error"
							fxLayout="row" fxLayoutAlign="start center">
							<div *ngIf="newPsw.errors.required">
								<span translate="errors.TYPE_PASSWORD"></span>
							</div>
							<div *ngIf="newPsw.errors.pattern">
								<span translate="login.errors.PATTERN"></span>
							</div>
						</div>
					</div>
				</div>

				<div class="space15"></div>

				<!-- Spazio aggiuntivo se presente l'errore del pattern nel form-->
				<div *ngIf="!newPsw.valid && (newPsw.touched || newPsw.dirty) && newPsw && newPsw.errors && newPsw.errors.pattern"
					class="space50"></div>

				<!-- Ripeti password -->
				<div fxLayout="row" class="full-width" fxLayoutAlign="center center">
					<div class="input-wrapper-style-guides">
						<div class="title-input margin-bottom8" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
							<h5 translate="generic.REPEAT_PASSWORD"></h5>
						</div>
						<div class="input-bar" fxLayout="row" fxLayoutAlign="center center" style="position: relative;">
							<input class="full-width input-box"
								attr.aria-label="{{'login.REPEAT_PASSWORD' | translate}}" [type]="passwordType"
								id="repeatPassword"
								pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}"
								placeholder="{{'login.REPEAT_PASSWORD' | translate}}" name="repeatPassword" ngModel
								#repeatPassword="ngModel" required>
							<span class="show-password" (click)="changePasswordType()">
								<span *ngIf="passwordType === 'password'" class="strikethrough"></span>
								<span class="material-icons">remove_red_eye</span>
							</span>
						</div>

						<!-- Errori form -->
						<div *ngIf="!repeatPassword.valid && (repeatPassword.touched || repeatPassword.dirty)"
							class="input-error" fxLayout="row" fxLayoutAlign="start center">
							<div *ngIf="repeatPassword.errors.required">
								<span translate="errors.TYPE_PASSWORD"></span>
							</div>
							<div *ngIf="repeatPassword.errors.pattern">
								<span translate="login.errors.PATTERN"></span>
							</div>
						</div>

						<!-- Spazio aggiuntivo se presente l'errore del pattern nel form-->
						<div *ngIf="!repeatPassword.valid && (repeatPassword.touched || repeatPassword.dirty) && repeatPassword && repeatPassword.errors && repeatPassword.errors.pattern"
							class="space50"></div>

						<!-- Password non corrisponde -->
						<div *ngIf="repeatPassword.valid && repeatPassword.touched && !areNewPswSame(newPswForm)"
							class="input-error" fxLayout="row" fxLayoutAlign="start center">
							<span translate="login.PASSWORD_DONT_MATCH"></span>
						</div>
					</div>
				</div>
			</ng-container>

			<div *ngIf="showLoader" class="full-width">
				<generic-list-placeholder *ngFor="let number of [1,2,3,4,5]"></generic-list-placeholder>
			</div>

			<!-- Pulsante per il login -->
			<div fxLayout="row" class="full-width" fxLayoutAlign="center end">
				<button class="btn-primary-1 large margin-top40 margin-bottom30" type="submit"
					[disabled]="!newPswForm.valid || !areNewPswSame(newPswForm)" role="button">
					<p translate="generic.CONFIRM"></p>
				</button>
			</div>
		</form>
	</div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
	<p translate="errors.NO_AVAILABLE_LANG"></p>
</div>

<!-- Modale di conferma operazione -->
<modal id="successModal" [showBackBtn]="false" confirmButton="true" [title]="'generic.PASSWORD_SET_TITLE' | translate"
	confirmButtonPlaceholder="OK" (onConfirmButtonClicked)="closeSuccessModal()">
	<div fxLayout="column" fxLayoutGap="12px">
		<p>{{'generic.PASSWORD_SET' | translate}}</p>
	</div>
</modal>