import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from 'src/app/shared/services/url.service';
import { Router } from '@angular/router';
import { JwtPayload } from 'atfcore-commonclasses';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-default-page',
    template: ''
})
export class DefaultPageComponent implements OnDestroy {
    result$: Subscription;

    constructor(private store: Store<fromApp.AppState>,
        private router: Router,
        private urlService: UrlService) {
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
        const isRecruiter$: Observable<boolean> = this.store.select(fromApp.getIsRecruiter);
        const isManager$: Observable<boolean> = this.store.select(fromApp.getIsManager);
        const isGuru$: Observable<boolean> = this.store.select(fromApp.getIsGuru);
        const isHrbpPro$: Observable<boolean> = this.store.select(fromApp.getIsHrbpPro);
        const isZone$: Observable<boolean> = this.store.select(fromApp.getIsZoneManager);

        this.result$ = combineLatest([
            combineLatest([loggedUser$, isAdmin$, isRecruiter$, isManager$]),
            combineLatest([isGuru$, isHrbpPro$, isZone$]),
        ]).subscribe(
            ([firstObject, secondObject]) => {
                if (firstObject[0] && firstObject[0].user) {
                    // Eseguo il redirect in base alle auth dell'utente
                    const redirectPage = this.urlService.getDefaultRedirectPage(firstObject[1], firstObject[2], firstObject[3], secondObject[0], secondObject[1], secondObject[2]);
                    this.router.navigate([redirectPage]);
                }
            });
    }

    ngOnDestroy() {
        this.result$.unsubscribe();
    }
}