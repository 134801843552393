/*
 * Modulo per la gestione dell'autenticazione
 */

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { CommonModule } from "@angular/common";
import { CoreModule } from "../core/core.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LocalLoginComponent } from "./login/local-login.component";
import { LoginComponent } from "./login/login.component";
import { LoginFormComponent } from "./login/login-form/login-form.component";
import { LoginSamlErrorComponent } from "./login/login-saml-error.component";
import { LogoutSsoCompletedComponent } from "./login/logout-sso-completed.component";
import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { SignUpWelcomeComponent } from "./sign-up/sign-up-welcome/sign-up-welcome.component";
import { UtmCampaignComponent } from "./login/utm-campaign.component";

@NgModule({
  declarations: [
    LoginComponent,
    LocalLoginComponent,
    LoginSamlErrorComponent,
    LogoutSsoCompletedComponent,
    LoginFormComponent,
    SignUpComponent,
    SignUpWelcomeComponent,
    UtmCampaignComponent
  ],
  imports: [
    CoreModule,
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [TranslateService],
})
export class AuthModule {}
