import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { UrlService } from 'src/app/shared/services/url.service';

import * as fromApp from "../../ngrx/app.reducers";
import * as CommonClasses from "../../../cm2-commonclasses";

@Injectable({
  providedIn: 'root'
})
export class ZonemanagerService {
  applicationData: GlobalApplicationData;
  result$: Subscription;
  applicationContext: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private urlService: UrlService
  ) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(
      fromApp.getGlobalApplicationData
    );
    const combinedSelectes$ = combineLatest(globalApplicationData$);
    this.result$ = combinedSelectes$.subscribe(([globalApplicationData]) => {
      this.applicationData = globalApplicationData;
      this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
    });
   }



   countUserToDoList(getOnlyUnread?: boolean, getOnlyRead?: boolean){
    let httpParams = new HttpParams();
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    httpParams = httpParams.append(
      "getStatusNotification", "true"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-todolist-counters-producer",
      {
        params: httpParams,
      }
    );
  }

  getUserToDoList(
    fromRecord,
    numRecords,
    searchText?,
    getOnlyUnread?: boolean,
    getOnlyRead?: boolean,
    getStatusNotification?: boolean,
    smartHiringStatus?,
    activePhases?,
    managerName?,
    agencyName?,
    getOnlyMyNewrecruit?
  ) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("fromRecord", fromRecord);
    httpParams = httpParams.append("numRecords", numRecords);
    httpParams = httpParams.append("searchText", searchText)
    httpParams = httpParams.append("getOnlyUnread", getOnlyUnread ? "true" : "false");
    httpParams = httpParams.append("getOnlyRead", getOnlyRead ? "true" : "false");
    httpParams = httpParams.append("getStatusNotification", getStatusNotification ? "true" : "false");
    smartHiringStatus.forEach(element => {
      httpParams = httpParams.append("smartHiringStatus", element);
    });
    activePhases.forEach(element => {
      httpParams = httpParams.append("activePhases", element);
    });
    httpParams = httpParams.append("managerName", managerName);
    httpParams = httpParams.append("agencyName", agencyName);
    httpParams = httpParams.append("getOnlyMyNewrecruit", getOnlyMyNewrecruit ? "true" : "false");

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-todolist-list-producer/" +
      fromRecord +
      "/" +
      numRecords,
      {
        params: httpParams
      }
    );
  }

  chat_getLastMessage(
    recipientId: string,
    numRecords: string = "10",
    fromRecord: string = "0"
  ) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-last-chat-message/",
      {
        params: { recipientId, numRecords, fromRecord },
      }
    );
  }

  getRecruitById(userId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("newRecruitId", userId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-by-id/",
      {
        params: httpParams,
      }
    );
  }

  chat_initializeChat(sessionStorageId, recipientId) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/initialize-chat-for-producer/",
      {
        params: { sessionStorageId, recipientId },
      }
    );
  }

  chat_sendMessage(sessionStorageId, textMessage, recipientId, uploadId?) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/send-message/",
      {
        sessionStorageId,
        textMessage,
        recipientId,
        uploadId,
      }
    );
  }

  chat_getAllChat() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/newrecruit-active-chat/"
    );
  }

  importUsers(sessionStorageId, file){
    let formData = new FormData();
    formData.append("importFile", file);
    formData.append("hasHeadersToSkip", "true");
    formData.append("sessionStorageId", sessionStorageId);

    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/import-newrecruiter-producer-from-file/", formData
    );
  }

  getNewlyhired(
    fromRecord?,
    numRecords?,
    searchText?,
    activePhases?,
    getOnlyMyNewRecruit?,
    smartHiringStatus?,
    managername?,
    agencyname?,
    userIds?,
    ){
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    httpParams = httpParams.append('searchedText', searchText);
    httpParams = httpParams.append('sorting', 'CREATION_DATE_DESC');
    httpParams = httpParams.append('withManagerLoaded', 'true');
    httpParams = httpParams.append('withRecruiterLoaded', 'false');
    if (activePhases) {
      activePhases.forEach(element => {
        httpParams = httpParams.append('activePhases', element);
      });
    }
    if (smartHiringStatus) {
      smartHiringStatus.forEach(element => {
        httpParams = httpParams.append('smartHiringStatus', element);
      });
    }
    httpParams = httpParams.append('getOnlyMyNewrecruit', getOnlyMyNewRecruit);
    httpParams = httpParams.append('managerName', managername);
    httpParams = httpParams.append('agencyName', agencyname);
    if (userIds) {
      userIds.forEach(element => {
        httpParams = httpParams.append('userIds', element);
      });
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-all-newrecruit-producer/",
      {
        params: httpParams,
      }
    );
  }

  lockUser(userId, statusProcessProduction, statusProcessProductionNote){

    let ids = [];
    userId.forEach(element => {
      ids.push(element.userId);
    });

    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/set-status-process-production/",
      {
        statusProcessProduction: statusProcessProduction,
        statusProcessProductionNote: statusProcessProductionNote,
        userId: ids
      }
    );
  }

  sendMail(ids){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/send-account-validation-mail-producer/",
      {
        userIds: ids
      }
    );
  }

  exportUsersById(fromRecord, numRecords, searchedText, userIds){
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('sorting', 'CREATION_DATE_DESC');
    userIds.forEach(element => {
      httpParams = httpParams.append('userIds', element.userId);
    });
    httpParams = httpParams.append('skipEmail', 'true');

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/create-report-new-recruit-producer/",
      {
        params: httpParams,
      }
    );
  }

  getDistinctUsersFieldValues(field: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("field", field);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-distinct-users-field-values",
      {
        params: httpParams,
      }
    );
  }

  getJourneyStatus(userId){
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('viewType', 'HRBP');

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-journey-status-by-userid-for-producer/",
      {
        params: httpParams,
      }
    );
  }

  getMaritalStatus(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-stato-civile/", {}
    );
  }

  getProvinceList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-province-list/", {}
    );
  }

  getCities(province?){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("provincia", province);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-comuni-list",
      {
        params: httpParams,
      }
    );
  }

  getQualifications(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-titolo-studio/", {}
    );
  }

  getUserList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-tipo-dipendente/"
    );
  }

  getRemedyManagers( socAssunzione?, includeDisabled?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("includeDisabled", includeDisabled ? 'true' : 'false');
    if(socAssunzione) httpParams = httpParams.append("socDistacco", socAssunzione);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-menudata-manager/",
      {
        params: httpParams,
      }
    );
  }

  getDocuments(userId, step){
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('step', step);
    httpParams = httpParams.append('searchStatus', 'true');

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-validation-by-step-producer/",
      {
        params: httpParams,
      }
    );
  }

  getDocumentsValidationById(validationId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("validationId", validationId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-validation-by-id/",
      {
        params: httpParams,
      }
    );
  }

  uploadDocumentValidation(validationId: string, documentType?: string) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/upload-document-validation-by-manager-for-producer",
      {
        validationId,
        documentType
      }
    );
  }


  rejectDocumentValidationForProducer(validationId: string, documentType?: string, statusNote?: string) {

    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/reject-document-validation-for-producer/",
      {
        validationId,
        documentType,
        statusNote
      }
    );
  }

  acceptDocumentValidationForProducer(validationId: string, documentType?: string) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/accept-document-validation-for-producer",
      {
        validationId,
        documentType
      }
    );
  }

  confirmDocumentValidationForProducer(validationId: string, documentType?: string) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/confirm-document-validation-for-producer",
      {
        validationId,
        documentType
      }
    );
  }

  acceptDocumentValidationMassive(validationIds: string[], documentType?: string) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/accept-document-validation-for-producer-massive",
      {
        validationIds,
        documentType
      }
    );
  }

  uploadDocument(body, uploadObj) {
    let formData = new FormData();
    formData.append("file", uploadObj);
    formData.append("fileName", uploadObj.name);

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        formData.append(key, body[key]);
      }
    }

    let params = new HttpParams();
    const options = {
      params,
    };
    const req = new HttpRequest(
      "POST",
      this.applicationContext +
        "rest-api/onboarding-mediator/upload-document-validation-by-manager-to-hrbp-for-producer",
      formData,
      options
    );
    return this.http.request(req);
  }

  getDocumentValidationByStep(userId, step: "CONTROLLO-DOCUMENTI" | "LETTERA-ASSUNZIONE") {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("step", step);
    httpParams = httpParams.append("searchStatus", "true");
    httpParams = httpParams.append("userId", userId);


    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-document-validation-by-step-producer/",
      {
        params: httpParams
      }
    );
  }

  getTemporaryToken(validationId: string, uploadId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("validationId", validationId);
    httpParams = httpParams.append("uploadId", uploadId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-masked-url/",
      {
        params: httpParams,
      }
    );
  };

  getDocumentsOfUserByParameters(parameters) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-upload-by-user-and-parameter/",
      {
        params: parameters,
      }
    );
  }

  getZoneManagers(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-zone-managers/",{}
    );
  }

  getSpecializations(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-specializzazioni/", {}
    );
  }

  setDocsNotificationAsRead(notificationQueueId){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/mark-notification-as-read/",
      {
        notificationQueueId
      }
    );
  }

}
