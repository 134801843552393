import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { BehaviorSubject, Observable, Subscription, combineLatest } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";

import { AuthService } from "src/app/auth/services/auth.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { distinctUntilChanged } from "rxjs/operators";

/*
 *    Servizio che gestisce i vari redirect
 */
@Injectable()
export class RedirectService implements OnDestroy {
  isMainMenuSidebarOpened: boolean;
  sidenavObservables$: Subscription;
  constructor(
    private store: Store<fromApp.AppState>,
    private authService: AuthService,
    private router: Router,
    // tslint:disable-next-line: variable-name
    private _location: Location
  ) {
    // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
    let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(
      fromApp.isMainMenuSidebarOpened
    );
    const combinedSelectes$ = combineLatest(isMainMenuSidebarOpened$);

    this.sidenavObservables$ = combinedSelectes$.subscribe(
      ([isMainMenuSidebarOpened]) => {
        this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
      }
    );
  }

  private $activeTab: BehaviorSubject<string> = new BehaviorSubject("0");

  // Quando il componente è distrutto, eseguo anche l'unsubscribe
  ngOnDestroy() {
    if (this.sidenavObservables$) {
      this.sidenavObservables$.unsubscribe();
    }
  }

  goBack(route?: any, secondPage?: boolean, isTouchpoint?: boolean) {
    // if (isTouchpoint) {
    //   if (secondPage) {
    //     this.router.navigate(["../"], { relativeTo: route });
    //   } else {
    //     this.router.navigate(["../../home"], { relativeTo: route });
    //   }
    // } else {
    //   this.router.navigate(["../home"], { relativeTo: route });
    // }
    this._location.back();
  }

  // Chiude la sidebar di sinistra contente il menu principale
  closeSidenavMainMenu() {
    this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
  }

  goToLogin() {
    this.router.navigate( ["localLogin"] );
  }

  goToChat(name) {
    this.router.navigate(["hrbp/singlechat/" + name]);
  }

  goToChatZoneManager(name) {
    this.router.navigate(["zonemanager/singlechat/" + name]);
  }

  goToViewDocument(idUser, validationId) {
    this.router.navigate(["hrbp/view-document/" + idUser + '/' + validationId]);
  }

  goToSendCommunication(idUser, validationId?) {
    this.router.navigate(["hrbp/sendcommunication/" + idUser + "/" + validationId]);
  }

  goToConfirmGuru(idUser){
    this.router.navigate(["recruiter/confirmguru/" + idUser]);
  }

  goToRecruit(idRecruit, recruiter = false) {
    if(!recruiter){
      this.router.navigate(["hrbp/singleuser/" + idRecruit]);
    } else {
      this.router.navigate(["recruiter/singleuser/" + idRecruit]);
    }
  }

  goToDetailNewRecruit(idRecruit) {
    if (this.authService.isAdminMode()) {
      this.router.navigate(["hrbp/singleuser/" + idRecruit]);
    } else if(this.authService.isRecruiter()) {
      this.router.navigate(["recruiter/singleuser/" + idRecruit]);
    } else if(this.authService.isManager()) {
      this.router.navigate(["manager/singleuser/" + idRecruit]);
    }
  }

  goToJourneySettings(role, idUser) {
    this.router.navigate([ role + "/journeysettings/" + idUser + "/" + role ]);
  }

  goToInductionPlan(idUser) {
    this.router.navigate(["manager/induction-plan/" + idUser]);
  }

  goToCreateMeeting(idUser) {
    this.router.navigate(["manager/create-meeting/" + idUser]);
  }

  goToEditMeeting(idUser, idMeeting) {
    this.router.navigate(["manager/create-meeting/" + idUser + "/" + idMeeting]);
  }

  goToMeetingSummary(idUser, idMeeting) {
    this.router.navigate(["manager/meeting-summary/" + idUser + "/" + idMeeting]);
  }

  goToChangeGuru(idUser) {
    this.router.navigate([ "recruiter/changeguru/" + idUser]);
  }

  goToVirtualExperience(idExp) {
    this.router.navigate(["users/virtualexperience/" + idExp]);
  }

  goToInduction(meetingId) {
    this.router.navigate(["users/virtualexperience/" + meetingId + "/INDUCTION"]);
  }

  // Porta all'home page
  goToHome() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.authService.isAdminMode()) {
      this.router.navigate(["hrbp/todolist"]);
    } else if(this.authService.isRecruiter()) {
      this.router.navigate(["recruiter/todolist"]);
    } else if(this.authService.isGuru()) {
      this.router.navigate(["guru/todolist"]);
    } else if(this.authService.isManager()) {
      this.router.navigate(["manager/todolist"]);
    } else if(this.authService.isHrbpPro()) {
      this.router.navigate(["hrbpPro/todolist"]);
    } else if(this.authService.isZonemanager()) {
      this.router.navigate(["zonemanager/todolist"]);
    } else {
      this.router.navigate(["users/todolist"]);
    }
  }

  goToNewTab(path) {
    this.router.navigate([path]);
  }

  goToProposal(idProposal) {
    this.router.navigate(["users/proposal/" + idProposal]);
  }

  goToSmartHiring(idUser) {
    this.router.navigate(["hrbp/smarthiring/" + idUser]);
  }

  // Porta alle notifiche
  goToNotifications() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.authService.isAdminMode()) {
      this.router.navigate(["admin/notifications"]);
    }
  }

  goToRecoveryPassword(userId: string, token: string) {
    this.router.navigate(["newlyHired/recoveryUserPassword/" + userId + "/" + token]);
  }

  goToPrivacy() {
    this.router.navigate(["users/privacy"]);
  }

  // Porta alla pagina del profilo
  goToProfile() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.authService.isHrbpPro()) {
      this.router.navigate(["hrbpPro/profile"])
    }
    else if (this.authService.isAdminMode()) {
      this.router.navigate(["hrbp/profile"]);
    } else if(this.authService.isRecruiter()) {
      this.router.navigate(["recruiter/profile"]);
    } else if(this.authService.isGuru()) {
      this.router.navigate(["guru/profile"]);
    } else if(this.authService.isZonemanager()){
      this.router.navigate(["zonemanager/profile"])
    } else {
      this.router.navigate(["users/profile"]);
    }
  }

  goToFaq() {
    if (this.authService.isAdminMode()) {
      this.router.navigate(["hrbp/faq"]);
    } else if(this.authService.isRecruiter()) {
      this.router.navigate(["recruiter/faq"]);
    } else if(this.authService.isGuru()) {
      this.router.navigate(["guru/faq"]);
    } else {
      this.router.navigate(["users/faq"]);
    }
  }

  goToSendNewMail(idUser, docType, validationId?) {
    this.router.navigate(["hrbp/sendcommitproposal/" + idUser + "/" + docType + "/" + validationId]);
  }

  goToFilters() {
    if(this.authService.isHrbpPro()) {
      this.router.navigate(["hrbpPro/filters"]);
    } else if(this.authService.isZonemanager()) {
      this.router.navigate(["zonemanager/filters"]);
    } else if (this.authService.isAdminMode()) {
      this.router.navigate(["hrbp/filters"]);
    } else if(this.authService.isRecruiter()) {
      this.router.navigate(["recruiter/filters"]);
    } else if(this.authService.isGuru()) {
      this.router.navigate(["guru/filters"]);
    } else {
      this.router.navigate(["users/profile"]);
    }
  }

  goToExperienceSummary(idExp) {
    this.router.navigate(["guru/experiencesummary/" + idExp ]);
  }

  goToCreateExperience(idExp?) {
    this.router.navigate(["guru/createexperience" + (idExp ? ("/" + idExp) : "")]);
  }

  goToGuruExperience() {
    this.router.navigate(["guru/experience"]);
  }

  goToCompletedExperiences(){
    this.router.navigate(["guru/completedexperiences"]);
  }

  goToFiltersUser() {
    this.router.navigate(["users/filters"]);
  }

  goToAcceptResponse(idUser, validationId) {
    this.router.navigate([
      "hrbp/acceptcommitmentproposal/" + idUser + "/" + validationId,
    ]);
  }

  goToAcceptCommitmentProposal(idUser, validationId, docType) {
    this.router.navigate([
      "hrbp/acceptcommitmentproposal/" +
      idUser +
      "/" +
      validationId +
      "/" +
      docType,
    ]);
  }

  goToSendMail(idUser) {
    this.router.navigate(["hrbp/sendmail/" + idUser]);
  }

  goToSendDocuments(idUser) {
    this.router.navigate(["hrbp/send-documents/" + idUser]);
  }

  goToRequestDocuments(idUser) {
    this.router.navigate(["hrbp/request-user-documents", idUser]);
  }

  goToMyTeam() {
    this.router.navigate(["users/myTeam"]);
  }

  goToDashboard() {
    this.router.navigate(['hrbp/dashboard']);
  }

  goToSettings() {
    console.log("Redirect missing");
    // this.router.navigate(['users/myTeam']);
  }

  logout() {
    this.router.navigate(["/login"]);
  }

  goToFAQ() {
    console.log("Redirect missing");
    // this.router.navigate(['users/myTeam']);
  }

  goToTutorial() {
    console.log("Redirect missing");
    // this.router.navigate(['users/myTeam']);
  }

  goToPerformance(year, userId?: string) {
    console.log("perfomance routing");
    if (userId) {
      this.router.navigate(["users/performance/", year, userId]);
    } else {
      this.router.navigate(["users/performance/", year]);
    }
  }
  goToSkills(year, userId?: string) {
    console.log("Redirect missing");
    // this.router.navigate(['users/myTeam']);
  }
  goToPotential(year, userId?: string) {
    console.log("Redirect missing");
    // this.router.navigate(['users/myTeam']);
  }
  goToIDP(year, userId?: string) {
    console.log("Redirect missing");
    // this.router.navigate(['users/myTeam']);
  }
  goToFeedback(year, userId?: string) {
    console.log("Redirect missing");
    // this.router.navigate(['users/myTeam']);
  }
  goToGoal(year, userId?: string) {
    console.log("Redirect missing");
    // this.router.navigate(['users/myTeam']);
  }

  watchTab(): Observable<string> {
    return this.$activeTab.pipe(distinctUntilChanged());
  }

  setTab(tab: string) {
    sessionStorage.setItem("currentTab", tab);
    this.$activeTab.next(tab);
  }

  getTab() {
    return sessionStorage.getItem("currentTab");
  }

  goToSmartHiringDetails(idUser) {
    this.router.navigate(["hrbp/smarthiringDetails", idUser]);
  }

  goToAddUser(){
    this.router.navigate(["hrbpPro/addUser"]);
  }

  goToNewlyHired(){
    this.router.navigate(["hrbpPro/newlyhired"]);
  }

  goToGenerateLetters(ids){
    this.router.navigate(["hrbpPro/generateLetters/" + ids.length + '/', ids]);
  }

  goToStartSmartHiring(ids, page){
    this.router.navigate(["hrbpPro/smartHiring/" + page + '/', ids]);
  }

  goToDocs(id){
    this.router.navigate(["hrbpPro/documents/" + id]);
  }

  goToDocsZM(id){
    this.router.navigate(["zonemanager/documents/" + id]);
  }
}
