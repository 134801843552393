import * as CoreActions from "./core.actions";

import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { IAppDocument } from "src/app/utils";

export interface CoreState {
  globalApplicationData: GlobalApplicationData;
  isFetchingLangs: boolean;
  showApplicationLoader: boolean;
  isMainMenuSidebarOpened: boolean;
  redirectUrl: string;
  applicationLang: string;
  defaultLang: string;
  notificationsCounter: number;
  selectedDocumentDetails: string;
  selectedDocumentDetailsMainActionText: string;
  selectedDocumentDetailsMainActionValue: string;
  showFooter: boolean;
  hideTopNavigation: boolean;
  filePreview?: IAppDocument;
}

const initialState: CoreState = {
  globalApplicationData: null,
  isFetchingLangs: false,
  showApplicationLoader: false,
  isMainMenuSidebarOpened: false,
  redirectUrl: null,
  applicationLang: null,
  defaultLang: null,
  notificationsCounter: 0,
  selectedDocumentDetails: null,
  selectedDocumentDetailsMainActionText: null,
  selectedDocumentDetailsMainActionValue: null,
  showFooter: true,
  hideTopNavigation: false,
  filePreview: null,
};

export function coreReducer(
  state = initialState,
  action: CoreActions.CoreActions
) {
  switch (action.type) {
    case CoreActions.SAVE_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload,
      };
    case CoreActions.REMOVE_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: null,
      };
    case CoreActions.SET_CORE_APPLICATION_DATA:
      return {
        ...state,
        globalApplicationData: action.payload,
      };
    case CoreActions.SET_APPLICATION_LANG:
      return {
        ...state,
        applicationLang: action.payload,
      };
    case CoreActions.REMOVE_CORE_APPLICATION_DATA:
      return {
        ...state,
        globalApplicationData: null,
      };
    case CoreActions.REMOVE_APPLICATION_LANG:
      return {
        ...state,
        applicationLang: null,
      };
    case CoreActions.GET_AVAILABLE_LANGS:
      return {
        ...state,
        isFetchingLangs: true,
      };
    case CoreActions.GET_AVAILABLE_LANGS_FINISHED:
      return {
        ...state,
        isFetchingLangs: false,
      };
    case CoreActions.SET_DEFAULT_LANG:
      return {
        ...state,
        defaultLang: action.payload,
      };
    case CoreActions.OPEN_MAIN_MENU_SIDEBAR:
      return {
        ...state,
        isMainMenuSidebarOpened: true,
      };
    case CoreActions.CLOSE_MAIN_MENU_SIDEBAR:
      return {
        ...state,
        isMainMenuSidebarOpened: false,
      };
    case CoreActions.SET_NOTIFICATIONS_COUNTER:
      return {
        ...state,
        notificationsCounter: action.payload,
      };
    case CoreActions.SET_SELECTED_DOCUMENT_DETAILS:
      return {
        ...state,
        selectedDocumentDetails: action.payload,
      };
    case CoreActions.SET_SELECTED_DOCUMENT_DETAILS_MAIN_ACTION_VALUE:
      return {
        ...state,
        selectedDocumentDetailsMainActionValue: action.payload,
      };
    case CoreActions.SET_SELECTED_DOCUMENT_DETAILS_MAIN_ACTION_TEXT:
      return {
        ...state,
        selectedDocumentDetailsMainActionText: action.payload,
      };
    case CoreActions.SET_SHOW_FOOTER:
      return {
        ...state,
        showFooter: action.payload,
      };
    case CoreActions.SET_HIDE_TOP_NAVIGATION:
      return {
        ...state,
        hideTopNavigation: action.payload,
      };
    case CoreActions.SET_FILE_PREVIEW:
      return {
        ...state,
        filePreview: action.payload,
      };
    default:
      return state;
  }
}

export const getGlobalApplicationData = (state: CoreState) =>
  state.globalApplicationData;
export const isFetchingLangs = (state: CoreState) => state.isFetchingLangs;
export const showApplicationLoader = (state: CoreState) =>
  state.showApplicationLoader;
export const isMainMenuSidebarOpened = (state: CoreState) =>
  state.isMainMenuSidebarOpened;
export const getRedirectUrl = (state: CoreState) => state.redirectUrl;
export const getApplicationContext = (state: CoreState) =>
  state.globalApplicationData.applicationContext;
export const getApplicationLang = (state: CoreState) => state.applicationLang;
export const getDefaultLang = (state: CoreState) => state.defaultLang;
export const getNotificationsCounter = (state: CoreState) =>
  state.notificationsCounter;
export const getSelectedDocumentDetails = (state: CoreState) =>
  state.selectedDocumentDetails;
export const getSelectedDocumentDetailsMainActionText = (state: CoreState) =>
  state.selectedDocumentDetailsMainActionText;
export const getSelectedDocumentDetailsMainActionValue = (state: CoreState) =>
  state.selectedDocumentDetailsMainActionValue;
export const getShowFooter = (state: CoreState) => state.showFooter;
export const getHideTopNavigation = (state: CoreState) =>
  state.hideTopNavigation;
export const getFilePreview = (state: CoreState) => state.filePreview;
