import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from '../ngrx/core.actions';
import { Router } from '@angular/router';
import { JwtPayload, RequiredAuth } from "../../../cm2-commonclasses";
import { TranslateService } from '@ngx-translate/core';
import { RecruiterService } from "src/app/recruiter/services/recruiter.service";
import { Observable, combineLatest, Subscription } from 'rxjs';
import { RedirectService } from '../../shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { HrbpPaths, HrbpTabsHome, UsersPaths, UsersTabsHome, RecruiterTabsHome, RecruiterPaths, GuruTabsHome, GuruPaths, ManagerTabsHome, ManagerPaths, HrbpProTabsHome, HrbpProPaths, ZonemanagerTabsHome, ZonemanagerPaths } from 'src/app/utils/Tabs';
import { authControl } from 'src/app/shared/models/global-application-data.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/users/services/user.service';
import { AppDocumentValidationStatus, IAppDocument } from "../../utils";
import { ChatService } from 'src/app/shared/components/chat/chat.service';
import { HrbpService } from 'src/app/hrbp/services/hrbp.service';
import { HeaderService } from "src/app/core/header/services/header.service";
import { filter } from 'rxjs/operators';
import { ZonemanagerService } from 'src/app/zonemanager/services/zonemanager.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnDestroy {
    loggedUser: JwtPayload;
    isMainMenuSidebarOpened: boolean;
    result$: Subscription;
    @Output() activateAnimation = new EventEmitter();
    isAdmin: boolean;
    avatar = 'assets/img/placeholder.svg';
    notificationsCounter: number;

    uuid: any;
    isProducer: boolean;
    isHrbp;
    tabsHome;
    paths;
    idTab;
    getToDoList$: Subscription;
    getRecruitsCounter$: Subscription;
    private navigationChange: Subscription;


    constructor(
        private store: Store<fromApp.AppState>,
        private router: Router,
        private toastr: ToastrService,
        private _chatService: ChatService,
        private userService: UserService,
        private recruiterService: RecruiterService,
        private zoneManagerService: ZonemanagerService,
        public translate: TranslateService,
        private headerService: HeaderService,
        private hrbpService: HrbpService,
        public redirectService: RedirectService,
        public authService: AuthService
        ) {
          // Recupero le informazioni sull'utente loggato dallo Store applicativo
          let loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
          // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di sinistra col menu principale è aperta
          let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
          let notificationsCounter$: Observable<number> = this.store.select(fromApp.getNotificationsCounter);
          // Sto in ascolto dei vari cambiamenti
          const combinedSelectes$ = combineLatest(loggedUser$, isMainMenuSidebarOpened$, notificationsCounter$);
          this.result$ = combinedSelectes$.subscribe(
            ([loggedUser, isOpened, notificationsCounter]) => {
              this.loggedUser = loggedUser;
              this.isMainMenuSidebarOpened = isOpened;
              this.notificationsCounter = notificationsCounter;
              // Recupero l'avatar dell'utente loggato dal suo payload


              if (this.loggedUser) {

                this.getInfo();

                if (loggedUser.auths && loggedUser.auths.length
                  && this.authService.isUserAuthorized(RequiredAuth.CORPORATEACADEMY_MANAGE_COURSES, loggedUser.auths)) {
                    this.isAdmin = true;
                  } else {
                    this.isAdmin = false;
                  }

                  this.manageTabs(loggedUser);

                  this.navigationChange = this.router.events
                  .pipe(filter((event) => event instanceof NavigationEnd))
                  .subscribe((event: NavigationEnd) => {
                    this.checkPathForTabs(event.url);
                    if(this.loggedUser){
                        this.manageTabs(this.loggedUser);
                      }
                  });

                  // Recupero l'avatar dell'utente loggato dal suo payload, con una foto di default qualora mancasse
                  this.avatar = (this.loggedUser && this.loggedUser.user && this.loggedUser.user.userOptions && this.loggedUser.user.userOptions.avatarImage)
                  || (this.loggedUser && this.loggedUser.user && this.loggedUser.user.chiaveSesso == "M" ? 'assets/img/placeholder.svg' : 'assets/img/placeholder.svg');
                }

              });
            }


    async getInfo(){
      try {
        let res = await this.userService.getProfileInfo().toPromise();
        if (res.response) {
          if (res.response.newRecruitType == 'PRODUCER') {
            this.isProducer = true;
          } else {
            this.isProducer = false;
          }
        }
      } catch (error) {

      }
    }

    manageTabs(loggedUser){
      const authObject = authControl(loggedUser && loggedUser.auths);
      const href = location.href.toString();
      const canBeUser = Boolean(loggedUser.params?.isNewRecruit);

      // console.log(authObject);

      if(authObject.isAdmin && href.includes("hrbp") && !href.includes("hrbpPro")) {
        this.isHrbp = true;
        this.tabsHome = HrbpTabsHome;
        this.paths = HrbpPaths;
        this.getRecruitsCounter();
        this.getToDoListCounterForHrbp();
        // setTimeout(() => this.initializeChatForHrbp(), 2000);
        this.initializeChatForHrbp();
        this.idTab = this.tabsHome[1].id;
      } else if (authObject.isHrbpPro && href.includes("hrbpPro")) {
        this.tabsHome = HrbpProTabsHome;
        this.paths = HrbpProPaths;
        this.idTab = 0;
        this.getToDoListCounterHrbpPro();
      } else if(authObject.isZonemanager && href.includes("zonemanager")) {
        this.tabsHome = ZonemanagerTabsHome;
        this.paths = ZonemanagerPaths;
        this.idTab = 0;
        this.getToDoListCounterForZonemanager();
        this.initializeChatZoneManager();
      }else if(authObject.isManager && href.includes("manager") && !href.includes("zonemanager")) {
          this.tabsHome = ManagerTabsHome;
          this.paths = ManagerPaths;
          this.idTab = 0;
          //this.getToDoListCounterForGuru();
      } else if(authObject.isRecruiter && href.includes("recruiter")) {
          this.tabsHome = RecruiterTabsHome;
          this.paths = RecruiterPaths;
          this.getToDoListCounterForRecruiter();
          this.initializeChatForRecruiter();
          this.idTab = 1;
      } else if(authObject.isGuru && href.includes("guru")) {
          this.tabsHome = GuruTabsHome;
          this.paths = GuruPaths;
          this.idTab = 0;
          this.getToDoListCounterForGuru();
      } else if(canBeUser && href.includes('users')){
          this.tabsHome = UsersTabsHome;
          this.paths = UsersPaths;
          this._getJourneyCount();
          this.getDocCounts();
          this.getToDoListCounterForUser();
          //setTimeout(() => this.initializeChatForUser(), 1500);
          this.initializeChatForUser();
          this.idTab = this.tabsHome[1].id;
      }
    }

    private async initializeChatForUser() {
        try {
            this.uuid = this._chatService.getUuid();
            this._chatService.setService(this.userService);

            let response;
            if (this.isProducer) {
              response = await this.userService.getZoneManagerAvatar().toPromise();
            } else {
              response = await this.userService.getHrbpAvatar().toPromise();
            }

            if (response.error) {
                console.error(response);
                return;
            }
            const hrbp = response.response;

            let res;
            if (this.isProducer) {
              res = await this.userService.chat_initializeChatPro(this.uuid, hrbp.userId).toPromise();
            } else {
              res = await this.userService.chat_initializeChat(this.uuid, hrbp.userId).toPromise();
            }

            const initChat = res.response;

            this._chatService.countWatch().subscribe((count) => {
                this.tabsHome[3].notifications = count.toString();
            });
            await this._chatService.stompSubscribe(initChat);
        } catch (e) {
            console.error(e);
            //this.toastr.error(e.message);
        }
    }

    private async initializeChatZoneManager() {
        try {
            this.uuid = this._chatService.getUuid();
            this._chatService.setService(this.userService);

            let userId = this.loggedUser.user.userId;
            const res = await this.zoneManagerService
                .chat_initializeChat(this.uuid, userId)
                .toPromise();
            const initChat = res.response;

            this._chatService.countWatch().subscribe((count) => {
                this.tabsHome[3].notifications = count.toString();
            });
            await this._chatService.stompSubscribe(initChat);
        } catch (e) {
            console.error(e);
            //this.toastr.error(e.message);
        }
    }

    private async initializeChatForHrbp() {
        try {
            this.uuid = this._chatService.getUuid();
            this._chatService.setService(this.hrbpService);
            const initChat = (
                await this.hrbpService
                    .chat_initializeChat(
                        this.uuid,
                        this.uuid
                    )
                    .toPromise()
            ).response;

            this._chatService.countWatch().subscribe((count) => {
                this.tabsHome[3].notifications = count.toString();
            });

            await this._chatService.stompSubscribe(initChat);
        } catch (e) {
            console.error(e);
        }
    }

    private async initializeChatForRecruiter() {
        try {
            this.uuid = this._chatService.getUuid();
            this._chatService.setService(this.recruiterService);
            const initChat = await this.recruiterService.chat_initializeChat(this.uuid,this.uuid).toPromise();

            await this._chatService.stompSubscribe(initChat.response);
        } catch (e) {
            console.error(e);
        }
    }

    private async getDocCounts() {
        try {
            this.headerService.notificationsDocumentsCounter.subscribe(counterValue => this.tabsHome[2].notifications = counterValue);
            this.headerService.updateDocumentsCounterHeader();

            //const documents = await this.userService.getCountDocumentsNotification().toPromise();
            /*if (!documents.error) {*/

        } catch (error) {
            console.log(error);
        }
    }

    private async _getJourneyCount() {
        try {

            this.headerService.notificationsJourneyCounter.subscribe(counterValue => this.tabsHome[1].notifications = counterValue);

            const result = await this.userService
                .getCountJourneyNotification(true)
                .toPromise();
            if (result.error) {
                //this.toastr.error(this.translate.instant("errors." + result.error));
            } else {
                this.tabsHome[1].notifications = result.response || "0";
            }
        } catch (error) {
            console.error(error);
            //this.toastr.error(this.translate.instant("errors." + error));
        }

    }

    checkPathForTabs(path){
      if(path.includes("/guru")){
          this.tabsHome = GuruTabsHome;
          this.paths = GuruPaths;
          this.idTab = 0;
          this.getToDoListCounterForGuru();
      } else if(path.includes("recruiter")){
          this.tabsHome = RecruiterTabsHome;
          this.paths = RecruiterPaths;
          this.getToDoListCounterForRecruiter();
          this.initializeChatForRecruiter();
          this.idTab = 1;
      } else if (path.includes("hrbpPro")) {
        this.tabsHome = HrbpProTabsHome;
        this.paths = HrbpProPaths;
        this.idTab = 0;
        this.getToDoListCounterHrbpPro();
      } else if (path.includes("hrbp")) {
        this.isHrbp = true;
        this.tabsHome = HrbpTabsHome;
        this.paths = HrbpPaths;
        this.getRecruitsCounter();
        this.getToDoListCounterForHrbp();
        this.initializeChatForHrbp();
        this.idTab = this.tabsHome[1].id;
      }
      else if (path.includes("zonemanager")) {
        this.isHrbp = true;
        this.tabsHome = ZonemanagerTabsHome;
        this.paths = ZonemanagerPaths;
        this.getRecruitsCounter();
        this.getToDoListCounterForHrbp();
        this.initializeChatZoneManager()
        this.idTab = this.tabsHome[3].id;
      }
  }

    getToDoListCounterForUser() {

        this.headerService.notificationsToDoListCounterUser.subscribe(
            counterValue =>
                this.tabsHome[0].notifications = counterValue
        );

        if (this.getToDoList$) {
            this.getToDoList$.unsubscribe();
        }
        this.getToDoList$ = this.userService.countUserToDoList(true).subscribe(
            (countResponse) => {
                if (countResponse && countResponse.error) {
                    //this.toastr.error( this.translate.instant("errors." + countResponse.error) );
                } else {
                    this.tabsHome[0].notifications = countResponse.response || "0";
                }
            },
            (err) => {
                //this.toastr.error(this.translate.instant("errors." + err));
            }
        );
    }

    getToDoListCounterForHrbp() {

        this.headerService.notificationsToDoListCounter.subscribe(
            counterValue =>
                this.tabsHome[0].notifications = counterValue
        );

        if (this.getToDoList$) {
            this.getToDoList$.unsubscribe();
        }
        this.getToDoList$ = this.hrbpService.countUserToDoList(true).subscribe(
            (countResponse) => {
                if (countResponse && countResponse.error) {
                    //this.toastr.error( this.translate.instant("errors." + countResponse.error) );
                } else {
                    this.tabsHome[0].notifications = countResponse.response || "0";
                }
            },
            (err) => {
                //this.toastr.error(this.translate.instant("errors." + err));
            }
        );
    }

    getToDoListCounterForRecruiter() {

        this.headerService.notificationsToDoListCounterRecruiter.subscribe(
            counterValue =>
                this.tabsHome[0].notifications = counterValue
        );

        this.headerService.updateToDoListCounterHeaderForRecruiter();

    }

    getToDoListCounterForGuru() {

        this.headerService.notificationsToDoListCounterGuru.subscribe(
            counterValue =>
                this.tabsHome[0].notifications = counterValue
        );

        this.headerService.updateToDoListCounterHeaderForGuru();

    }

    getToDoListCounterHrbpPro() {

      this.headerService.notificationsToDoListCounterHrpbPro.subscribe(
        counterValue =>
            this.tabsHome[0].notifications = counterValue
      );

      this.headerService.updateToDoListCounterHeaderForHrbpPro();

    }

    getToDoListCounterForZonemanager(){

      this.headerService.notificationsToDoListCounterZonemanager.subscribe(
        counterValue =>
            this.tabsHome[0].notifications = counterValue
      );

      this.headerService.updateToDoListCounterHeaderForZonemanager();

    }

    getRecruitsCounter() {

        this.headerService.notificationsNewRecruitCounter.subscribe(
            counterValue =>
                this.tabsHome[1].notifications = counterValue
        );

        if (this.getRecruitsCounter$) {
            this.getRecruitsCounter$.unsubscribe();
        }
        this.getRecruitsCounter$ = this.hrbpService
            .getCounterHrbpNewRecruit()
            .subscribe(
                (res) => {
                    if (res && res.error) {
                        //this.toastr.error(this.translate.instant("errors." + res.error));
                    } else {
                        this.tabsHome[1].notifications = res.response || "0";
                    }
                },
                (err) => {
                    //this.toastr.error(this.translate.instant("errors." + err));
                }
            );
    }

    // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav nello Store
    closeMainMenuSidenav(): void {
        this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
    }

    // Porta alla home page
    goToHome(): void {
        this.redirectService.goToHome();
    }

    // Porta al profilo
    goToProfile(): void {
        this.redirectService.goToProfile();
    }

    // Dispatch dell'azione che esegue il toggle sulla sidebar di sinistra col menu principale
    toggleMainMenuSidebar(): void {
        // Alza l'evento per il componente padre, che attive le animazioni sulle sidenav
        this.activateAnimation.emit();
        if (!this.isMainMenuSidebarOpened) {
            this.store.dispatch(new CoreActions.OpenMainMenuSidebar());
        } else {
            this.closeMainMenuSidenav();
        }
    }

    ngOnDestroy() {
        if (this.getRecruitsCounter$) {
            this.getRecruitsCounter$.unsubscribe();
        }
        if (this.getToDoList$) {
            this.getToDoList$.unsubscribe();
        }
    }
}
