import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as AuthActions from "../../../auth/ngrx/auth.actions";
import * as fromApp from "../../../ngrx/app.reducers";
import { Store } from "@ngrx/store";
import { RedirectService } from "../../services/redirect.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  activeTab: any = "0";
  @Input() avatarSrc?: string;
  @Input() paths: any;
  @Input() tabs: any;
  @Input() currentTabId: string;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onTabClicked: EventEmitter<string> = new EventEmitter();

  private subscription: Subscription;
  private navigationChange: Subscription;

  constructor(
    private store: Store<fromApp.AppState>, public router: Router, public redirectService: RedirectService) { }

  ngOnInit(): void {
    this.redirectService.setTab("0");
    this.subscription = this.redirectService.watchTab().subscribe((tab) => {
      this.activeTab = tab ? tab : "0";
    });
    this.activeTab = this.redirectService.getTab();

    this.setCurrentActiveTab();

    this.navigationChange = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        switch (event.url) {
          case "#/recruiter/guru":
          case "/recruiter/guru":
            break;
          case "/users":
          case "/users/todolist":
          case "#/users/todolist":
          case "/recruiter":
          case "/recruiter/todolist":
          case "#/recruiter/todolist":
          case "/manager":
          case "/manager/todolist":
          case "#/manager/todolist":
          case "/hrbpPro":
          case "/hrbpPro/todolist":
          case "#/hrbpPro/todolist":
          case "/zonemanager/todolist":
          case "#/zonemanager/todolist":
            this.activeTab = "0";
            break;
          case "/users/journey":
          case "#/users/journey":
          case "#/recruiter/newrecruit":
          case "/recruiter/newrecruit":
          case "/manager/newrecruit":
          case "#/manager/newrecruit":
          case "/hrbpPro/newrecruit":
          case "#/hrbpPro/newrecruit":
          case "/hrbpPro/newlyhired":
          case "#/hrbpPro/newlyhired":
          case "/hrbpPro/addUser":
          case "#/hrbpPro/addUser":
          case "hrbpPro/smartHiring":
          case "hrbpPro/generateLetters":
          case "hrbpPro/singleUser":
          case "hrbpPro/documents":
          case "hrbpPro/docType":
          case "/zonemanager/newlyhired":
          case "#/zonemanager/newlyhired":
            this.activeTab = "1";
            break;
          case "/users/documents-list":
          case "#/users/documents-list":
          case "/recruiter/dashboard":
          case "#/recruiter/dashboard":
          case "/guru/profile":
          case "#/guru/profile":
          case "/hrbp/dashboard":
          case "#/hrbp/dashboard":
          case "/hrbpPro/dashboard":
          case "#/hrbpPro/dashboard":
          case "/manager/profile":
          case "#/manager/profile":
          case "/zonemanager/dashboard":
          case "#/zonemanager/dashboard":
            this.activeTab = "2";
            break;
          case "/users/chat":
          case "#/users/chat":
          case "/zonemanager/chat":
          case "#/zonemanager/chat":
            this.activeTab = "3";
            break;
          case "/hrbp":
          case "/hrbp/todolist":
          case "#/hrbp/todolist":
          case "/guru/todolist":
          case "#/guru/todolist":
            this.activeTab = "0";
            break;
          case "/hrbp/newlyhired":
          case "#/hrbp/newlyhired":
          case "/guru/experience":
          case "#/guru/experience":
            this.activeTab = "1";
            break;
          case "/recruiter/dashboard":
          case "#/recruiter/dashboard":
          case "#/hrbpPro/profile":
          case "/hrbpPro/profile":
            this.activeTab = "3";
            break;
          case "/hrbp/chat":
          case "#/hrbp/chat":
            this.activeTab = "3";
            break;
          case "/hrbp/profile":
          case "#/hrbp/profile":
          case "/users/profile":
          case "#/users/profile":
          case "/recruiter/profile":
          case "#/recruiter/profile":
          case "/zonemanager/profile":
          case "#/zonemanager/profile":
            this.activeTab = "4";
            break;
          default:
            this.activeTab = "-1";
            break;
        }
      });
  }

  selectTab(id: any) {
    if (id === "logout") {
      // Eseguo il logout dall'applicazione
      this.store.dispatch(new AuthActions.Logout());
      this.router.navigate(["/login"]);
    } else {
      // Altrimenti continuo nella navigazione
      this.redirectService.setTab(id);
      this.redirectService.goToNewTab(this.paths[id]);
    }
  }

  private setCurrentActiveTab() {
    const route = this.router.url;
    if (route.includes("profile")) {
      this.activeTab = "4";
      this.redirectService.setTab("4");
    } else if(route.indexOf("/guru/todolist") > -1) {
      this.activeTab = "0";
      this.redirectService.setTab("0");
    } else if(route.indexOf("/manager/newrecruit") > -1) {
      this.activeTab = "1";
      this.redirectService.setTab("1");
    } else if(route.indexOf("/manager/todolist") > -1) {
      this.activeTab = "0";
      this.redirectService.setTab("0");
    } else if(route.indexOf("/recruiter/newrecruit") > -1) {
      this.activeTab = "1";
      this.redirectService.setTab("1");
    } else if(route.indexOf("/recruiter/todolist") > -1) {
      this.activeTab = "0";
      this.redirectService.setTab("0");
    } else if(route.indexOf("/guru/experience") > -1) {
      this.activeTab = "1";
      this.redirectService.setTab("1");
    } else if(route.indexOf("/hrbp/dashboard") > -1) {
      this.activeTab = "2";
      this.redirectService.setTab("2");
    } else if(route.indexOf("/guru/profile") > -1) {
      this.activeTab = "2";
      this.redirectService.setTab("2");
    } else if(route.indexOf("/manager/profile") > -1) {
      this.activeTab = "2";
      this.redirectService.setTab("2");
    } else if (route.indexOf("/users/todolist") > -1) {
      this.activeTab = "0";
      this.redirectService.setTab("0");
    } else if (
      route.indexOf("/users/proposal") > -1 ||
      route.indexOf("/users/communication") > -1 ||
      route.indexOf("/users/journey") > -1 ||
      route.indexOf("/users/documentssign") > -1 ||
      route.indexOf("/users/documentsproduced") > -1
    ) {
      this.activeTab = "1";
      this.redirectService.setTab("1");
    } else if (route.indexOf("/users/documents-list") > -1) {
      this.activeTab = "2";
      this.redirectService.setTab("2");
    } else if (route.indexOf("/users/chat") > -1) {
      this.activeTab = "3";
      this.redirectService.setTab("3");
    } else if (route.indexOf("/hrbp/todolist") > -1) {
      this.activeTab = "0";
      this.redirectService.setTab("0");
    } else if (
      route.indexOf("/hrbp/adduser") > -1 ||
      route.indexOf("/hrbp/newlyhired") > -1 ||
      route.indexOf("/hrbp/sendmail") > -1 ||
      route.indexOf("/hrbp/sendcommunication") > -1 ||
      route.indexOf("/hrbp/sendcommitproposal") > -1 ||
      route.indexOf("/hrbp/uploaddocument") > -1 ||
      route.indexOf("/hrbp/send-documents") > -1 ||
      route.indexOf("/hrbp/acceptcommitmentproposal") > -1 ||
      route.indexOf("/hrbp/singleuser") > -1
    ) {
      this.activeTab = "1";
      this.redirectService.setTab("1");
    } else if (route.indexOf("/hrbp/dashboard") > -1 || route.indexOf("/recruiter/dashboard") > -1) {
      this.activeTab = "2";
      this.redirectService.setTab("2");
    } else if (route.indexOf("/hrbp/chat") > -1) {
      this.activeTab = "3";
      this.redirectService.setTab("3");
    } else if ( route.includes("recruiter") && !route.includes("profile")){
      this.activeTab = undefined
    } else if(
      route.indexOf("/hrbpPro/todolist") > -1 ||
      route.indexOf("/zonemanager/todolist") > -1
      ) {
      this.activeTab = 0;
      this.redirectService.setTab("0");
    } else if(
      route.indexOf("/zonemanager/newlyhired") > -1 ||
      route.indexOf("/hrbpPro/newlyhired") > -1 ||
      route.indexOf("/hrbpPro/addUser") > -1 ||
      route.includes("/hrbpPro/singleUser") ||
      route.includes("hrbpPro/documents")
      ) {
      this.activeTab = 1;
      this.redirectService.setTab("1");
    } else if(
      route.indexOf("/hrbpPro/dashboard") > -1 ||
      route.indexOf("/zonemanager/dashboard") > -1
      ) {
      this.activeTab = 2;
      this.redirectService.setTab("2");
    } else if(
      route.indexOf("/hrbpPro/profile") > -1 ||
      route.indexOf("/zonemanager/chat") > -1
      ) {
      this.activeTab = 3;
      this.redirectService.setTab("3");
    } else if(
      route.indexOf("/zonemanager/profile") > -1
      ) {
      this.activeTab = 4;
      this.redirectService.setTab("4");
    }
  }
}
