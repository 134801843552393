import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { Store, StoreModule } from "@ngrx/store";

import { AngularSvgIconModule } from "angular-svg-icon";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthEffects } from "./auth/ngrx/auth.effects";
import { AuthInterceptor } from "./shared/interceptors/auth.interceptor";
import { AuthModule } from "./auth/auth.module";
import { AuthService } from "./auth/services/auth.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { ChatService } from "./shared/components/chat/chat.service";
import { CoreEffects } from "./core/ngrx/core.effects";
import { CoreModule } from "./core/core.module";
import { EffectsModule } from "@ngrx/effects";
import { ModalService } from "./shared/components/modal/modal.service";
import { NgModule } from "@angular/core";
import { ProfileComponent } from "./users/profile/profile.component";
import { SharedModule } from "./shared/shared.module";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { ToastrModule } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { reducers } from "./ngrx/app.reducers";
import { FooterComponent } from './core/footer/footer.component';
import { NewlyHiredRecoveryUserPasswordComponent } from "./users/newlyHiredRecoveryUserPassword/newlyHiredRecoveryUserPassword.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ProfileComponent,
    NewlyHiredRecoveryUserPasswordComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    AuthModule,
    CoreModule,
    AngularSvgIconModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([AuthEffects, CoreEffects]),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [], // Il plugin per lo Store (che funzione con l'estensione di Chrome) lo aggiungiamo solo se non stiamo facendo il build per la produzione
    SharedModule,
    TranslateModule
  ],
  providers: [
    Store,
    ModalService,
    AuthService,
    ChatService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
