import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-logout-sso-completed',
  templateUrl: './logout-sso-completed.component.html',
  styleUrls: ['./logout-sso-completed.component.scss']
})
export class LogoutSsoCompletedComponent {
  constructor(private router: Router,
    private analyticsService: AnalyticsService) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Logout SSO completato");
  }
}
