import { AbstractControl, ValidationErrors } from "@angular/forms";

export class PasswordValidator {
  public static matchValues(
    matchTo: string
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  public static strengthTest(): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const strongRegex = new RegExp(
        //"(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d$@$!%*?&].{8,}"
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !#$%&'()*+,-./:;<=>?@^_`{|}~])(?=.{8,})"
      );

      return !!control.parent &&
        !!control.parent.value &&
        strongRegex.test(control.value)
        ? null
        : { isMatching: false };
    };
  }
}
