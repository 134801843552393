import * as fromAuth from "../auth/ngrx/auth.reducers";
import * as fromCore from "../core/ngrx/core.reducers";
import * as fromProfile from "../users/profile/ngrx/profile.reducers";

import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

export interface AppState {
  user: fromProfile.ProfileState;
  auth: fromAuth.AuthState;
  core: fromCore.CoreState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: fromProfile.profileReducer,
  auth: fromAuth.authReducer,
  core: fromCore.coreReducer,
};

export const getAuthAppState = createFeatureSelector<fromAuth.AuthState>(
  "auth"
);
export const getCoreAppState = createFeatureSelector<fromCore.CoreState>(
  "core"
);
export const getProfileAppState = createFeatureSelector<fromProfile.ProfileState>(
  "user"
);

export const getAuthState = createSelector(
  getAuthAppState,
  (state: fromAuth.AuthState) => state
);
export const getToken = createSelector(getAuthState, fromAuth.getToken);
export const getUserAcknowledges = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.userAcknowledges
);

// Selettori relativi allo state del core
export const getCoreState = createSelector(
  getCoreAppState,
  (state: fromCore.CoreState) => state
);
export const isAuthenticated = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.authenticated
);
export const isFetchingLangs = createSelector(
  getCoreAppState,
  fromCore.isFetchingLangs
);
export const getRedirectUrl = createSelector(
  getCoreAppState,
  fromCore.getRedirectUrl
);
export const getApplicationContext = createSelector(
  getCoreAppState,
  fromCore.getApplicationContext
);
export const getGlobalApplicationData = createSelector(
  getCoreAppState,
  fromCore.getGlobalApplicationData
);
export const showApplicationLoader = createSelector(
  getCoreAppState,
  fromCore.showApplicationLoader
);
export const isMainMenuSidebarOpened = createSelector(
  getCoreAppState,
  fromCore.isMainMenuSidebarOpened
);
export const getApplicationLang = createSelector(
  getCoreAppState,
  fromCore.getApplicationLang
);
export const getDefaultLang = createSelector(
  getCoreAppState,
  fromCore.getDefaultLang
);

export const getProfileState = createSelector(
  getProfileAppState,
  (state: fromProfile.ProfileState) => state
);
export const getLoggedUser = createSelector(
  getProfileState,
  fromProfile.getLoggedUser
);
export const getIsAdmin = createSelector(
  getProfileState,
  fromProfile.getIsAdmin
);
export const getIsRecruiter = createSelector(
  getProfileState,
  fromProfile.getIsRecruiter
);
export const getIsManager = createSelector(
  getProfileState,
  fromProfile.getIsManager
);
export const getIsGuru = createSelector(
  getProfileState,
  fromProfile.getIsGuru
);
export const getIsHrbpPro = createSelector(
  getProfileState,
  fromProfile.getIsHrbpPro
);
export const getIsZoneManager = createSelector(
  getProfileState,
  fromProfile.getIsZoneManager
);
export const getAvailableLangs = createSelector(
  getProfileState,
  fromProfile.getAvailableLangs
);
export const getNotificationsCounter = createSelector(
  getCoreAppState,
  fromCore.getNotificationsCounter
);
export const getSelectedDocumentDetails = createSelector(
  getCoreAppState,
  fromCore.getSelectedDocumentDetails
);
export const getSelectedDocumentDetailsMainActionText = createSelector(
  getCoreAppState,
  fromCore.getSelectedDocumentDetailsMainActionText
);
export const getSelectedDocumentDetailsMainActionValue = createSelector(
  getCoreAppState,
  fromCore.getSelectedDocumentDetailsMainActionValue
);
export const getShowFooter = createSelector(
  getCoreAppState,
  fromCore.getShowFooter
);
export const getHideTopNavigation = createSelector(
  getCoreAppState,
  fromCore.getHideTopNavigation
);
export const getFilePreview = createSelector(
  getCoreAppState,
  fromCore.getFilePreview
);
