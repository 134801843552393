<div class="fixHeader">
    <div class="content">
        <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="start center"
            fxLayoutAlign.gt-sm="space-between center">

            <div class="main-menu-header" aria-level="1" fxLayout="row" fxLayoutAlign="space-between center"
                fxLayoutAlign.gt-sm="start center" role="heading">
                <!-- header sx (menu, logo)-->
                <button (click)="goToHome()" class="logo-wrapper icon-style-header"
                    attr.aria-label="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}">
                    <img placement="bottom" hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" class="logo-generali"
                        src="assets/img/icons/logo_generali.svg">
                </button>

                <!-- header CENTER - LOGO -->

                <div class="header-linkApp-coursemanager" fxLayout="row" fxLayoutAlign="start center">
                    <button (click)="goToHome()" class="logo-wrapper icon-style-header"
                        attr.aria-label="{{ 'header.ONBOARDING_TOOLTIP' | translate }}">
                        <img placement="bottom" hide-delay="50" tooltip-class="custom-tooltip"
                            tooltip="{{ 'header.ONBOARDING_TOOLTIP' | translate }}"
                            title="{{ 'header.ONBOARDING_TOOLTIP' | translate }}"
                            alt="{{ 'header.ONBOARDING_TOOLTIP' | translate }}" class="logo-onboarding"
                            src="assets/img/onboarding.png">
                    </button>
                </div>

                <!-- header dx profile -->
                <div class="header-linkApp-coursemanager" fxLayout="row" fxLayoutAlign="start center">
                    <div class="section-profile">
                        <button layout="column" class="img-avatar-coursemanager-home-wrapper icon-style-header"
                            attr.aria-label="{{ 'header.PROFILE' | translate }}" role="button" fxLayout="row"
                            fxLayoutAlign="center center" (click)="goToProfile()" tooltip-class="custom-tooltip"
                            tooltip="{{ 'header.PROFILE' | translate }}" placement="bottom" hide-delay="50">
                            <img fxHide.gt-sm class="img-avatar-coursemanager-home" [src]="avatar" alt="avatar"
                                title="avatar">
                        </button>
                    </div>
                </div>
            </div>

            <navbar *ngIf="tabsHome && tabsHome.length && paths && paths.length && avatar && avatar.length"
                class="full-width" [tabs]="tabsHome" [currentTabId]="idTab" (onTabClicked)="idTab = $event" [paths]="paths"
                [avatarSrc]="avatar">
            </navbar>

        </div>
    </div>

</div>
