import * as CommonClasses from "../../../cm2-commonclasses";
import * as fromApp from "../../ngrx/app.reducers";

import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import { Observable, Subscription, combineLatest, of } from "rxjs";

import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { UrlService } from "src/app/shared/services/url.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  applicationData: GlobalApplicationData;
  result$: Subscription;
  applicationContext: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private urlService: UrlService,
    private http: HttpClient
  ) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(
      fromApp.getGlobalApplicationData
    );
    const combinedSelectes$ = combineLatest(globalApplicationData$);
    this.result$ = combinedSelectes$.subscribe(([globalApplicationData]) => {
      this.applicationData = globalApplicationData;
      this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
    });
  }

  //used in document-produce component
  getDocumentToProduce() {
    let httpParams = new HttpParams();

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-document-to-produce/",
      {
        params: httpParams,
      }
    );
  }

  sendTotalFeedback(vote: number, comment: string) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext +'rest-api/onboarding-mediator/create-user-platform-feedback', { vote: vote, comment: comment });
  }

  getTotalFeedback() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext +'rest-api/onboarding-mediator/get-user-platform-feedback');
  }

  getDistinctUsersFieldValues(field: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("field", field);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-distinct-users-field-values",
      {
        params: httpParams,
      }
    );
  }

  getNationList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-nations-list"
    );
  }

  //used in proposal component
  userAcceptProposal(idProposal) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("id", idProposal);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/user-accept-proposal/",
      {
        params: httpParams,
      }
    );
  }

  getProposal(idProposal) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("id", idProposal);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/user-accept-proposal/",
      {
        params: httpParams,
      }
    );
  }

  countUserToDoList(getOnlyUnread?: boolean, getOnlyRead?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    httpParams = httpParams.append(
      "getStatusNotification", "true"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-todolist-counters",
      {
        params: httpParams,
      }
    );
  }

  getNewRecruits(parameters) {
    /*if(parameters.fromRecord !== undefined)   httpParams = httpParams.append("fromRecord", parameters.fromRecord);*/
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-all-newrecruit/",
      {
        params: parameters,
      }
    );
  }

  getCountJourneyNotification(getOnlyUnread?: boolean, getOnlyRead?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-journey-counters",
      {
        params: httpParams,
      }
    );
  }

  getCountDocumentsNotification() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-counters-new-documents"
    );
  }

  getUserToDoList(
    fromRecord,
    numRecords,
    searchText,
    getOnlyUnread?: boolean,
    getOnlyRead?: boolean
  ) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("fromRecord", fromRecord);
    httpParams = httpParams.append("numRecords", numRecords);
    httpParams = httpParams.append("searchText", searchText);
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    httpParams = httpParams.append(
      "getStatusNotification", "true"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-todolist-list/" +
        fromRecord +
        "/" +
        numRecords,
      {
        params: httpParams,
      }
    );
  }

  getDocument(searchedText) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("searchedText", searchedText);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/user-accept-proposal/",
      {
        params: httpParams,
      }
    );
  }

  getChatMsgs() {
    let httpParams = new HttpParams();

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-user-to-do-list/",
      {
        params: httpParams,
      }
    );
  }

  getDetailExperience(idExp){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("experienceId", idExp);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-experience-for-user-by-id/",
      {
        params: httpParams,
      }
    );
  }

  getDetailInduction(eventId){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("eventId", eventId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-induction-plan-for-user-by-id/",
      {
        params: httpParams,
      }
    );
  }

  getCities(province?){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("provincia", province);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-comuni-list",
      {
        params: httpParams,
      }
    );
  }

  getMaritalStatus() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-stato-civile/", {}
    );
  }

  confirmPresenceExperience(experienceId) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/confirm-experience-for-user/",
      {
        experienceId
      }
    );
  }

  confirmPresenceInduction(eventId) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/confirm-induction-plan-for-user/",
      {
        eventId
      }
    );
  }

  getProfileInfo() {
    let httpParams = new HttpParams();

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-my-profile-data/",
      {
        params: httpParams,
      }
    );
  }

  setProfileInfo(info, sessionStorageId) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/create-or-update-newrecruit/",
      {
        user: info,
        sessionStorageId,
      }
    );
  }

  setProfileInfoProducer(info, sessionStorageId) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/create-or-update-newrecruit-producer/",
      {
        user: info,
        sessionStorageId,
      }
    );
  }

  uploadAvatar(uploadObj) {
    let formData = new FormData();
    formData.append("file", uploadObj);
    formData.append("filename", "newAvatar.png");
    let params = new HttpParams();
    const options = {
      params: params,
    };
    const req = new HttpRequest(
      "POST",
      this.applicationContext + "rest-api/onboarding-mediator/upload-avatar",
      formData,
      options
    );
    return this.http.request(req);
  }

  uploadDocumentProducer(body, uploadObj) {
    let formData = new FormData();
    formData.append("file", uploadObj);
    formData.append("fileName", uploadObj.name);

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        formData.append(key, body[key]);
      }
    }

    let params = new HttpParams();
    const options = {
      params,
    };
    const req = new HttpRequest(
      "POST",
      this.applicationContext +
        "rest-api/onboarding-mediator/upload-document-validation-for-producer",
      formData,
      options
    );
    return this.http.request(req);
  }

  uploadDocument(body, uploadObj) {
    let formData = new FormData();
    formData.append("file", uploadObj.file);
    formData.append("fileName", uploadObj.name);

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        formData.append(key, body[key]);
      }
    }

    let params = new HttpParams();
    const options = {
      params,
    };
    const req = new HttpRequest(
      "POST",
      this.applicationContext +
        "rest-api/onboarding-mediator/upload-document-validation",
      formData,
      options
    );
    return this.http.request(req);
  }

  getJourneyStructureProducer() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-journey-structure-producer/"
    );
  }

  getJourneyStructure() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-journey-structure/"
    );
  }

  getJourneyStructureById() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-journey-status-for-user/"
    );
  }

  getJourneyStructureByIdProducer() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-journey-status-for-user-producer/"
    );
  }

  getDocumentsOfUserByParameters(parameters) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-upload-by-user-and-parameter/",
      {
        params: parameters,
      }
    );
  }

  getDocumentValidationByUser() {
    let httpParams = new HttpParams();
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-document-validation-by-user/",
      {
        params: httpParams,
      }
    );
  }

  rejectDocumentValidationForProducer(validationId: string, documentType?: string, statusNote?: string) {

    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/reject-document-validation-for-producer/",
      {
        validationId,
        documentType,
        statusNote
      }
    );
  }

  getDocumentValidationByStep(
    userId: string,
    step: "INVIO-LETTERE" | "INVIO-DOCUMENTI" | "RICHIEDI-DOCUMENTI"
  ) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-document-validation-by-step/",
      {
        params: {
          userId,
          step,
          searchStatus: "true",
        },
      }
    );
  }

  getDocumentValidationById(validationId) {
    const params = { validationId };

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-document-validation-by-id/",
      {
        params,
      }
    );
  }
  getRecruitById(userId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("newRecruitId", userId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-by-id/",
      {
        params: httpParams,
      }
    );
  }

  getDocumentsValidationById(validationId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("validationId", validationId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-validation-by-id/",
      {
        params: httpParams,
      }
    );
  }

    //Take a temporary token for get the external resources
    //this token can only be used once
    getTemporaryToken(validationId: string, uploadId: string) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append("validationId", validationId);
      httpParams = httpParams.append("uploadId", uploadId);

      return this.http.get<CommonClasses.SenecaResponse<any>>(
        this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-document-masked-url/",
        {
          params: httpParams,
        }
      );
    };

  getTempUrl(userId, step, documentType){
    let httpParams = new HttpParams();
      httpParams = httpParams.append("userId", userId);
      if (documentType == 'ENGAGEMENTLETTER') {
        httpParams = httpParams.append("step", "LETTERA-ASSUNZIONE");
      } else {
        httpParams = httpParams.append("step", step);
      }

      httpParams = httpParams.append("documentType", documentType);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-template-url/",
      {
        params: httpParams,
      }
    );
  }

  getProvinceList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-province-list/", {}
    );
  }

  acceptDocumentValidation(body: any) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/accept-document-validation/",
      body
    );
  }

  getHrbpAvatar() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-avatar-by-userId/"
    );
  }

  getZoneManagerAvatar() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-avatar-by-userId-for-producer/"
    );
  }

  chat_initializeChat(sessionStorageId, recipientId) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/initialize-chat/",
      {
        params: { sessionStorageId, recipientId },
      }
    );
  }

  chat_initializeChatPro(sessionStorageId, recipientId) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/initialize-chat-for-producer/",
      {
        params: { sessionStorageId, recipientId },
      }
    );
  }

  chat_sendMessage(sessionStorageId, textMessage, recipientId, uploadId?) {
    /*let httpParams = new HttpParams();
    httpParams = httpParams.append("sessionStorageId", sessionStorageId);
    httpParams = httpParams.append("text", text);
    httpParams = httpParams.append("recipientId", recipientId);*/
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/send-message/",
      {
        sessionStorageId,
        textMessage,
        recipientId,
        uploadId,
      }
    );
  }

  chat_getLastMessage(
    recipientId: string,
    numRecords: string = "10",
    fromRecord: string = "0"
  ) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-last-chat-message/",
      {
        params: { recipientId, numRecords, fromRecord },
      }
    );
  }

  //Marca come letti i messaggi dello user indicato in input.
  chat_updateMarkMessage(recipientId?) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/update-chat-mark/",
      {
        params: { recipientId },
      }
    );
  }

  getMarkDate(recipientId?) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-mark-date/",
      {
        params: { recipientId },
      }
    );
  }

  getQualifications(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-titolo-studio/", {}
    );
  }

  getDocuments(userId, step){
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('step', step);
    httpParams = httpParams.append('searchStatus', 'true');

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-validation-by-step-producer/",
      {
        params: httpParams,
      }
    );
  }

  generateDocs(userId, step?, status?){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/generate-document-validation-by-step-for-producer/",
      {
        userId: userId,
        step: (step ? step : 'CONTROLLO-DOCUMENTI'),
        defaultStatus: (status ? status : "REQUESTED"),
      }
    );
  }


}
