/*
 *  Componente che permette l'accesso all'utente tramite form
 */

import { Component, OnInit, Output } from "@angular/core";

import { EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
  selector: "login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit {
  @Output() onLogin: EventEmitter<NgForm> = new EventEmitter();
  passwordType: string = "password";

  constructor() {}

  ngOnInit() {}

  // Effettua il login
  login(form: NgForm) {
    this.onLogin.emit(form);
  }

  changePasswordType() {
    if (this.passwordType === "password") {
      this.passwordType = "text";
    } else {
      this.passwordType = "password";
    }
  }
}
