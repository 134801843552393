import * as CommonClasses from "../../../cm2-commonclasses";
import * as fromApp from "../../ngrx/app.reducers";

import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import { Observable, Subscription, combineLatest, of } from "rxjs";

import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { UrlService } from "src/app/shared/services/url.service";

@Injectable()

export class GuruService {
  applicationData: GlobalApplicationData;
  result$: Subscription;
  applicationContext: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private urlService: UrlService
  ) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(
      fromApp.getGlobalApplicationData
    );
    const combinedSelectes$ = combineLatest(globalApplicationData$);
    this.result$ = combinedSelectes$.subscribe(([globalApplicationData]) => {
      this.applicationData = globalApplicationData;
      this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
    });
  }

  getRecruitList( numRecords, fromRecord, searchText) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("numRecords", numRecords);
    httpParams = httpParams.append("fromRecord", fromRecord);
    httpParams = httpParams.append("searchText", searchText);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-all-users-for-experience/",
      {
        params: httpParams,
      }
    );
  }

  deleteExperience(idExp) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/delete-experience-for-guru/",
      {
        experienceId: idExp
      }
    );
  }

  getDetailExperience(idExp) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("experienceId", idExp);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-detail-experience-for-user/",
      {
        params: httpParams,
      }
    );
  }

  getSlotsAvailable(parameters) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("usersId", parameters.usersId);
    httpParams = httpParams.append("startDateDay", parameters.startDateDay);
    httpParams = httpParams.append("startDateMonth", parameters.startDateMonth);
    httpParams = httpParams.append("startDateYear", parameters.startDateYear);
    httpParams = httpParams.append("endDateDay", parameters.endDateDay);
    httpParams = httpParams.append("endDateMonth", parameters.endDateMonth);
    httpParams = httpParams.append("endDateYear", parameters.endDateYear);
    httpParams = httpParams.append("startTimeHours", parameters.startTimeHours);
    httpParams = httpParams.append("startTimeMinute", parameters.startTimeMinute);
    httpParams = httpParams.append("endTimeHours", parameters.endTimeHours);
    httpParams = httpParams.append("endTimeMinute", parameters.endTimeMinute);
    httpParams = httpParams.append("meetingDuration", parameters.meetingDuration);
    httpParams = httpParams.append("resultsLimit", parameters.resultsLimit || "20");

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-availability-slot-for-experience/",
      {
        params: httpParams,
      }
    );

  }

  createEvent(parameters) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/create-experience-for-guru/",
      {
        usersId: parameters.usersId,
        description: parameters.description,
        note: parameters.note,
        location: parameters.location,
        startDate: parameters.startDate,
        endDate: parameters.endDate
      }
    );

  }

  getUserTypeList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-tipo-dipendente/"
    );
  }

  editEvent(parameters, idExp?) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/update-experience-for-guru/",
      {
        usersId: parameters.usersId,
        description: parameters.description,
        note: parameters.note,
        location: parameters.location,
        startDate: parameters.startDate,
        endDate: parameters.endDate,
        experienceId: idExp
      }
    );

  }

  getNewRecruitToPlan(onlyNotAssigned = "true") {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("onlyNotAssigned", onlyNotAssigned);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-users-for-guru/",
      {
        params: httpParams
      }
    );
  }



  getExperienceList(status: "ORGANIZED" | "CLOSED", numRecords, fromRecord, fromDate?, toDate?, searchText?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("numRecords", numRecords || "10");
    httpParams = httpParams.append("fromRecord", fromRecord || "0");
    httpParams = httpParams.append("experienceStatus", status);
    httpParams = httpParams.append("startDate", fromDate);
    httpParams = httpParams.append("endDate", toDate);
    httpParams = httpParams.append("searchText", searchText)

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-experience-list-for-user/",
      {
        params: httpParams,
      }
    );
  }

  getCounterExperiences(status: "ORGANIZED" | "CLOSED", fromDate?, toDate?, searchText?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("experienceStatus", status);
    httpParams = httpParams.append("startDate", fromDate);
    httpParams = httpParams.append("endDate", toDate);
    httpParams = httpParams.append("searchText", searchText)

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-count-experience-list-for-user/",
      {
        params: httpParams,
      }
    );
  }

  markToDoAsRead(notificationQueueId) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/mark-notification-as-read/",
      {
        notificationQueueId,
      }
    );
  }

  getGuruInfo() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-my-profile-data/"
    );
  }


  countUserToDoList(getOnlyUnread?: boolean, getOnlyRead?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    httpParams = httpParams.append(
      "getStatusNotification", "true"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-todolist-counters",
      {
        params: httpParams,
      }
    );
  }

  getUserToDoList(
    fromRecord,
    numRecords,
    searchText?: string,
    getOnlyUnread?: boolean,
    getOnlyRead?: boolean
  ) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("fromRecord", fromRecord);
    httpParams = httpParams.append("numRecords", numRecords);
    httpParams = httpParams.append("searchText", searchText)
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    httpParams = httpParams.append(
      "getStatusNotification", "true"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-todolist-list/" +
      fromRecord +
      "/" +
      numRecords,
      {
        params: httpParams,
      }
    );
  }

}
