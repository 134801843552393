import * as AuthActions from "./auth.actions";
import * as ProfileActions from "../../users/profile/ngrx/profile.actions";
import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { Actions, Effect, ofType } from "@ngrx/effects";
import {
  JwtPayload,
  SenecaResponse,
  UserAcknowledges,
} from "../../../cm2-commonclasses";
import { Observable, from, merge } from "rxjs";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";

import { AuthService } from "../services/auth.service";
import { DeviceDetectorService, DeviceInfo } from "ngx-device-detector";
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { authControl } from "src/app/shared/models/global-application-data.model";
import { catchError } from "rxjs/operators";

const jwtDecode = require("jwt-decode");

interface AuthLoginMergedObservables {
  tinyTokenSenecaResponse: SenecaResponse<string>;
  fullJwtTokenSenecaResponse?: SenecaResponse<string>;
  thenNavigateToUrl?: string;
}
@Injectable()
export class AuthEffects {

  protected actions$ = inject(Actions);

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private deviceService: DeviceDetectorService
  ) {
    let redirectUrl$: Observable<string> = this.store.select(
      fromApp.getRedirectUrl
    );
    redirectUrl$.subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });
  }

  token: string;
  tinyToken: string;
  redirectUrl: string;
  isSso: boolean;
  authDataMail: string;


  @Effect()
  authLogin$ = merge(
    this.actions$.pipe(
      ofType(AuthActions.DO_LOGIN),
      map((action: AuthActions.DoLogin) => {
        return action.payload;
      }),
      switchMap(
        (authData: { email: string; password: string; isSso?: boolean }): Observable<AuthLoginMergedObservables> => {
          this.isSso = authData.isSso;
          this.authDataMail = authData.email;
          const deviceInfo = this.deviceService.getDeviceInfo();
          const userAgent = deviceInfo && deviceInfo.userAgent;
          let deviceType;
          if (this.deviceService.isMobile()) {
            // Salvo il fatto che è uno smartphone
            deviceType = "P";
          } else if (this.deviceService.isTablet()) {
            // Salvo il fatto che è un tablet
            deviceType = "T";
          } else if (this.deviceService.isDesktop()) {
            // Salvo il fatto che è un computer desktop
            deviceType = "D";
          }
          return from(
            this.authService.login(
              authData.email,
              authData.password,
              deviceType,
              userAgent
            )
          ).pipe(
            map(senecaResponse => {
              return <AuthLoginMergedObservables>{
                tinyTokenSenecaResponse: senecaResponse
              }
            })
          )
        }
      )),
    this.actions$.pipe(
      ofType(AuthActions.SWITCH_LOGGED_NODE),
      map((action: AuthActions.SwitchLoggedNode) => {
        return action.payload;
      }),
      switchMap((actionPayload: { thenNavigateToUrl: string }): Observable<AuthLoginMergedObservables> => {
        const deviceInfo: DeviceInfo = this.deviceService.getDeviceInfo();
        const userAgent: string = deviceInfo && deviceInfo.userAgent;
        let deviceType;
        if (this.deviceService.isMobile()) {
          // Salvo il fatto che è uno smartphone
          deviceType = "P";
        } else if (this.deviceService.isTablet()) {
          // Salvo il fatto che è un tablet
          deviceType = "T";
        } else if (this.deviceService.isDesktop()) {
          // Salvo il fatto che è un computer desktop
          deviceType = "D";
        }

        return from(this.authService.switchLoggedNode({
          deviceType: deviceType,
          userAgent: userAgent
        })).pipe(
          map(senecaResponse => {
            return <AuthLoginMergedObservables>{
              tinyTokenSenecaResponse: senecaResponse,
              thenNavigateToUrl: actionPayload.thenNavigateToUrl
            }
          })
        )
      })
    )
  )
    .pipe(
      switchMap((result: AuthLoginMergedObservables): Observable<AuthLoginMergedObservables> => {
        const tinyTokenObj: SenecaResponse<string> = result.tinyTokenSenecaResponse;
        if (tinyTokenObj.error) {
          throw new Error(tinyTokenObj.error);
        }
        return from(this.authService.getJWTToken(tinyTokenObj.response)).pipe(
          map(senecaResponse => {
            return <AuthLoginMergedObservables>{
              tinyTokenSenecaResponse: tinyTokenObj,
              fullJwtTokenSenecaResponse: senecaResponse,
              thenNavigateToUrl: result.thenNavigateToUrl
            }
          })
        );
      }),
      mergeMap((result: AuthLoginMergedObservables) => {
        const tinyToken: string = result.tinyTokenSenecaResponse.response;
        const fullJwtToken: string = result.fullJwtTokenSenecaResponse.response;
        let actionsContainer = [];
        let authObject = null;
        let decodedJwt: JwtPayload = jwtDecode(fullJwtToken);
        authObject = authControl(decodedJwt && decodedJwt.auths);
        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
        } else if (authObject.isHrbpPro) {
          this.router.navigate(["hrbpPro"]);
        }
        else if (authObject.isAdmin) {
          this.router.navigate(["hrbp"]);
        }
        else if (authObject.isZonemanager) {
          this.router.navigate(["zonemanager"]);
        }
        else if (authObject.isRecruiter) {
          this.router.navigate(["recruiter"]);
        }
        else if (authObject.isManager) {
          this.router.navigate(["manager"]);
        }
        else if (authObject.isGuru) {
          this.router.navigate(["guru"]);
        } else {
          this.router.navigate(["/"]);
        }
        actionsContainer.push(
          {
            type: AuthActions.SET_TOKEN,
            payload: tinyToken,
          },
          {
            type: AuthActions.SET_USER_AUTHENTICATED,
          },
          {
            type: CoreActions.REMOVE_REDIRECT_URL,
          },
          {
            type: CoreActions.START_RENEW_TOKEN_POLLING,
            payload: {
              redirectUrl: result.thenNavigateToUrl
            }
          }
        );
        return actionsContainer;
      }),
      catchError((err, caught) => {
        if (err && err.message) {
          this.toastr.error(this.translate.instant("errors." + err.message));
        }
        return caught;
      })
    );

  // Effect che recupera la lista delle userAcknowledges dell'utente collegato
  @Effect()
  acknowledgedGet = this.actions$.pipe(
    ofType(AuthActions.RETRIEVE_USER_ACKNOWLEDGES),
    switchMap(() => {
      return from(this.authService.getAllUserAcknowledges());
    }),
    map((data: SenecaResponse<UserAcknowledges>) => {
      if (data) {
        if (data.error) {
          // Catturo l'errore
          throw new Error(data.error);
        } else {
          return {
            type: AuthActions.SET_USER_ACKNOWLEDGES,
            payload: data.response,
          };
        }
      }
    }),
    catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant("errors." + err.message));
      }
      this.store.dispatch(new AuthActions.SetUserAcknowledges(null));
      return caught;
    })
  );

  // Effect che aggiorna la lista delle userAcknowledges dell'utente collegato
  @Effect()
  acknowledgedUpdate = this.actions$.pipe(
    ofType(AuthActions.UPDATE_USER_ACKNOWLEDGES),
    switchMap((action: any) => {
      return from(this.authService.updateUserAcknowledges(action.payload));
    }),
    map((data: SenecaResponse<UserAcknowledges>) => {
      if (data) {
        if (data.error) {
          // Catturo l'errore
          throw new Error(data.error);
        } else {
          return {
            type: AuthActions.RETRIEVE_USER_ACKNOWLEDGES,
            payload: null,
          };
        }
      }
    }),
    catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant("errors." + err.message));
      }
      this.store.dispatch(new AuthActions.SetUserAcknowledges(null));
      return caught;
    })
  );

  // Dobbiamo esplicitare il fatto che non eseguiamo nessun dispatch. Ovviamente ngrx ha bisogno di lasciare fluire l'Observable in entrata (questo il motivo per cui utilizziamo il do() al posto del subscribe())
  @Effect()
  authLogout$ = this.actions$
    .pipe(
      ofType(AuthActions.LOGOUT)
      , switchMap(() => {
        return from(this.authService.logout());
      })
      , mergeMap(
        (data: SenecaResponse<any>) => {
          if (data.response === null && data.error === null) {

            let actionsContainer = [
              {
                type: AuthActions.SESSION_LOGOUT
              },
              {
                type: AuthActions.SET_USER_ACKNOWLEDGES,
                payload: null
              },
              {
                type: CoreActions.REMOVE_APPLICATION_LANG
              },
              {
                type: ProfileActions.CANCEL_LOGGED_USER
              }
            ];
            this.router.navigate(["/login"]);
            return actionsContainer;
          }
        }
      )
      , catchError((err, caught) => {
        if (err && err.message) {
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
        return caught;
      })
    );
}
