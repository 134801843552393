// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import * as AuthActions from "../ngrx/auth.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, combineLatest } from "rxjs";

import { Lang } from "../../../cm2-commonclasses";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../services/auth.service";
import { RedirectService } from "src/app/shared/services/redirect.service";

@Component({
  selector: "app-local-login",
  templateUrl: "./local-login.component.html",
  styleUrls: ["./local-login.component.scss"],
})
export class LocalLoginComponent implements OnInit, OnDestroy {
  isFetchingLangs: boolean;
  langs: Lang[];
  result$;
  //forgotPassword is true when the user click the link for the recovery of password
  forgotPassword = false;
  email: string;
  emailSent = false;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private toastr: ToastrService,
    private redirectService: RedirectService,
    private _auth: AuthService,
    private router: Router
  ) {
    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
    let availableLangs$: Observable<Lang[]> = this.store.select(
      fromApp.getAvailableLangs
    );
    let isFetchingLangs$: Observable<boolean> = this.store.select(
      fromApp.isFetchingLangs
    );
    const combinedSelectes$ = combineLatest(availableLangs$, isFetchingLangs$);
    this.result$ = combinedSelectes$.subscribe(([langs, isFetchingLangs]) => {
      this.langs = langs;
      this.isFetchingLangs = isFetchingLangs;
    });
  }
  ngOnInit() {}
  ngOnDestroy() {
    this.result$.unsubscribe();
  }

  // Dal form passato come parametro, ricavo i dati immessi dall'utente per inserirli nel dispatch dell'action che tenterà il login
  onLogin(form: NgForm) {
    const email = form.value.email;
    const password = form.value.password;
    this.store.dispatch(
      new AuthActions.DoLogin({ email: email, password: password })
    );
  }

  //send mail for the recovery of the password
  async sendMail() {
    if(!this.email) return;
    this.emailSent = true;

    try {
      let res: any = await this._auth.sendMailReconveryPassword(this.email).toPromise();
    
      if(res.error) {
        this.toastr.error(this.translate.instant("errors." + res.error));
      }/* else {
        this.redirectService.goToRecoveryPassword(res.response.userId, res.response.token);
      }*/
    } catch (e) {
      console.log(e);
    }
  }

  emailValid() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.email).toLowerCase());
}

  signUp() {
    this.router.navigate(["sign-up"]);
  }
}
