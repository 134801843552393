import { RequiredAuth } from 'src/cm2-commonclasses';

/*
 * Modello che dinisce una classe con i dati comuni e utili per l'applicazione (tipo il context)
*/

export class GlobalApplicationData {
  // Prefisso applicativo (context) e le altre varibili del server
  public applicationContext: string;
  public isAliveAddress: string;
  public isMaintenanceAddress: string;
  public eTicketResourcePath: string;
  public orginalRequestedURL: string;
  // Indica se mostrare un alert di "sessione/token scaduta/o"
  public showSessionExpiredAlert: boolean;
  // Indica alla pagina di autorizzazione che deve effettuare un reload completo, in modo da pulire tutti gli status
  public forceFullPageRefresh: boolean;
  // Ruoli disponibili
  // Questo array di oggetti role/label sarà popolato durante la chiamata che recupera i dati dell'utente loggato
  public availableUserGroups: string[];
  // Azioni a cui un utente è abilitato
  public actions: string[];
  // Indica se deve essere forzata l'apertura della dialog delle preferenze utente
  public forceShowUserPrefDialog: boolean;
  // Tipo di device
  public deviceType: string;
  // Agent dell'utente
  public userAgent: string;

  constructor(applicationContext: string, isAliveAddress: string, isMaintenanceAddress: string, eTicketResourcePath: string, orginalRequestedURL: string, showSessionExpiredAlert: boolean, forceFullPageRefresh: boolean, availableUserGroups: string[], actions: string[], forceShowUserPrefDialog: boolean, deviceType: string, userAgent: string) {
    this.applicationContext = applicationContext;
    this.isAliveAddress = isAliveAddress;
    this.isMaintenanceAddress = isMaintenanceAddress;
    this.eTicketResourcePath = eTicketResourcePath;
    this.orginalRequestedURL = orginalRequestedURL;
    this.showSessionExpiredAlert = showSessionExpiredAlert;
    this.forceFullPageRefresh = forceFullPageRefresh;
    this.availableUserGroups = availableUserGroups;
    this.actions = actions;
    this.forceShowUserPrefDialog = forceShowUserPrefDialog;
    this.deviceType = deviceType;
    this.userAgent = userAgent;
  }
}

export function authControl(auths: string[]) {
  let authObject: any = {};
  if (auths && auths.filter((x: any) => {
        return x == RequiredAuth.ONBOARDING_ADMIN;
      }).length
    ) {
      authObject.isAdmin = true;
    }
    if (auths && auths.filter((x: any) => {
        return x == RequiredAuth.ONBOARDING_RECRUITER;
      }).length
    ) {
      authObject.isRecruiter = true;
    }
    if (auths && auths.filter((x: any) => {
        return x == RequiredAuth.ONBOARDING_MANAGER;
      }).length
    ) {
      authObject.isManager = true;
    }
    if (auths && auths.filter((x: any) => {
        return x == RequiredAuth.ONBOARDING_GURU;
      }).length
    ) {
      authObject.isGuru = true;
    }
    if (auths && auths.filter((x: any) => {
      return x == RequiredAuth.ONBOARDING_ZONE_MANAGER;
    }).length
    ) {
      authObject.isZonemanager = true;
    }
    if (auths && auths.filter((x: any) => {
      return x == RequiredAuth.ONBOARDING_HRBP_PRODUCER;
    }).length
  ) {
    authObject.isHrbpPro = true;
  }
    return authObject;
}
