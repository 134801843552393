import * as fromApp from "../../ngrx/app.reducers";

import {
  ErrorCodes,
  JwtPayload,
  SenecaResponse,
} from "../../../cm2-commonclasses";
import { Observable, Subscription, combineLatest, throwError } from "rxjs";

import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

@Injectable()
export class PlayerService {
  applicationData: GlobalApplicationData;
  result$: Subscription;
  loggedUser: JwtPayload;

  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {
    // Dallo store recupero l'oggetto principale contenente generici dati, e la lingua dell'applicazione
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(
      fromApp.getGlobalApplicationData
    );
    // Collegamento all'utente loggato
    const loggedUser$: Observable<JwtPayload> = this.store.select(
      fromApp.getLoggedUser
    );

    const combinedSelectes$ = combineLatest(
      globalApplicationData$,
      loggedUser$
    );
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
      }
    );
  }

  updateStatusFromPlayer(
    itemId: string,
    eventName: string,
    totalTime: number,
    currentTime: number
  ) {
    if (!!this.loggedUser && !!this.loggedUser.user) {
      let userId = this.loggedUser.user.userId;
      return this.http.post<SenecaResponse<any>>(
        this.applicationData.applicationContext +
          `rest-api/onboarding-mediator/glp-update-status-from-player/${itemId}/${eventName}/${totalTime}/${currentTime}`,
        {
          userId: userId,
        }
      );
    }
    return throwError(ErrorCodes.USER_NOT_FOUND);
  }
}
