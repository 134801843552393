/*
 *  Reducer che gestiscono e modificano lo State applicativo riguardante il profilo dell'utente loggato
 */

import * as CommonClasses from "../../../../cm2-commonclasses";
import * as ProfileActions from "./profile.actions";
import * as jwt_decode from "jwt-decode";
import { JwtPayload } from '../../../../cm2-commonclasses';
import { authControl } from 'src/app/shared/models/global-application-data.model';
import { OnboardingJWTPayloadParams } from 'atfcore-commonclasses';

export interface ProfileState {
  loggedUser: CommonClasses.JwtPayload<OnboardingJWTPayloadParams>;
  editedUser: CommonClasses.User;
  langs: CommonClasses.Lang[];
  isAdmin: boolean;
  isRecruiter: boolean;
  isManager: boolean;
  isGuru: boolean;
  isNewRecruit: boolean;
  isHrbpPro: boolean;
  isZoneManager: boolean;
  canBeNewRecruit?: boolean;
}

const initialState: ProfileState = {
  loggedUser: null,
  editedUser: null,
  langs: null,
  isAdmin: false,
  isRecruiter: false,
  isManager: false,
  isGuru: false,
  isNewRecruit: false,
  isHrbpPro: false,
  isZoneManager: false
};

export function profileReducer(
  state = initialState,
  action: ProfileActions.ProfileActions
) {
  switch (action.type) {
    case ProfileActions.UPDATE_USER:
      const oldUserInfo = state.loggedUser;
      const updatedUser = {
        ...oldUserInfo,
        ...action.payload.loggedUser,
      };
      const user = updatedUser;
      return {
        ...state,
        loggedUser: user,
        editedUser: null,
      };
    case ProfileActions.CANCEL_LOGGED_USER:
      // Cancello le informazioni sull'utente loggato
      return {
        ...state,
        loggedUser: null,
      };
    case ProfileActions.SAVE_AVAILABLE_LANGS:
      // Salva le lingue disponibili a sistema recuperate tramite servizi
      return {
        ...state,
        langs: action.payload,
      };
    case ProfileActions.DECODE_TOKEN:
      const loggedUser: JwtPayload = jwt_decode(action.payload);
      let authObject = {
        isAdmin: false,
        isRecruiter: false,
        isManager: false,
        isGuru: false,
        isHrbpPro: false,
        isZonemanager: false
      }
      if (loggedUser && loggedUser.auths && loggedUser.auths.length) {
        authObject = authControl(loggedUser && loggedUser.auths);
      }
      // Decodifico il token ricevuto dopo il login e lo salvo, questo mi identificherà l'oggetto rappresentante l'utente loggato
      return <ProfileState>{
        ...state,
        isAdmin: authObject.isAdmin,
        isRecruiter: authObject.isRecruiter,
        isManager: authObject.isManager,
        isGuru: authObject.isGuru,
        isNewRecruit: Boolean(loggedUser.params?.isNewRecruit),
        isHrbpPro: authObject.isHrbpPro,
        isZoneManager: authObject.isZonemanager,
        canBeNewRecruit: loggedUser.params.canBeNewRecruit,
        loggedUser: {
          ...state.loggedUser,
          ...loggedUser
        }
      };
    default:
      return state;
  }
}

export const getLoggedUser = (state: ProfileState): JwtPayload<OnboardingJWTPayloadParams> => state.loggedUser;
export const getAvailableLangs = (state: ProfileState) => state.langs;
export const getIsAdmin = (state: ProfileState) => state.isAdmin;
export const getIsRecruiter = (state: ProfileState) => state.isRecruiter;
export const getIsManager = (state: ProfileState) => state.isManager;
export const getIsGuru = (state: ProfileState) => state.isGuru;
export const getIsHrbpPro = (state: ProfileState) => state.isHrbpPro;
export const getIsZoneManager = (state: ProfileState) => state.isZoneManager;

