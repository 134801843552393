import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OwlDateTimeModule, OwlNativeDateTimeModule,  } from '@busacca/ng-pick-datetime';
import { AccordionComponent } from "./components/accordion/accordion.component";
import { AccordionContentComponent } from "./components/accordion-content/accordion-content.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AppTranslateModule } from "../translate/translate.module";
import { BaseComponent } from "./components/base-component.component";
import { BaseFormComponent } from "./components/base-form.component";
import { BaseSubscriberComponent } from "./components/base-subscriber.component";
import { BoxDocUploadedComponent } from "./components/box-doc-uploaded/box-doc-uploaded.component";
import { CKEditorModule } from "ng2-ckeditor";
import { CardComponent } from "./components/card/card.component";
import { ChatComponent } from "./components/chat/chat.component";
import { ChatMessageComponent } from "./components/chat-message/chat-message.component";
import { ChatSingleUserComponent } from "./components/chat-single-user/chat-single-user.component";
import { CommonModule } from "@angular/common";
import { CustomButtonComponent } from "./components/custom-button/custom-button.component";
import { DatepickerComponent } from "../core/datepicker/datepicker.component";
import { DisableFormControlDirective } from "./directive/disable-form-control.directive";
import { DocItemComponent } from "./components/doc-item/doc-item.component";
import { DocPreviewComponent } from "./components/doc-preview/doc-preview.component";
import { DocumentDetailsHeaderComponent } from "../core/document-details-header/document-details-header.component";
import { FileUploadModule } from "ng2-file-upload";
import { FlexLayoutModule } from "@angular/flex-layout";
import { GenericListPlaceholderComponent } from "./components/placeholders/generic-list-placeholder/generic-list-placeholder.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { InputSearchComponent } from "./components/input-search/input-search.component";
import { InputSearchLightComponent } from "./components/input-search-light/input-search-light.component";
import { ItemDocumentComponent } from "./components/item-document/item-document.component";
import { ItemListComponent } from "./components/item-list/item-list.component";
import { ItemListNewComponent } from "./components/item-list-new/item-list-new.component";
import { MessageComponent } from "./components/message/message.component";
import { ModalComponent } from "./components/modal/modal.component";
import { MomentModule } from "ngx-moment";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { NewInputComponent } from "./components/new-input/new-input.component";
import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxPaginationModule } from "ngx-pagination";
import { PageContainerComponent } from "./components/page-container/page-container.component";
import { PaginationComponent } from "../core/pagination/pagination.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { PreviewCardComponent } from "./components/preview-card/preview-card.component";
import { SelectComponent } from "./components/select/select.component";
import { SelectWithCheckboxComponent } from "./components/select-with-checkbox/select-with-checkbox.component";
import { ShowNotificationComponent } from "./components/show-notification/show-notification.component";
import { StepperComponent } from "./components/stepper/stepper.component";
import { TabComponent } from "./components/tab/tab.component";
import { TextareaComponent } from "./components/textarea/textarea.component";
import { TooltipModule } from "ng2-tooltip-directive";
import { UserPlaceholderComponent } from "./components/user-placeholder/user-placeholder.component";
import { AccordionWithSelectComponent } from './components/accordion-with-select/accordion-with-select.component';
import { FiltersComponent } from './components/filters/filters.component';
import { JourneySettingsComponent } from "./components/journey-settings/journey-settings.component";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FaqComponent } from './components/faq/faq.component';
import { UserSelectedListComponent } from './components/user-selected-list/user-selected-list.component';

/*
 *    Dichiarazione dei moduli comuni e delle direttive
 */

@NgModule({
  declarations: [
    ModalComponent,
    PaginationComponent,
    BaseComponent,
    BaseSubscriberComponent,
    BaseFormComponent,
    DatepickerComponent,
    DisableFormControlDirective,
    GenericListPlaceholderComponent,
    SelectComponent,
    TextareaComponent,
    CustomButtonComponent,
    InputSearchComponent,
    InputSearchLightComponent,
    ItemListComponent,
    PageContainerComponent,
    TabComponent,
    AccordionContentComponent,
    AccordionComponent,
    NavbarComponent,
    MessageComponent,
    ChatSingleUserComponent,
    ChatComponent,
    ShowNotificationComponent,
    CardComponent,
    PreviewCardComponent,
    SelectWithCheckboxComponent,
    ItemListNewComponent,
    DocumentDetailsHeaderComponent,
    ItemDocumentComponent,
    NewInputComponent,
    StepperComponent,
    UserPlaceholderComponent,
    BoxDocUploadedComponent,
    DocItemComponent,
    ChatMessageComponent,
    DocPreviewComponent,
    AccordionWithSelectComponent,
    FiltersComponent,
    JourneySettingsComponent,
    FaqComponent,
    UserSelectedListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FlexLayoutModule,
    AppTranslateModule.forRoot(),
    FileUploadModule,
    MomentModule,
    OwlDateTimeModule,
    ImageCropperModule,
    OwlNativeDateTimeModule,
    AngularSvgIconModule,
    TooltipModule,
    FormsModule,
    NgSelectModule,
    PdfViewerModule,
    NgxSliderModule

  ],
  exports: [
    ModalComponent,
    CommonModule,
    AppTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    FlexLayoutModule,
    FileUploadModule,
    PaginationComponent,
    MomentModule,
    BaseComponent,
    BaseSubscriberComponent,
    BaseFormComponent,
    SelectWithCheckboxComponent,
    DatepickerComponent,
    DisableFormControlDirective,
    AngularSvgIconModule,
    TooltipModule,
    GenericListPlaceholderComponent,
    SelectComponent,
    NgSelectModule,
    TextareaComponent,
    CustomButtonComponent,
    InputSearchComponent,
    InputSearchLightComponent,
    ItemListComponent,
    PageContainerComponent,
    TabComponent,
    AccordionContentComponent,
    AccordionComponent,
    NavbarComponent,
    MessageComponent,
    ChatComponent,
    ChatSingleUserComponent,
    ShowNotificationComponent,
    CardComponent,
    PreviewCardComponent,
    ItemListNewComponent,
    DocumentDetailsHeaderComponent,
    ItemDocumentComponent,
    NewInputComponent,
    StepperComponent,
    UserPlaceholderComponent,
    BoxDocUploadedComponent,
    DocItemComponent,
    ChatMessageComponent,
    DocPreviewComponent,
    AccordionWithSelectComponent,
  ],
})
export class SharedModule {}
