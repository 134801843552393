import { Action } from "@ngrx/store";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { IAppDocument } from "src/app/utils";

// Setta il prefisso applicativo (context) e le altre varibili del server
export const SET_CORE_APPLICATION_DATA = "SET_CORE_APPLICATION_DATA";

// Pulisce il prefisso applicativo (context) e le altre varibili del server
export const REMOVE_CORE_APPLICATION_DATA = "REMOVE_CORE_APPLICATION_DATA";

// Recupera le lingue disponibili a sistema
export const GET_AVAILABLE_LANGS = "GET_AVAILABLE_LANGS";

// Setta come completato il caricamento delle lingue disponibili
export const GET_AVAILABLE_LANGS_FINISHED = "GET_AVAILABLE_LANGS_FINISHED";

// Setta la lingua di default
export const SET_DEFAULT_LANG = "SET_DEFAULT_LANG";

// Salva l'url richiesto prima di eseguire il redirect alla pagina di login
export const SAVE_REDIRECT_URL = "SAVE_REDIRECT_URL";

// Cancella l'url di redirect dopo il login
export const REMOVE_REDIRECT_URL = "REMOVE_REDIRECT_URL";

// Apre la sidebar di sinistra col menu principale
export const OPEN_MAIN_MENU_SIDEBAR = "OPEN_MAIN_MENU_SIDEBAR";

// Chiude la sidebar di sinistra col menu principale
export const CLOSE_MAIN_MENU_SIDEBAR = "CLOSE_MAIN_MENU_SIDEBAR";

// Inizia il polling per il renew del token
export const START_RENEW_TOKEN_POLLING = "START_RENEW_TOKEN_POLLING";

// Setta la lingua dell'applicazione
export const SET_APPLICATION_LANG = "SET_APPLICATION_LANG";

// Cancella la lingua dell'applicazione
export const REMOVE_APPLICATION_LANG = "REMOVE_APPLICATION_LANG";

// Setta il contatore delle notifiche
export const SET_NOTIFICATIONS_COUNTER = "SET_NOTIFICATIONS_COUNTER";

// Setta il nome di un documento che si sta visualizzando
export const SET_SELECTED_DOCUMENT_DETAILS = "SET_SELECTED_DOCUMENT_DETAILS";

// Setta il valore dell'azione principale della pagina di un documento che si sta visualizzando
export const SET_SELECTED_DOCUMENT_DETAILS_MAIN_ACTION_VALUE =
  "SET_SELECTED_DOCUMENT_DETAILS_MAIN_ACTION_VALUE";

// Setta il testo dell'azione principale della pagina di un documento che si sta visualizzando
export const SET_SELECTED_DOCUMENT_DETAILS_MAIN_ACTION_TEXT =
  "SET_SELECTED_DOCUMENT_DETAILS_MAIN_ACTION_TEXT";

// Mostra / nasconde il footer
export const SET_SHOW_FOOTER = "SET_SHOW_FOOTER";

// Mostra / nasconde il footer
export const SET_HIDE_TOP_NAVIGATION = "HIDE_TOP_NAVIGATION";

// Mostra / nasconde il footer
export const SET_FILE_PREVIEW = "FILE_PREVIEW";

export class SetCoreApplicationData implements Action {
  readonly type = SET_CORE_APPLICATION_DATA;

  // Payload dell'azione
  constructor(public payload: GlobalApplicationData) {}
}

export class SaveRedirectUrl implements Action {
  readonly type = SAVE_REDIRECT_URL;

  // Payload dell'azione, che è l'url richiesto
  constructor(public payload: string) {}
}

export class SetApplicationLang implements Action {
  readonly type = SET_APPLICATION_LANG;

  // Payload dell'azione, che contiene l'abbreviazione della lingua scelta (es 'it')
  constructor(public payload: string) {}
}

export class SetDefaultLang implements Action {
  readonly type = SET_DEFAULT_LANG;

  constructor(public payload: string) {}
}

export class OpenMainMenuSidebar implements Action {
  readonly type = OPEN_MAIN_MENU_SIDEBAR;
}

export class CloseMainMenuSidebar implements Action {
  readonly type = CLOSE_MAIN_MENU_SIDEBAR;
}

export class GetAvailableLangs implements Action {
  readonly type = GET_AVAILABLE_LANGS;
}

export class RemoveApplicationLang implements Action {
  readonly type = REMOVE_APPLICATION_LANG;
}

export class GetAvailableLangsFinished implements Action {
  readonly type = GET_AVAILABLE_LANGS_FINISHED;
}

export class StartRenewTokenPolling implements Action {
  readonly type = START_RENEW_TOKEN_POLLING;
  constructor(
    public payload?: { redirectUrl?: string; forceRefreshUser?: boolean }
  ) {}
}

export class RemoveCoreApplicationData implements Action {
  readonly type = REMOVE_CORE_APPLICATION_DATA;
}

export class RemoveRedirectUrl implements Action {
  readonly type = REMOVE_REDIRECT_URL;
}

export class SetNotificationsCounter implements Action {
  readonly type = SET_NOTIFICATIONS_COUNTER;

  constructor(public payload: number) {}
}

export class SetSelectedDocumentDetails implements Action {
  readonly type = SET_SELECTED_DOCUMENT_DETAILS;

  constructor(public payload: string) {}
}

export class SetSelectedDocumentDetailsMainActionValue implements Action {
  readonly type = SET_SELECTED_DOCUMENT_DETAILS_MAIN_ACTION_VALUE;

  constructor(public payload: string) {}
}

export class SetSelectedDocumentDetailsMainActionText implements Action {
  readonly type = SET_SELECTED_DOCUMENT_DETAILS_MAIN_ACTION_TEXT;

  constructor(public payload: string) {}
}

export class SetShowFooter implements Action {
  readonly type = SET_SHOW_FOOTER;

  constructor(public payload: boolean) {}
}

export class SetHideTopNavigation implements Action {
  readonly type = SET_HIDE_TOP_NAVIGATION;

  constructor(public payload: boolean) {}
}

export class SetFilePreview implements Action {
  readonly type = SET_FILE_PREVIEW;

  constructor(public payload: IAppDocument) {}
}

// Definisco un tipo custom per eseguire il bundle delle azioni in un singolo export
export type CoreActions =
  | SetCoreApplicationData
  | GetAvailableLangs
  | GetAvailableLangsFinished
  | RemoveCoreApplicationData
  | SaveRedirectUrl
  | OpenMainMenuSidebar
  | SetDefaultLang
  | CloseMainMenuSidebar
  | StartRenewTokenPolling
  | SetApplicationLang
  | RemoveApplicationLang
  | RemoveRedirectUrl
  | SetSelectedDocumentDetails
  | SetSelectedDocumentDetailsMainActionValue
  | SetSelectedDocumentDetailsMainActionText
  | SetNotificationsCounter
  | SetShowFooter
  | SetHideTopNavigation
  | SetFilePreview;
