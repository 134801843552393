/*
* Componente che crea una singola Tab
*/
import { Component, Input } from '@angular/core';

/**
 * Classe che rappresenta le caratteristiche base dei componenti custom
 */
@Component({
    template: '<span></span>'
})
export class BaseComponent {
    @Input() hidden: boolean;

    constructor() {
        this.hidden = this.readBooleanInput(this.hidden);
    }

    readBooleanInput(flag: any): boolean {
        return !!flag && (flag === true || flag.toString() === 'true');
    }
}
