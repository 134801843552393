<div *ngIf="!demo" class="item-list-wrapper full-width" fxLayout="column" fxLayoutAlign="start" [ngClass]="{'sign-up': isSignUp}">

    <div class="button-section" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="13px">
            <!-- Checkbox -->
            <!--<div style="height: 24px; min-width: 24px;">-->
                <div *ngIf="isCheckbox" class="checkbox-indicator cursor-pointer" fxLayoutAlign="center center"
                    (click)="checkboxClicked()">
                    <svg-icon *ngIf="isChecked && !isDisabled" src="../../../../assets/img/icons/checkmark-verde.svg">
                    </svg-icon>
                    <!-- <div *ngIf="isChecked && !isDisabled" class="checked"></div> -->
                    <!-- <div *ngIf="!isChecked" class="not-checked"></div> -->
                </div>
            <!--</div>-->
            <span [ngStyle]="customTitleStyle" [ngClass]="'title-document ' + (isDisabled ? 'disabled-text' : '') + ' ' + (lowerCaseTitle ? '' : 'uppercase')">
                {{documentName}}
            </span> 
        </div>
        <!-- Toggle -->
        <div *ngIf="isToggle" class="toggle-indicator cursor-pointer"
            [ngClass]="{checked: isToggleChecked, 'not-active': isDisabled}" (click)="toggleClicked()">
            <div *ngIf="!isDisabled" class="active"></div>
            <div *ngIf="isDisabled" class="not-active"></div>
        </div>
    </div>

    <div *ngIf="textContent && !hideBottom" class="document-state-section" fxLayout="row"
        fxLayoutAlign="space-between center" (click)=emitOnItemClicked()>
        <!-- text and date -->
        <div fxLayout="column" fxLayoutAlign="start start">
            <span class="textContent">{{textContent | translate}}</span>
            <p class="date" *ngIf="date">
                {{date | date:'dd.MM.yyyy'}}
            </p>
        </div>

        <!-- colored oval with text inside -->
        <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="17px">
            <div *ngIf="rightText" class="right-text" [ngClass]="statusDocument" fxLayoutAlign="center center">
                <p>{{ rightText | translate }}</p>
            </div>

            <!-- right svg -->
            <svg-icon src="../../../../assets/img/icons/chevron-right.svg"></svg-icon>
        </div>
    </div>

    <div *ngIf="demo" class="item-wrapper" fxLayout="row" fxLayoutAlign="start center">
        <div class="box-document-name">
            {{ documentName | translate }}
        </div>
        
    </div>

</div>