<div style="height: 100vh" fxLayout="row">
  <div fxLayout="column" fxLayoutAlign="center center" fxFill>
    <div class="logo-container" fxLayout="column" fxLatyoutAlign="center center">
      <img width="140" class="logoRegular cursor-pointer" src="assets/img/icons/logo_generali.svg">
    </div>
    <h2>
      Onboarding
    </h2>
    <div fxLayout="row" fxLayoutAlign="center center">
    </div>
    <h4 class="text-center">Caricamento in corso
    </h4>
  </div>
</div>