<div class="modal" [ngStyle]="modalStyle">
  <div #modalEl class="modal-body" [ngStyle]="modalBodyStyle" tabindex="-1" aria-modal="true" role="dialog">

    <!-- Icona di chiusura in position absolute-->
    <button *ngIf="showBackBtn" class="back-button-modal" tabindex="0" (click)="close()">
      <svg-icon src="assets/img/icons/back-button.svg"></svg-icon>
    </button>

    <!-- Immagine e titolo-->
    <div class="img-title-wrapper" *ngIf="svgImage || title" fxLayout="column" fxLayoutAlign="start start"
      fxLayoutGap="4px">
      <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
        <svg-icon *ngIf="svgImage" class="svgImage" [src]="svgImage"></svg-icon>
      </div>
      <div *ngIf="title" class="title">{{title | translate}}</div>
      <div *ngIf="subtitle" class="subtitile">{{ subtitle }}</div>
    </div>

    <div *ngIf="isLoading">
      <generic-list-placeholder *ngFor="let number of [1,2,3]" class="full-width"></generic-list-placeholder>
    </div>

    <div *ngIf="!isLoading">
      <div style="min-height: 111px;">
        <ng-content></ng-content>
      </div>
      <div class="buttons" fxLayout="column" fxLayout.gt-xs="row-reverse" fxLayoutAlign="start center"
        fxLayoutAlign.gt-xs="start start" fxLayoutGap="8px" fxLayoutGap.gt-xs="24px">
        <custom-button class="right-align" *ngIf="confirmButton" dimension="modal-btn-regular" importance="primary"
          [text]="confirmButtonPlaceholder | translate" (click)="confirmButtonClicked()">
        </custom-button>
        <custom-button class="right-align" *ngIf="rejectButton" dimension="modal-btn-regular" importance="secondary"
          [text]="rejectButtonPlaceholder | translate" (click)="rejectButtonClicked()">
        </custom-button>
      </div>
    </div>
  </div>

  <!--<div *ngIf="isLoading" style="min-height: 111px;">
        <generic-list-placeholder *ngFor="let number of [1,2,3]" class="full-width"></generic-list-placeholder>
    </div>-->
</div>
