import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from '../../shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
    constructor(private store: Store<fromApp.AppState>,
        private router: Router,
        public translate: TranslateService,
        public redirectService: RedirectService,
        public authService: AuthService) {
    }
}