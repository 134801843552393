import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { IAppDocument } from "src/app/utils";

@Component({
  selector: "app-doc-preview",
  templateUrl: "doc-preview.component.html",
  styleUrls: ["doc-preview.component.scss"],
})
export class DocPreviewComponent implements OnInit {
  @Input() document;
  @Output() onClose: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
