<div class="input-wrapper-style-guides full-width" fxLayout="column" fxLayoutGap="16px">

    <div class="label" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
        <p>{{(label | translate)}}</p>
        <p *ngIf="isRequired" class="red-text">*</p>
        <svg-icon *ngIf="hasTooltip" class="tooltip-img" placement="bottom" height="38px" hide-delay="50"
            tooltip-class="custom-tooltip" tooltip="{{tooltipText}}"
            src="assets/img/icons/question-mark-rounded-pos.svg">
        </svg-icon>
    </div>

    <div class="input-bar full-width" [ngClass]="{'isDisabled': isDisabled}">
        <input [type]="typeText" class="input-box full-width" [disabled]="isDisabled" [min]="min" [max]="max" [maxlength]="maxLength"
            [placeholder]="placeholder | translate" (ngModelChange)="emitEvent($event)" [(ngModel)]="value" (keydown)="keyDown($event)">
        <svg-icon
            [src]="errorText ? 'assets/img/icons/input-error.svg' : (status === 'toCompile' ? 'assets/img/icons/input-to-compile.svg' : '')">
        </svg-icon>
    </div>

    <p *ngIf="errorText" class="error-desc full-width">
        {{errorText | translate}}
    </p>
</div>
