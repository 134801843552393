import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { take, map } from "rxjs/operators";

// Import dello State dell'applicativo
import * as fromApp from "../../ngrx/app.reducers";
// Import del tipo di informazione che estrapolerò dallo State
import * as fromAuth from "../ngrx/auth.reducers";
// Import delle azioni dell'auth
import * as AuthActions from "../ngrx/auth.actions";
// Import delle azioni del core
import * as CoreActions from "../../core/ngrx/core.actions";
import { LangsService } from "src/app/core/services/langs.service";
import { AuthService } from './auth.service';

function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {

    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

@Injectable()
export class AuthGuard  {
  constructor(
    private store: Store<fromApp.AppState>,
    private langsService: LangsService,
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return (
      this.store
        .select("auth")
        .pipe(
          take(1),
          map((authState: fromAuth.AuthState) => {
            let url = state.url;
            let urlWithoutToken = null;
            let skipSso: boolean;

            if (authState.authenticated) {
              // Utente correttamente loggato e può accedere alla risorsa
              return true;
            }

            // Potrebbe essere stato fatto un refresh, di conseguenza non c'è più il dato all'interno dello State. Guardo quindi se ho il token in sessione, perché se ce l'avessi significa che l'utente è già autenticato
            let sessionStorageToken: string = sessionStorage.getItem("token");
            let localStorageToken: string = localStorage.getItem("token");

            if (!sessionStorageToken && localStorageToken) {
              sessionStorage.setItem("token", localStorageToken);
              sessionStorageToken = localStorageToken;
              localStorage.removeItem("token");
            }

            if (!urlWithoutToken) {
              urlWithoutToken = url;
            }

              // Uso questo vanilla JS e window.location.href perché la variabile "url" ed il router non sono aggiornati con il token appeso
              let urlToken: string = null;
              let skipSsoParam;
              let ssortkqpParam;
              let query =
                window.location.search.substring(1) ||
                window.location.hash.substring(1);
              let vars = query.split("&");
              for (let i = 0; i < vars.length; i++) {
                let pair = vars[i].split("=");
                if (
                  decodeURIComponent(pair[0]) == "skipSso" ||
                  pair[0].includes("skipSso")
                ) {
                  skipSsoParam = decodeURIComponent(pair[1]);
                  break;
                }
                if (
                  decodeURIComponent(pair[0]) == "token" ||
                  pair[0].includes("token")
                ) {
                  urlToken = decodeURIComponent(pair[1]);
                  sessionStorage.setItem("token", urlToken);
                  sessionStorageToken = urlToken;
                  break;
                } else if(
                  decodeURIComponent(pair[0]) == "ssortkqp" ||
                  pair[0].includes("ssortkqp")
                ) {
                  ssortkqpParam = decodeURIComponent(pair[1]);
                  break;
                }
              }
              
              if (skipSsoParam) {
                skipSso = true;
                url = removeURLParameter(url, "skipSso");
              }
  
              // Salvataggio se dell'url di redirect se questo è tra gli entrypoint consentiti
              if (url) {
                this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                sessionStorage.setItem("redirectUrl", url);
              }

              if(ssortkqpParam && !urlToken) {
                let getTokenFromKeyPromise = this.getTokenFromSsortkqp(ssortkqpParam);
                getTokenFromKeyPromise.then((token: string) => {
                  if(token && token.length) {
                  sessionStorage.setItem("token", token);
                  sessionStorageToken = sessionStorage.getItem("token");
                  }

                  if (sessionStorageToken) {
                    this.store.dispatch(new AuthActions.SetUserAuthenticated());
                    this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                    return true;
                  } else {
                    let url = state.url;
              if (url) {
                if (skipSsoParam) {
                  skipSso = true;
                  url = removeURLParameter(url, "skipSso");
                }
                this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
              }

              if (skipSso) {
                this.router.navigate(["/localLogin"]);
              } else {
                this.router.navigate(["/login"]);
              }
                  }

                })
            } else {
              if (sessionStorageToken) {
                this.store.dispatch(new AuthActions.SetUserAuthenticated());
                this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                return true;
              } else {
              // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
              let url = state.url;
              if (url) {
                if (skipSsoParam) {
                  skipSso = true;
                  url = removeURLParameter(url, "skipSso");
                }
                this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
              }

              if (skipSso) {
                this.router.navigate(["/localLogin"]);
              } else {
                this.router.navigate(["/login"]);
              }
            }
            return false;
            }
          })
        )
    );
  }

  // Recupera token dalla chiave dell'url
  getTokenFromSsortkqp(key: string) {
    return new Promise((resolve, reject) => {
      this.authService.retrieveTokenAfterLogin(key).subscribe((senecaResponse) => {
        if (senecaResponse.error) {
          reject();
        } else {
          if(senecaResponse && senecaResponse.response) {
            resolve(senecaResponse.response);
          } else {
            resolve(null);
          }
        }
      },
        (err) => {
          reject();
        })
    }
    )
  }
}