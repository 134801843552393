import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "custom-button",
  templateUrl: "./custom-button.component.html",
  styleUrls: ["./custom-button.component.scss"],
})
export class CustomButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() dimension: string;
  @Input() importance: string;
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  @Input() text: string;
  @Input() uppercase: boolean;
  @Input() isLibraryOnlineImg: boolean;
  @Input() customStyle: any;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onButtonClick(data?) {
    this.onButtonClicked.emit(data);
  }
}
