import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "new-input",
  templateUrl: "./new-input.component.html",
  styleUrls: ["./new-input.component.scss"],
})
export class NewInputComponent implements OnInit {
  @Input() label?: string;
  @Input() value?= "";
  @Input() isDisabled?: boolean;
  @Input() placeholder?: string;
  @Input() status?= "empty";
  @Input() isRequired?: boolean;
  @Input() hasTooltip?: boolean;
  @Input() tooltipText?: string;
  @Input() errorText?: string;
  @Input() typeText?: string = 'text';
  @Input() min="0"
  @Input() max?;
  @Input() moreCheck = false;
  @Input() maxLength?;
  @Input() negativeAllowed: boolean = false;
  @Output() onTextChange = new EventEmitter();
  @Output() onKeyDown = new EventEmitter();

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void { }

  emitEvent(event) {
    this.onKeyDown.emit(event);
    this.onTextChange.emit(event); //retrocompatibilità
  }

  keyDown(data) {
    if(this.maxLength && this.moreCheck) {
      if(this.value && this.value.length > (this.maxLength-1) && data.key != "Backspace") {
        return false;
      }
    }
    return data;
  }
  /*textChanged(){
    window.console.log(this.value)
    this.onTextChange.emit(this.value);
  }*/
}
