import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { UserService } from "../../../users/services/user.service";
import { HrbpService } from "../../../hrbp/services/hrbp.service";
import { RecruiterService } from "../../../recruiter/services/recruiter.service";
import { GuruService } from 'src/app/guru/services/guru.service';
import { HrbpProService } from 'src/app/hrbp-pro/services/hrbp-pro.service';
import { ZonemanagerService } from 'src/app/zonemanager/services/zonemanager.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private notificationsNewRecruitCounterEmitter = new BehaviorSubject('0');
  notificationsNewRecruitCounter = this.notificationsNewRecruitCounterEmitter.asObservable();

  private notificationsToDoListCounterEmitter = new BehaviorSubject('0');
  notificationsToDoListCounter = this.notificationsToDoListCounterEmitter.asObservable();

  private notificationsToDoListCounterEmitterUser = new BehaviorSubject('0');
  notificationsToDoListCounterUser = this.notificationsToDoListCounterEmitter.asObservable();

  private notificationsJourneyCounterEmitter = new BehaviorSubject('0');
  notificationsJourneyCounter = this.notificationsJourneyCounterEmitter.asObservable();

  private notificationsDocumentsCounterEmitter = new BehaviorSubject('0');
  notificationsDocumentsCounter = this.notificationsDocumentsCounterEmitter.asObservable();

  private notificationsToDoListCounterEmitterRecruiter = new BehaviorSubject('0');
  notificationsToDoListCounterRecruiter = this.notificationsToDoListCounterEmitterRecruiter.asObservable();

  private notificationsToDoListCounterEmitterGuru = new BehaviorSubject('0');
  notificationsToDoListCounterGuru = this.notificationsToDoListCounterEmitterGuru.asObservable();

  private notificationsToDoListCounterEmitterHrbpPro = new BehaviorSubject('0');
  notificationsToDoListCounterHrpbPro = this.notificationsToDoListCounterEmitterHrbpPro.asObservable();

  private notificationsToDoListCounterEmitterZonemanager = new BehaviorSubject('0');
  notificationsToDoListCounterZonemanager = this.notificationsToDoListCounterEmitterZonemanager.asObservable();

  constructor(
    private hrbpService: HrbpService,
    private userService: UserService,
    private recruiterService: RecruiterService,
    private guruService: GuruService,
    private hrbpProService: HrbpProService,
    private zonemanagerService: ZonemanagerService
    ) {
  }

  ngOnInit(){
    //this.emitTest()
  }

  async updateNewRecruitCounterHeader() {
    try {
      let res = await this.hrbpService.getCounterHrbpNewRecruit().toPromise();

      if (res && res.error) {
        //show error?
        //this.toastr.error(this.translate.instant("errors." + res.error));
      } else {
        this.notificationsNewRecruitCounterEmitter.next(res.response || "0");
      }

    } catch(e) {
      console.log(e);
    }
  }

  async updateToDoListCounterHeaderUser() {
    try {
      let res = await this.userService.countUserToDoList(true).toPromise();

      if (res && res.error) {
        //show error?
        //this.toastr.error(this.translate.instant("errors." + res.error));
      } else {
        this.notificationsToDoListCounterEmitterUser.next((res.response) || "0");
      }

      return res.response;

    } catch(e) {
      console.log(e);
    }
  }

  //update counter of notifications for badge (navbar) of recruiter
  async updateToDoListCounterHeaderForRecruiter() {
    try {
      let res = await this.recruiterService.countUserToDoList(true).toPromise();

      if (res && res.error) {
        //show error?
        //this.toastr.error(this.translate.instant("errors." + res.error));
      } else {
        this.notificationsToDoListCounterEmitterRecruiter.next((res.response) || "0");
      }

      return res.response;

    } catch(e) {
      console.log(e);
    }
  }

  //update counter of notifications for badge (navbar) of guru
  async updateToDoListCounterHeaderForGuru() {
    try {
      let res = await this.guruService.countUserToDoList(true).toPromise();

      if (res && res.error) {
        //show error?
        //this.toastr.error(this.translate.instant("errors." + res.error));
      } else {
        this.notificationsToDoListCounterEmitterGuru.next((res.response) || "0");
      }

      return res.response;

    } catch(e) {
      console.log(e);
    }
  }

  async updateToDoListCounterHeaderForHrbpPro() {
    try {
      let res = await this.hrbpProService.countUserToDoList(true).toPromise();

      if (res && res.error) {
        //show error?
        //this.toastr.error(this.translate.instant("errors." + res.error));
      } else {
        this.notificationsToDoListCounterEmitterHrbpPro.next((res.response) || "0");
      }

      return res.response.totalRowsCount;

    } catch(e) {
      console.log(e);
    }
  }

  async updateToDoListCounterHeaderForZonemanager() {
    try {
      let res = await this.zonemanagerService.countUserToDoList(true).toPromise();

      if (res && res.error) {
        //show error?
        //this.toastr.error(this.translate.instant("errors." + res.error));
      } else {
        this.notificationsToDoListCounterEmitterZonemanager.next((res.response) || "0");
      }

      return res.response.totalRowsCount;

    } catch(e) {
      console.log(e);
    }
  }

  async updateToDoListCounterHeader() {
    try {
      let res = await this.hrbpService.countUserToDoList(true).toPromise();

      if (res && res.error) {
        //show error?
        //this.toastr.error(this.translate.instant("errors." + res.error));
      } else {
        this.notificationsToDoListCounterEmitter.next((res.response) || "0");
      }

      return res.response.totalRowsCount;

    } catch(e) {
      console.log(e);
    }
  }

  async updateJourneyCounterHeader() {
    try {
      const result = await this.userService.getCountJourneyNotification(true).toPromise();
      if(!result.error){
        this.notificationsJourneyCounterEmitter.next(result.response);
      }
    } catch(e) {
      console.log(e);
    }
  }

  async updateDocumentsCounterHeader() {
    try {
      const result = await this.userService.getCountDocumentsNotification().toPromise();
      if(!result.error){
        this.notificationsDocumentsCounterEmitter.next(result.response);
      }
    } catch(e) {
      console.log(e);
    }
  }

  emitTest(){
    this.notificationsNewRecruitCounterEmitter.next('test');
  }

}
