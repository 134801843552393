/*
 *    Servizio che costruisce e tornag li URL delle varie applicazioni
 */
export class UrlService {
  private development: boolean;
  private staging: boolean;
  private production: boolean;

  constructor() {
    this.checkHostName();
  }

  checkHostName() {
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      this.development = true;
    } else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
      this.development = true;
    } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      this.staging = true;
    } else {
      this.production = true;
    }
  }

  // Ritorna l'URL dell'applicazione
  getApplicationUrl() {
    let domain = "";
    let applicationUrl: any = {
      url: null,
      baseUrl: null,
      protocol: "",
    };
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    applicationUrl.protocol =
      window.location.protocol === "http:" ? "http" : "https";

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(
      firstDot,
      window.location.hostname.length
    );
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(
        1,
        window.location.hostname.length
      );
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      domain = "atfob.alloy.it";
      this.development = true;
    } else if (window.location.hostname.indexOf("atfob") == 0 && firstDot > 0) {
      domain = "atfob.alloy.it";
      this.development = true;
    } else if (
      window.location.hostname.indexOf("atfobdemo") == 0 &&
      firstDot > 0
    ) {
      domain = "atfobdemo.alloy.it";
      this.development = true;
    } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      domain = "testob." + secondLevelDomain;
      this.staging = true;
    } else {
      //domain = "coursemanager." + secondLevelDomain;
      let url = window.location.hostname.substring(0, firstDot);
      if (url) {
        url = url + ".";
      }
      domain = url + secondLevelDomain;
      this.production = true;
    }
    /*applicationUrl.baseUrl = 'https://atfob.alloy.it/'
      //applicationUrl.protocol + "://" + domain + ":" + port + "/";
    applicationUrl.url = 'https://atfob.alloy.it' //applicationUrl.protocol + "://" + domain;*/
    // applicationUrl.baseUrl =
    // applicationUrl.protocol + "://" + domain + ":" + port + "/";
    // applicationUrl.url = applicationUrl.protocol + "://" + domain;
    applicationUrl.baseUrl = 'https' + "://" + domain +  "/";
    applicationUrl.url = 'https' + "://" + domain;
    /* else {
            //domain = "coursemanager." + secondLevelDomain;
            domain = "atfcm2.alloy.it";
        }
        applicationUrl.baseUrl = applicationUrl.protocol + "s://" + domain + "/";
        applicationUrl.url = applicationUrl.protocol + "s://" + domain; */
    return applicationUrl;
  }

  // Ritorna l'URL dell'applicazione
  getSSOUrl(deviceType: string, userAgent: string, urlExtension?: string) {
    let domain = "";
    let ssoUrl: string = null;
    let ssoReturnPage: string = null;
    // Calcolo il dominio di appartenenza
    const protocol = window.location.protocol === "http:" ? "http" : "https";

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    const lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(
      firstDot,
      window.location.hostname.length
    );
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(
        1,
        window.location.hostname.length
      );
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      domain = "localhosts.alloy.it";
    } else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
      domain = "atfs.alloy.it";
    } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      domain = "testsso." + secondLevelDomain;
    } else {
      domain = "sso.generalilearningplatform.com";
    }
    ssoReturnPage = this.getApplicationUrl().url;
    if (urlExtension && urlExtension.length) {
      ssoReturnPage = ssoReturnPage + urlExtension;
    }
    ssoUrl =
      protocol +
      "://" +
      domain +
      "/login?originApplicationName=" +
      encodeURIComponent("COURSEMANAGER") +
      "&applicationUrl=" +
      encodeURIComponent(ssoReturnPage) +
      "&deviceType=" +
      encodeURIComponent(deviceType) +
      "&userAgent=" +
      encodeURIComponent(userAgent);
    return ssoUrl;
  }

  // Proxa l'url di un'immagine
  getExternalResourceName = (requestUrl: string, requestName: string) => {
    return (
      this.getApplicationUrl().baseUrl +
      "rest-api/onboarding-mediator/proxy-url/" +
      encodeURIComponent(requestName) +
      "?requestUrl=" +
      encodeURIComponent(requestUrl)
    );
  };

  // Torna la pagina di default per il redirect (ad esempio dopo il login) in base all'auth dell'utente
  getDefaultRedirectPage = (isAdmin?: boolean, isRecruiter?: boolean, isManager?: boolean, isGuru?: boolean, isHrbpPro?: boolean, isZoneManager?: boolean) => {
    if(isAdmin) {
      return 'hrbp';
    } else if(isRecruiter) {
      return 'recruiter';
    } else if(isManager) {
      return 'manager';
    } else if(isGuru) {
       return 'guru';
    } else if(isHrbpPro) {
      return 'hrbpPro';
    } else if(isZoneManager) {
      return 'zonemanager';
    } else {
      return 'users';
    }
  }
}

