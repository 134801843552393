<div style="height: 100vh" fxLayout="row">
  <div fxLayout="column" fxLayoutAlign="center center" fxFill>
    <div class="logo-container" fxLayout="column" fxLatyoutAlign="center center">
      <img placement="bottom" class="logoRegular cursor-pointer" src="../../../assets/img/icons/logo_generali.svg">
    </div>
    <h2>
      Onboarding
    </h2>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h1 class="error_404 text-inline">403</h1>
    </div>
    <h2 class="no-margin">FORBIDDEN!</h2>
    <h4 class="text-center"><span translate="errors.403"></span>
    </h4>
  </div>
</div>