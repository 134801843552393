import * as AuthActions from './auth.actions';
import { UserAcknowledges } from 'atfcore-commonclasses';

export interface AuthState {
  token: string;
  authenticated: boolean;
  userAcknowledges: UserAcknowledges;
}

// State iniziale
const initialState: AuthState = {
  token: null,
  authenticated: false,
  userAcknowledges: null
};

export function authReducer(state = initialState, action: AuthActions.AuthActions) {
  switch (action.type) {
    case (AuthActions.SET_USER_AUTHENTICATED):
      return {
        ...state,
        authenticated: true,
      };
    case (AuthActions.SET_USER_ACKNOWLEDGES):
      return {
        ...state,
        userAcknowledges: action.payload
      };
      case (AuthActions.SESSION_LOGOUT):
      // Rimuovo il token dal session storage
      sessionStorage.removeItem('token');
      return {
        ...state,
        authenticated: false,
        token: null
      };
    case (AuthActions.SET_TOKEN):
      sessionStorage.setItem('token', action.payload);
      return {
        ...state,
        token: action.payload
      };
    default:
      return state;
  }
}

export const getToken = (state: AuthState) => state.token;
export const isAuthenticated = (state: AuthState) => state.authenticated;
export const getUserAcknowledges = (state: AuthState) => state.userAcknowledges;