//added
import * as CommonClasses from "../../../cm2-commonclasses";
import * as fromApp from "../../ngrx/app.reducers";

import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import { Observable, Subscription, combineLatest, of } from "rxjs";

import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { UrlService } from "src/app/shared/services/url.service";
import { map } from "rxjs/operators";

@Injectable()
export class HrbpService {
  applicationData: GlobalApplicationData;
  result$: Subscription;
  applicationContext: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private urlService: UrlService
  ) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(
      fromApp.getGlobalApplicationData
    );
    const combinedSelectes$ = combineLatest(globalApplicationData$);
    this.result$ = combinedSelectes$.subscribe(([globalApplicationData]) => {
      this.applicationData = globalApplicationData;
      this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
    });
  }

  countUserToDoList(getOnlyUnread?: boolean, getOnlyRead?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    httpParams = httpParams.append(
      "getStatusNotification", "true"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-todolist-counters",
      {
        params: httpParams,
      }
    );
  }

  getCounterHrbpNewRecruit(getOnlyUnread?: boolean, getOnlyRead?: boolean) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-counters-newrecruit"
    );
  }

  getDefaultRecruiter() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-default-recruiter"
    );
  }

  getDistinctUsersFieldValues(field: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("field", field);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-distinct-users-field-values",
      {
        params: httpParams,
      }
    );
  }

  getChiefValues(socDistacco?) {
    let httpParams = new HttpParams();
    if(socDistacco) httpParams = httpParams.append("socDistacco", socDistacco);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-distinct-users-chief-values",
      {
        params: httpParams,
      }
    );
  }

  getNationList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-nations-list"
    );
  }

  getUserToDoList(
    fromRecord,
    numRecords,
    searchText?: string,
    getOnlyUnread?: boolean,
    getOnlyRead?: boolean
  ) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("fromRecord", fromRecord);
    httpParams = httpParams.append("numRecords", numRecords);
    httpParams = httpParams.append("searchText", searchText)
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    httpParams = httpParams.append(
      "getStatusNotification", "true"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-todolist-list/" +
      fromRecord +
      "/" +
      numRecords,
      {
        params: httpParams,
      }
    );
  }

  getNewRecruits(parameters) {
    /*if(parameters.fromRecord !== undefined)   httpParams = httpParams.append("fromRecord", parameters.fromRecord);*/
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-all-newrecruit/",
      {
        params: parameters,
      }
    );
  }

  setRecruitData(info, sessionStorageId) {
    window.console.log();
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/create-or-update-newrecruit/",
      {
        user: info,
        sessionStorageId,
      }
    );
  }

  // Take a temporary token for get the external resources
  //this token can only be used once
  getTemporaryToken(validationId: string, uploadId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("validationId", validationId);
    httpParams = httpParams.append("uploadId", uploadId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-masked-url/",
      {
        params: httpParams,
      }
    );
  };

  changeStatusRecruit(newrecruitId, status: "DISABLED" | "ENABLED", note) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/change-status-newrecruit",
      {
        status: status,
        newrecruitId: newrecruitId,
        note: note
      }
    );
  }

  sendMail(data) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", data.userId);
    httpParams = httpParams.append("documentType", data.documentType);
    httpParams = httpParams.append("step", "INVIO-LETTERE");
    if (data.expectedEndDate)
      httpParams = httpParams.append("expectedEndDate", data.expectedEndDate);

    window.console.log("sent");
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/request-document-validation/",
      {
        userId: data.userId,
        expectedEndDate: data.expectedEndDate,
        documentType: data.documentType,
        step: "INVIO-LETTERE"
      }
    );
  }

  sendRequestWithFile(file, userId, fileName, documentType, step, expectedEndDate) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("userId", userId);
    formData.append("documentType", documentType);
    formData.append("step", step);
    formData.append("expectedEndDate", expectedEndDate);

    const req = new HttpRequest(
      "POST",
      this.applicationContext +
      "rest-api/onboarding-mediator/request-and-upload-document-validation",
      formData
    );

    return this.http.request(req);
  }

  uploadDocumentValidation(uploadFile, userId, body, filename) {
    let formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("fileName", filename);
    formData.append("userId", userId);

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        formData.append(key, body[key]);
      }
    }

    let params = new HttpParams();
    const options = {
      params: params,
    };
    const req = new HttpRequest(
      "POST",
      this.applicationContext +
      "rest-api/onboarding-mediator/upload-document-validation",
      formData,
      options
    );
    return this.http.request(req);
  }

  sendCommunication(userId, expectedEndDate) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);
    httpParams = httpParams.append("documentType", "COMMUNICATION");
    httpParams = httpParams.append("expectedEndDate", expectedEndDate);

    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/request-document-validation/",
      {
        userId: userId,
        expectedEndDate: expectedEndDate,
        documentType: "COMMUNICATION",
        step: "INVIO-LETTERE"
      }
    );
  }

  uploadNote(text, validationId) {
    /*let httpParams = new HttpParams();
    httpParams = httpParams.append("text", text);
    httpParams = httpParams.append("validationId", validationId);*/

    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/create-document-validation-note/",
      {
        text: text,
        validationId: validationId,
      }
    );
  }

  getJourneyStructure() {
    let httpParams = new HttpParams();

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-journey-structure/",
      {
        params: httpParams,
      }
    );
  }

  getJourneyStructureById(userId, viewType = "HRBP") {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);
    httpParams = httpParams.append("viewType", viewType);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-journey-status-by-userid/",
      {
        params: httpParams,
      }
    );
  }

  getCities(province?){
    let httpParams = new HttpParams();
    if(province) httpParams = httpParams.append("provincia", province);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-comuni-list",
      {
        params: httpParams,
      }
    );
  }

  getDocumentsValidationOfUser(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-validation-by-user/",
      {
        params: httpParams,
      }
    );
  }

  getDocumentsOfUserByParameters(parameters) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-upload-by-user-and-parameter/",
      {
        params: parameters,
      }
    );
  }

  getDocumentValidationByType(documentType) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("documentType", documentType);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-validation-by-type/",
      {
        params: httpParams,
      }
    );
  }

  getDocumentsValidationById(validationId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("validationId", validationId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-validation-by-id/",
      {
        params: httpParams,
      }
    );
  }

  getRecruitById(userId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("newRecruitId", userId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-by-id/",
      {
        params: httpParams,
      }
    );
  }

  getStatusSmartHiring(userId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-status-smart-hiring/",
      {
        params: httpParams,
      }
    );
  }

  acceptDocument(validationId, documentType) {
    /*let httpParams = new HttpParams();
    httpParams = httpParams.append("validationId", validationId);
    httpParams = httpParams.append("documentType", documentType);*/

    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/accept-document-validation/",
      {
        validationId,
        documentType,
      }
    );
  }

  rejectDocument(validationId, documentType, text) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/reject-document-validation/",
      {
        validationId,
        documentType,
        statusNote: text,
      }
    );
  }

  getDirectlyResponsible(numRecords, fromRecord, searchText, socDistacco?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("numRecords", numRecords);
    httpParams = httpParams.append("fromRecord", fromRecord);
    httpParams = httpParams.append("searchText", searchText || '');
    if(socDistacco) {
      httpParams = httpParams.append("socDistacco", socDistacco);
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-menudata-manager/",
      {
        params: httpParams,
      }
    );
  }

  getRequiredEquipments(userType) {
    let httpParams = new HttpParams();
    // if (Array.isArray(userType)) {
    //   userType.forEach(type => {
    //     httpParams = httpParams.append("userType", type);
    //   });
    // } else {
    // }

    httpParams = httpParams.append("userType", userType);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-menudata-manager/",
      {
        params: httpParams,
      }
    );
  }

  getRecruiterList(numRecords, fromRecord, searchText) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("numRecords", numRecords);
    httpParams = httpParams.append("fromRecord", fromRecord);
    httpParams = httpParams.append("searchText", searchText || '');
    httpParams = httpParams.append("roleName", "ONBOARDING_RECRUITER")

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-user-list-by-role/",
      {
        params: httpParams,
      }
    );
  }
  getHrbpUsers(searchText?: string, numRecords?: number, fromRecord?: number) {
    let httpParams = new HttpParams();
    if(numRecords) {
      httpParams = httpParams.append("numRecords", numRecords.toString());
    }
    if(fromRecord) {
      httpParams = httpParams.append("fromRecord", fromRecord.toString());
    }

    httpParams = httpParams.append("searchText", searchText || '');

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext + "rest-api/onboarding-mediator/get-hrbp-users", { params: httpParams })
      .pipe(
        map(a => {
          return a.response.map(u => ({
            ...u,
            fullName: `${u.forename} ${u.surname}`
          }))
        })
      );
  }

  getClassifications(company?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('includeDisabled', 'true');
    if (company) {
      httpParams = httpParams.append('company', company);
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-inquadramento-for-remedy/",
      {
        params: httpParams
      }
    );
  }

  /*getDocumentsToSend(userId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("documentType", userId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-document-validation-to-send/",
      {
        params: httpParams,
      }
    );
  }*/

  getDocumentValidationByStep(
    userId: string,
    step: "INVIO-DOCUMENTI" | "RICHIEDI-DOCUMENTI" | "INVIO-LETTERE",
    full: boolean = true
  ) {
    const params: any = {
      userId,
      step,
    };

    if (full) {
      params.searchStatus = "true";
    }

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-validation-by-step/",
      {
        params,
      }
    );
  }

  sendDocumentToRecruit(
    userId: string,
    step: "INVIO-DOCUMENTI" | "RICHIEDI-DOCUMENTI",
    documentsType,
    expectedEndDate? //for MOP and MOG
  ) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/request-document-validation-to-send/",
      {
        userId,
        step,
        documentsType,
        expectedEndDate
      }
    );
  }

  getNumberNewRecruit_Dashboard() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-entry-by-months/"
    );
  }

  getAverageTimeApproval_Dashboard() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-average-time-approval/"
    );
  }

  getNewRecruitCountByPhase_Dashboard() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-count-by-phase/"
    );
  }

  getNewRecruitGroupByProperty_Dashboard(propertyName: string) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-group-by-property/",
      { params: { propertyName } }
    );
  }

  getExcel(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/create-excel-file-for-report/",
      {
        params: httpParams
      }
    );
  }

  getAvatar(userId: string){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/download-user-photo/",
      {
        params: httpParams
      }
    );
  }

  getSHStatus(userId: string){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-status-smart-hiring-actions/",
      {
        params: httpParams
      }
    );
  }

  chat_initializeChat(sessionStorageId, recipientId) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/initialize-chat/",
      {
        params: { sessionStorageId, recipientId },
      }
    );
  }

  chat_sendMessage(sessionStorageId, textMessage, recipientId, uploadId?) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/send-message/",
      {
        sessionStorageId,
        textMessage,
        recipientId,
        uploadId,
      }
    );
  }

  chat_getAllChat() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/newrecruit-active-chat/"
    );
  }

  chat_getLastMessage(
    recipientId: string,
    numRecords: string = "10",
    fromRecord: string = "0"
  ) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-last-chat-message/",
      {
        params: { recipientId, numRecords, fromRecord },
      }
    );
  }

  //Marca come letti i messaggi dello user indicato in input.
  chat_updateMarkMessage(recipientId) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/update-chat-mark/",
      {
        params: { recipientId },
      }
    );
  }

  getMarkDate(recipientId?) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-mark-date/",
      {
        params: { recipientId },
      }
    );
  }

  setActivationStatusDocument(userId, documentType, activeDocument) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/set-activation-status-document/",
      {
        params: { userId, documentType, activeDocument },
      }
    );
  }


  createRemedyTicket(userData) {
    let params = {};
    let keys = Object.keys(userData);
    for (let key of keys) {
      params[key] = userData[key];
    }
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-create-smart-hiring-ticket",
      params
    )
  }

  getTicketStatus(rmedyTicketId?) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-ticket-status/",
      {
        params: { rmedyTicketId },
      }
    );
  }

  getRemedyWorkplace(includeDisabled?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("includeDisabled", includeDisabled ? 'true' : 'false');
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-menudata-workplace/",
      {
        params: httpParams,
      }
    );
  }

  getRemedyEquipmentsProfile(company?: string, equipmentProfile?: string, includeDisabled?: boolean, userType?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("includeDisabled", includeDisabled ? 'true' : 'false');
    httpParams = httpParams.append("company", company);
    httpParams = httpParams.append("equipmentProfile", equipmentProfile);
    if (userType) {
      httpParams = httpParams.append("userType", userType);
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-menudata-equipments-profile/",
      {
        params: httpParams,
      }
    );
  }

  getUserList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-tipo-dipendente/"
    );
  }

  getRemedyManagers( socAssunzione?, includeDisabled?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("includeDisabled", includeDisabled ? 'true' : 'false');
    if(socAssunzione) httpParams = httpParams.append("socDistacco", socAssunzione);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-menudata-manager/",
      {
        params: httpParams,
      }
    );
  }

  getSmarHiringStatus(phaseId: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("phaseId", phaseId);
    httpParams = httpParams.append("userId", userId);
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-status-smart-hiring/",
      {
        params: httpParams,
      }
    );
  }

  unlockSmartHiring(phaseId: string) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/unlock-smart-hiring/",
      {
        phaseId: phaseId
      }
    );
  }

  getProvinceList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-province-list/", {}
    );
  }

  getMaritalStatus() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-stato-civile/", {}
    );
  }

  closeRemedyTicket(ticketId) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/register-remedy-ticket-closure/",
      {
        ticketId: ticketId
      }
    );
  }
}
