<div *ngIf="!fromRight" fxLayout="row" fxLayoutAlign="center center" style="padding: 5px 10px"
    [ngStyle]="containerStyle">
    <img class="img-sender" placement="bottom" tooltip-class="custom-tooltip" [src]="imgSrc"
        [ngStyle]="customImageStyle">
    <div class="freccia-sinistra"></div>
    <div class="welcomebox" fxLayout="row">
        <div class="message" [innerHTML]="text" style="display: inline;"
            [ngStyle]="{'padding-right': closable ? '20px' : '2px'}"></div>
        <img *ngIf="closable" width="15" src="assets/img/icons/close-icon.png" (click)="clickClose()">
    </div>
</div>
<div *ngIf="fromRight" fxLayout="row" fxLayoutAlign="end center" style="padding: 5px 10px" [ngStyle]="containerStyle">
    <div class="welcomebox" fxLayout="row" fxLayoutAlign="space-between start"
        [ngClass]="{'white-background': whiteBackground}">
        <img *ngIf="closable" width="15" src="assets/img/icons/close-icon.png" (click)="clickClose()">

        <div class="message" [innerHTML]="text" [ngStyle]="{'padding-left': closable ? '20px' : '2px'}"></div>
    </div>

    <div class="freccia-destra" [ngClass]="{'white-background': whiteBackground}"></div>
    <img class="img-sender" placement="bottom" tooltip-class="custom-tooltip" [src]="imgSrc"
        [ngStyle]="customImageStyle">

</div>