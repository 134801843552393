<div *ngIf="(isFetchingLangs$ | async) || showApplicationLoader" class="app-loading">
  <div class="logo"></div>
  <div class="lds-ellipsis ">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <p *ngIf="true">Caricamento in corso</p>
  </div>
</div>

<!-- Nome della pagina -->
<ng-container *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader">
  <app-header *ngIf="!selectedDocumentDetailsName && isAuthenticated" (activateAnimation)="activateAnimation()"
    class="sticky-header"></app-header>
  <!-- Header per la visualizzazione del dettaglio di un documento -->
  <app-document-details-header 
    *ngIf="selectedDocumentDetailsName && selectedDocumentDetailsName.length && isAuthenticated && !(isFetchingLangs$ | async) && !this.hideTopNavigation"
    class="sticky-header" [documentName]="selectedDocumentDetailsName"
    [mainActionText]="selectedDocumentDetailsMainActionText"
    (onMainActionClicked)="onDocumentDetailsHeaderMainActionClicked()"></app-document-details-header>
  <router-outlet></router-outlet>
  <app-footer
    *ngIf="isAuthenticated && (isThisCurrentPage('users') || isThisCurrentPage('hrbp') || isThisCurrentPage('recruiter') || isThisCurrentPage('guru') || isThisCurrentPage('hrbpPro') ) && showFooter">
  </app-footer>
</ng-container>


<app-doc-preview *ngIf="filePreview" [document]="filePreview" (onClose)="onPreviewClose()"></app-doc-preview>

<modal id="switch-browser-modal" class="informative-modal" [showBackBtn]="false"
  (onOutsideModalClicked)="closeSwitchBrowserModal()">
  <div class="switch-browser-modal">
    <div class="inner-modal-close">
      <svg-icon id="close-icon-modal" tabindex="0" src="assets/img/assets-IE-noworking-modal/icon-close.svg"
        tabindex="0" (click)="closeSwitchBrowserModal()">
      </svg-icon>
    </div>
    <div class="wrapper-gif">
      <img class="gif" src="assets/img/assets-IE-noworking-modal/loader.gif">
    </div>
    <h1>Cambia browser per una migliore esperienza</h1>
    <p>Internet Explorer non permette una completa fruizione della piattaforma.<br>
      Utilizza un browser diverso come:</p>
    <div class="icon-browser-wrapper">
      <div class="browser-icon-wrapper">
        <img src="assets/img/assets-IE-noworking-modal/chrome.png">
        <p>Chrome</p>
      </div>
      <div class="browser-icon-wrapper">
        <img src="assets/img/assets-IE-noworking-modal/edge.png">
        <p>Edge</p>
      </div>
    </div>
  </div>
</modal>