import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-login-saml-error',
  templateUrl: './login-saml-error.component.html',
  styleUrls: ['./login-saml-error.component.scss']
})
export class LoginSamlErrorComponent {
  error: any;

  constructor(private router: Router,
    private analyticsService: AnalyticsService) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Pagina di errore Saml");
    const specificError = this.router.parseUrl(this.router.routerState.snapshot.url).queryParamMap.get("error");
    if (specificError) {
      this.error = {
        specificError: specificError
      };
    }
  }
}
