export const UsersTabsHome = [
  {
    title: "To do list",
    id: "0",
    imgSrcGrey: "assets/img/icons/navbar-todolist-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-todolist-red.svg",
    notifications: "0",
  },
  {
    title: "Journey",
    id: "1",
    imgSrcGrey: "assets/img/icons/navbar-journey-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-journey-red.svg",
    disabled: false,
  },
  {
    title: "users.DOCUMENTS_TITLE",
    id: "2",
    imgSrcGrey: "assets/img/icons/navbar-document-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-document-red.svg",
    disabled: false,
  },
  {
    title: "Chat",
    id: "3",
    imgSrcGrey: "assets/img/icons/navbar-chat-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-chat-red.svg",
    disabled: false,
  }/*,
  {
    title: "header.LOGOUT",
    id: "logout",
    imgSrcGrey: "assets/img/icons/logout_icon.svg",
  }*/,
  {
    title: "header.PROFILE",
    id: "4",
    disabled: false,
    profileTab: true
  }
];

export const UsersPaths = [
  "/users/todolist",
  "/users/journey",
  "/users/documents-list",
  "/users/chat",
  "/users/profile",
];

export const HrbpTabsHome = [
  {
    title: "To do list",
    id: "0",
    imgSrcGrey: "assets/img/icons/navbar-todolist-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-todolist-red.svg",
  },
  {
    title: "NeoAssunti",
    id: "1",
    imgSrcGrey: "assets/img/icons/navbar-newhired-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-newhired-red.svg",
  },
  {
    title: "Dashboard",
    id: "2",
    imgSrcGrey: "assets/img/icons/navbar-dashboard-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-dashboard-red.svg",
  },
  {
    title: "Chat",
    id: "3",
    imgSrcGrey: "assets/img/icons/navbar-chat-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-chat-red.svg",
    notifications: "0",
    disabled: false,
  }/*,
  {
    title: "header.LOGOUT",
    id: "logout",
    imgSrcGrey: "assets/img/icons/logout_icon.svg",
  }*/,
  {
    title: "header.PROFILE",
    id: "4",
    disabled: false,
    profileTab: true
  },
];

export const HrbpPaths = [
  "/hrbp/todolist",
  "/hrbp/newlyhired",
  "/hrbp/dashboard",
  "/hrbp/chat",
  "/hrbp/profile",
];

export const HrbpProTabsHome = [
  {
    title: "To do list",
    id: "0",
    imgSrcGrey: "assets/img/icons/navbar-todolist-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-todolist-red.svg",
  },
  {
    title: "hrbp_pro.NEWLYHIRED",
    id: "1",
    imgSrcGrey: "assets/img/icons/navbar-newhired-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-newhired-red.svg",
  },
  {
    title: "Dashboard",
    id: "2",
    imgSrcGrey: "assets/img/icons/navbar-dashboard-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-dashboard-red.svg",
  },
  // {
  //   title: "Chat",
  //   id: "3",
  //   imgSrcGrey: "assets/img/icons/navbar-chat-grey.svg",
  //   imgSrcRed: "assets/img/icons/navbar-chat-red.svg",
  //   notifications: "0",
  //   disabled: false,
  // },
  // {
  //   title: "header.LOGOUT",
  //   id: "logout",
  //   imgSrcGrey: "assets/img/icons/logout_icon.svg",
  // },
  {
    title: "header.PROFILE",
    id: "3",
    disabled: false,
    profileTab: true
  },
];

export const HrbpProPaths = [
  "/hrbpPro/todolist",
  "/hrbpPro/newlyhired",
  "/hrbpPro/dashboard",
  "/hrbpPro/profile",
];


export const ZonemanagerTabsHome = [
  {
    title: "To do list",
    id: "0",
    imgSrcGrey: "assets/img/icons/navbar-todolist-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-todolist-red.svg",
  },
  {
    title: "hrbp_pro.NEWLYHIRED",
    id: "1",
    imgSrcGrey: "assets/img/icons/navbar-newhired-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-newhired-red.svg",
  },
  {
    title: "Dashboard",
    id: "2",
    imgSrcGrey: "assets/img/icons/navbar-dashboard-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-dashboard-red.svg",
  },
  {
    title: "Chat",
    id: "3",
    imgSrcGrey: "assets/img/icons/navbar-chat-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-chat-red.svg",
    notifications: "0",
    disabled: false,
  },
  {
    title: "header.PROFILE",
    id: "4",
    disabled: false,
    profileTab: true
  },
];


export const ZonemanagerPaths = [
  "/zonemanager/todolist",
  "/zonemanager/newlyhired",
  "/zonemanager/dashboard",
  "/zonemanager/chat",
  "/zonemanager/profile",
];

export const GuruTabsHome = [
  {
    title: "To do list",
    id: "0",
    imgSrcGrey: "assets/img/icons/navbar-todolist-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-todolist-red.svg",
  },
  {
    title: "Experience",
    id: "1",
    imgSrcGrey: "assets/img/icons/navbar-newhired-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-newhired-red.svg",
  }/*,
  {
    title: "header.LOGOUT",
    id: "logout",
    imgSrcGrey: "assets/img/icons/logout_icon.svg",
  }*/,
  {
    title: "header.PROFILE",
    id: "2",
    disabled: false,
    profileTab: true
  },
];

export const GuruPaths = [
  "/guru/todolist",
  "/guru/experience",
  "/guru/profile"
];


export const RecruiterTabsHome = [
  {
    title: "To do list",
    id: "0",
    imgSrcGrey: "assets/img/icons/navbar-todolist-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-todolist-red.svg",
  },
  {
    title: "hrbp.NEW_USER",
    id: "1",
    imgSrcGrey: "assets/img/icons/navbar-newhired-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-newhired-red.svg",
  },
  {
    title: "Dashboard",
    id: "2",
    imgSrcGrey: "assets/img/icons/navbar-dashboard-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-dashboard-red.svg",
  }/*,
  {
    title: "header.LOGOUT",
    id: "logout",
    imgSrcGrey: "assets/img/icons/logout_icon.svg",
  }*/,
  {
    title: "header.PROFILE",
    id: "4",
    disabled: false,
    profileTab: true
  }
];

export const RecruiterPaths = [
  "/recruiter/todolist",
  "/recruiter/newrecruit",
  "/recruiter/dashboard",
  "",
  "/recruiter/profile"
];

export const ManagerTabsHome = [
  {
    title: "To do list",
    id: "0",
    imgSrcGrey: "assets/img/icons/navbar-todolist-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-todolist-red.svg",
  },
  {
    title: "hrbp.NEW_USER",
    id: "1",
    imgSrcGrey: "assets/img/icons/navbar-newhired-grey.svg",
    imgSrcRed: "assets/img/icons/navbar-newhired-red.svg",
  }/*,
  {
    title: "header.LOGOUT",
    id: "logout",
    imgSrcGrey: "assets/img/icons/logout_icon.svg",
  }*/,
  {
    title: "header.PROFILE",
    id: "2",
    disabled: false,
    profileTab: true
  }
];

export const ManagerPaths = [
  "/manager/todolist",
  "/manager/newrecruit",
  "/manager/profile"
];
