<!--Footer mobile-->
<div fxHide.gt-sm class="footer" fxLayout="column">
    <div class="main-block" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="37px">
        <div fxLayout="row" fxLayoutAlign="cemter center">
            <img placement="bottom" hide-delay="50" tooltip-class="custom-tooltip" tooltip="Generali" title="Generali"
                alt="Generali" src="assets/img/horizontal-logo-generali-white.svg">
        </div>
        <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
            <div class="full-width cursor-pointer" fxLayout="row" fxLayoutAlign="space-between center" (click)="redirectService.goToFaq()" style="border-bottom: 1px solid #D8D8D8; padding: 20px 16px;">
                <p style="opacity: 1;">
                    {{ "footer.SUPPORT_AND_QUESTION" | translate }}
                </p>
                <svg-icon class="margin-bottom14" src="assets/img/icons/chevron-right-white.svg"></svg-icon>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <p tabindex="0">Follow us</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="29px">
                <div class="social-button">
                    <svg-icon src="assets/img/icons/twitter-icon.svg"></svg-icon>
                </div>
                <div class="social-button">
                    <svg-icon src="assets/img/icons/youtube-icon.svg"></svg-icon>
                </div>
                <div class="social-button">
                    <svg-icon src="assets/img/icons/facebook-icon.svg"></svg-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="privacy" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">
        <p class="cursor-pointer" (click)="redirectService.goToPrivacy()">Privacy Policy</p>
        <p class="company-info">© Generali Italia S.p.A.  - C.F. 00409920584 - P.IVA 00885351007 -  generaliitalia&#64;pec.generaligroup.com</p>
    </div>
</div>

<!--Footer desktop-->
<div fxHide.xs fxHide.sm class="footer footer-desktop">
    <div class="content">
        <div class="main-block" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxLayout="row" fxLayoutAlign="start center">
                <img class="generali-logo-footer" placement="bottom" hide-delay="50" tooltip-class="custom-tooltip" tooltip="Generali"
                    title="Generali" alt="Generali" src="assets/img/vertical-logo-generali-white.svg">
            </div>

            <div class="full-width" fxLayout="column" fxLayoutAlign="center start">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <p class="cursor-pointer" style="opacity: 1;" (click)="redirectService.goToFaq()">
                        {{ "footer.SUPPORT_AND_QUESTION" | translate }}&nbsp;-&nbsp;
                    </p>
                    <p class="cursor-pointer" style="opacity: 1;" (click)="redirectService.goToPrivacy()">Privacy Policy</p>
                </div>
                <p class="company-info">© Generali Italia S.p.A.  - C.F. 00409920584 - P.IVA 00885351007 -  generaliitalia&#64;pec.generaligroup.com</p>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                <a href="http://generali.it"> <img src="assets/img/icons/www-icon.svg"></a>
                <div class="social-button">
                    <svg-icon src="assets/img/icons/twitter-icon.svg"></svg-icon>
                </div>
                <div class="social-button">
                    <svg-icon src="assets/img/icons/facebook-icon.svg"></svg-icon>
                </div>
                <div class="social-button">
                    <svg-icon src="assets/img/icons/youtube-icon.svg"></svg-icon>
                </div>
                <div class="social-button">
                    <svg-icon src="assets/img/icons/instagram-icon.svg"></svg-icon>
                </div>
                <div class="social-button">
                    <svg-icon src="assets/img/icons/linkedin-icon.svg"></svg-icon>
                </div>
            </div>
        </div>
    </div>
</div>