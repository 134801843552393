import * as CommonClasses from "../../../cm2-commonclasses";
import * as fromApp from "../../ngrx/app.reducers";

import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import { Observable, Subscription, combineLatest, of } from "rxjs";

import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { Injectable } from "@angular/core";
import { SenecaResponse } from "src/cm2-commonclasses";
import { Store } from "@ngrx/store";
import { UrlService } from "src/app/shared/services/url.service";

@Injectable()

export class RecruiterService {
  applicationData: GlobalApplicationData;
  result$: Subscription;
  applicationContext: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private urlService: UrlService
  ) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(
      fromApp.getGlobalApplicationData
    );
    const combinedSelectes$ = combineLatest(globalApplicationData$);
    this.result$ = combinedSelectes$.subscribe(([globalApplicationData]) => {
      this.applicationData = globalApplicationData;
      this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
    });
  }


  countUserToDoList(getOnlyUnread?: boolean, getOnlyRead?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    httpParams = httpParams.append(
      "getStatusNotification", "true"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-todolist-counters",
      {
        params: httpParams,
      }
    );
  }

  getUserToDoList(
    fromRecord,
    numRecords,
    searchText?: string,
    getOnlyUnread?: boolean,
    getOnlyRead?: boolean
  ) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("fromRecord", fromRecord);
    httpParams = httpParams.append("numRecords", numRecords);
    httpParams = httpParams.append("searchText", searchText)
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    httpParams = httpParams.append(
      "getStatusNotification", "true"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-todolist-list/" +
      fromRecord +
      "/" +
      numRecords,
      {
        params: httpParams,
      }
    );
  }

  getRecruitById(userId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("newRecruitId", userId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-by-id/",
      {
        params: httpParams,
      }
    );
  }

  getRecruits(parameters) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-all-newrecruit-by-role/",
      {
        params: parameters,
      }
    );
  }

  getProvinceList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-province-list/", {}
    );
  }

  getJourneyStructureById(userId, viewType = "RECRUITER") {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);
    httpParams = httpParams.append("viewType", viewType);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-journey-status-by-userid/",
      {
        params: httpParams,
      }
    );
  }

  getActiveGuru(userId){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-active-guru/",
      {
        params: httpParams,
      }
    );
  }

  confirmGuru(userId, guruUserId, noGuru = false){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/create-guru-step-for-user/",
      {
        userId,
        guruUserId,
        noGuru
      }
    );
  }

  changeStatusConfirmGuru(stepId, statusType: "ACTIVE" | "NOT_ACTIVE"){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/change-status-guru-for-user/",
      {
        stepId,
        statusType
      }
    );
  }

  getCounterGuruList(parameters?){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("socAppartenenza", parameters.socAppartenenza ? parameters.socAppartenenza : []);
    httpParams = httpParams.append("searchText", parameters.searchText ? parameters.searchText : "");
    httpParams = httpParams.append("ruolo", parameters.ruolo ? parameters.ruolo : []);
    httpParams = httpParams.append("userId", parameters.userId);
    httpParams = httpParams.append("searchText", parameters.searchText)
    httpParams = httpParams.append("roleName", "ONBOARDING_GURU");

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-count-user-list-by-role/",
      {
        params: httpParams
      }
    );
  }

  getGuruList(parameters?){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("socAppartenenza", parameters.socAppartenenza ? parameters.socAppartenenza : []);
    httpParams = httpParams.append("searchText", parameters.searchText ? parameters.searchText : "");
    httpParams = httpParams.append("ruolo", parameters.ruolo ? parameters.ruolo : []);
    httpParams = httpParams.append("userId", parameters.userId);
    httpParams = httpParams.append("fromRecord", parameters.fromRecord);
    httpParams = httpParams.append("numRecords", parameters.numRecords);
    httpParams = httpParams.append("searchText", parameters.searchText)
    httpParams = httpParams.append("roleName", "ONBOARDING_GURU");

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-user-list-by-role/",
      {
        params: httpParams
      }
    );
  }

  getStatusConfirmGuru(userId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-status-step-guru/",
      {
        params: httpParams,
      }
    );
  }

  getNumberNewRecruit_Dashboard() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-entry-by-months/"
    );
  }

  getChiefValues(socDistacco?) {
    let httpParams = new HttpParams();
    if(socDistacco) httpParams = httpParams.append("socDistacco", socDistacco);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-distinct-users-chief-values-for-recruiter",
      {
        params: httpParams,
      }
    );
  }

  getAverageTimeApproval_Dashboard() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-average-time-approval/"
    );
  }

  getNewRecruitCountByPhase_Dashboard() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-count-by-phase/"
    );
  }

  getNewRecruitGroupByProperty_Dashboard(propertyName: string) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-group-by-property/",
      { params: { propertyName } }
    );
  }

  getDistinctUsersFieldValues(field: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("field", field);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-distinct-users-field-values",
      {
        params: httpParams,
      }
    );
  }

  getNationList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-nations-list"
    );
  }

  getDocumentsOfUserByParameters(parameters) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-upload-by-user-and-parameter/",
      {
        params: parameters,
      }
    );
  }

  getCities(province?){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("provincia", province);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-comuni-list",
      {
        params: httpParams,
      }
    );
  }

  getUserList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-tipo-dipendente/"
    );
  }

  getMaritalStatus() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-stato-civile/", {}
    );
  }

  // Take a temporary token for get the external resources
  //this token can only be used once
  getTemporaryToken(validationId: string, uploadId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("validationId", validationId);
    httpParams = httpParams.append("uploadId", uploadId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-masked-url/",
      {
        params: httpParams,
      }
    );
  };

  setRecruitData(info, sessionStorageId) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/create-or-update-newrecruit/",
      {
        user: info,
        sessionStorageId,
      }
    );
  }


  //******* chat *******/
  chat_initializeChat(sessionStorageId, recipientId) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/initialize-chat/",
      {
        params: { sessionStorageId, recipientId },
      }
    );
  }

  chat_sendMessage(sessionStorageId, textMessage, recipientId, uploadId?) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/send-message/",
      {
        sessionStorageId,
        textMessage,
        recipientId,
        uploadId,
      }
    );
  }

  chat_getAllChat() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/newrecruit-active-chat/"
    );
  }

  chat_getLastMessage(
    recipientId: string,
    numRecords: string = "10",
    fromRecord: string = "0"
  ) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-last-chat-message/",
      {
        params: { recipientId, numRecords, fromRecord },
      }
    );
  }

  //Marca come letti i messaggi dello user indicato in input.
  chat_updateMarkMessage(recipientId) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/update-chat-mark/",
      {
        params: { recipientId },
      }
    );
  }

}
