<div class="tab-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="center end">
    <div class="tabs" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="end center"
        fxLayoutGap.gt-sm="16px">

        <ng-container *ngFor="let tab of tabs">
            <button *ngIf="!tab.profileTab" id="navbarButton" [disabled]="tab.disabled"
                [ngClass]="{ 'active': activeTab == tab.id }" class="tab-element navbarButton" fxLayout.xs="column"
                fxLayout.sm="column" fxLayout.gt-sm="row" fxLayoutAlign="center center"
                fxLayoutAlign.gt-sm="start center" (click)="selectTab(tab.id)" fxFlex.xs fxFlex.sm>
                <img id="svgNavbar" [src]="activeTab == tab.id ? tab.imgSrcRed : tab.imgSrcGrey">
                <span *ngIf="!tab.disabled && tab.notifications && tab.notifications != '0' && tab.notifications != 0"
                    class="badge-notify-coursemanager margin0">{{tab.notifications}}</span>
                <p [ngClass]="{ 'red': activeTab == tab.id }" class="title">{{ tab.title | translate}}</p>
            </button>

            <!-- Profilo utente loggato (non mobile) -->
            <button *ngIf="tab.profileTab && avatarSrc && avatarSrc.length" fxHide.xs fxHide.sm id="profileButton"
                [ngClass]="{ 'active': activeTab == tab.id }" class="tab-element navbarButton" fxLayout.xs="column"
                fxLayout.sm="column" fxLayout.gt-sm="row" fxLayoutAlign="center center"
                fxLayoutAlign.gt-sm="start center" (click)="selectTab(tab.id)" fxFlex.xs fxFlex.sm>
                <img class="avatarImage" [src]="avatarSrc" alt="avatarImage" title="avatarImage">
                <p [ngClass]="{ 'red': activeTab ===  tab.id }" class="title">{{ tab.title | translate}}</p>
            </button>
        </ng-container>
    </div>
</div>
