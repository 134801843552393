import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map, filter, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
// Import dello State dell'applicativo
import * as fromApp from '../../ngrx/app.reducers';
// Import delle azioni del core
import { RequiredAuth } from 'src/cm2-commonclasses';
import { AuthService } from '../../auth/services/auth.service';
import { onceIfNotEmpty } from 'src/app/ngrx/util';

@Injectable()
export class ManagerGuard  {

    constructor(private store: Store<fromApp.AppState>,
        private authService: AuthService,
        private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(fromApp.isAuthenticated)
        .pipe(
            filter((isAuthenticated) => isAuthenticated),
            switchMap(() => {
                return this.store.select(fromApp.getLoggedUser);
            }),
            onceIfNotEmpty(),
            map(loggedUser => {
                if (loggedUser && loggedUser.auths && loggedUser.auths.length) {
                    for (let i = 0, authsLength = loggedUser.auths.length; i < authsLength; i++) {
                        let currentAuth = loggedUser.auths[i];
                        if (currentAuth === RequiredAuth.ONBOARDING_MANAGER) {
                            return true;
                        }
                    }
                } else {
                    return this.router.parseUrl('/403');
                }

                
            })
        )
    }
}
