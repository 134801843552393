import * as AuthActions from "../ngrx/auth.actions";
import * as fromApp from "../../ngrx/app.reducers";

import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { AuthService } from "../services/auth.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { PasswordValidator } from "./password.validator";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsService } from "src/app/shared/services/analytics.service";

@Component({
  selector: "app-sign-up",
  templateUrl: "sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  public passwordType: string = "password";
  public confirmPasswordType: string = "password";
  public form: FormGroup;
  public message: string = "signUp.sign_up_message";
  public showSubmit: boolean = false;
  public currentStep: number = 0;

  constructor(
    fb: FormBuilder,
    private store: Store<fromApp.AppState>,
    public _router: Router,
    private _route: ActivatedRoute,
    private _auth: AuthService,
    private _device: DeviceDetectorService,
    private _toastr: ToastrService,
    private _translate: TranslateService,
    private analyticsService: AnalyticsService
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    this.analyticsService.sendVirtualPageViewEvent(this._router.url, "Conferma registrazione");

    this.form = fb.group({
      email: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          PasswordValidator.strengthTest(),
        ]),
      ],
      password_confirmation: [
        null,
        Validators.compose([
          Validators.required,
          PasswordValidator.matchValues("password"),
        ]),
      ],
      privacy: [null],
      userId: [null, [Validators.required]],
      validationToken: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this._getParameters();
  }

  async onSubmit() {
    if (
      this.form.valid &&
      (this.form.value.privacy === false || this.form.value.privacy === true)
    ) {
      try {
        const deviceInfo = this._device.getDeviceInfo();
        const userAgent = deviceInfo && deviceInfo.userAgent;
        let deviceType: string;
        if (this._device.isMobile()) {
          // Salvo il fatto che è uno smartphone
          deviceType = "P";
        } else if (this._device.isTablet()) {
          // Salvo il fatto che è un tablet
          deviceType = "T";
        } else if (this._device.isDesktop()) {
          // Salvo il fatto che è un computer desktop
          deviceType = "D";
        }
        const body = {
          deviceType,
          userAgent,
          validationToken: this.form.value.validationToken,
          userId: this.form.value.userId,
          isPrivacyAccepted: this.form.value.privacy,
          password: this.form.value.password,
          email: this.form.value.email,
        };
        const result = await this._auth.confirmNewRecruiter(body);
        if (result.error) {
          return this._toastr.error(
            this._translate.instant(`errors.${result.error}`)
          );
        }
        //this._toastr.success(this._translate.instant(`signUp.ok`));

        this.store.dispatch(
          new AuthActions.DoLogin({
            email: this.form.value.email,
            password: this.form.value.password,
          })
        );
      } catch (error) {
        this._toastr.error(this._translate.instant(`errors.${error}`));
      }
    }
  }

  isSubmitEnabled() {
    if (
      this.form &&
      this.form.valid &&
      this.form.value &&
      this.form.value.privacy === true
    ) {
      return true;
    }
    return false;
  }

  isContinueBtnEnabled() {
    if (
      (this.currentStep === 0 &&
        this.form &&
        this.form.controls &&
        this.form.controls.email &&
        this.form.controls.email.valid) ||
      (this.currentStep === 1 &&
        this.form &&
        this.form.controls &&
        this.form.controls.password &&
        this.form.controls.password.valid &&
        this.form.controls.password_confirmation &&
        this.form.controls.password_confirmation.valid)
    ) {
      return true;
    }

    return false;
  }

  async continue() {
    switch (this.currentStep) {
      case 0:
        if (this.form.controls.email.valid) {
          const body = {
            validationToken: this.form.value.validationToken,
            userId: this.form.value.userId,
            email: this.form.value.email.toLowerCase(),
          };

          try {
            const result = await this._auth.canConfirmNewRecruiter(body);

            if (result.error) {
              this._toastr.error(
                this._translate.instant(`generic.modals.${result.error}`)
              );
              return;
            }
            this.currentStep++;
            this.message = "signUp.sign_up_message_password";
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case 1:
        if (
          this.form.controls.password.valid &&
          this.form.controls.password_confirmation.valid
        ) {
          this.currentStep++;
          this.showSubmit = true;
          this.message = `<p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Privacy Policy di Onboarding Up</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Questo Sito raccoglie alcuni Dati Personali dei propri Utenti.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;"><br></span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">&emsp;</span></p>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Tipologie di Dati raccolti</span></h3>
          <p><br></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">I Dati Personali saranno trattati, in qualit&agrave; di titolare del trattamento, dalla societ&agrave; del Gruppo Generali con cui Lei abbia iniziato un percorso di selezione, come indicato nell&rsquo;elenco sotto riportato (la &ldquo;Societ&agrave;&rdquo;), al fine di completare il processo necessario per la conclusione del contratto di lavoro.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">La Societ&agrave;, con questo Sito gestisce e raccoglie, in modo autonomo o tramite altri siti, dati personali comuni, quali: nome, cognome, numero di telefono cellulare, localit&agrave; di residenza, indirizzo email, coordinate bancarie, codice fiscale, genere, nazionalit&agrave;.</span></p>
          <p style="margin: 0cm 0cm 6pt; line-height: 14pt; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">I Dati raccolti al fine di consentirti di poter fruire dei servizi offerti e necessari per garantire il funzionamento del Sito potranno essere trattati da soggetti terzi che svolgono alcuni servizi per conto della Societ&agrave; e che sono stati nominati responsabili del trattamento, tra cui la Societ&agrave; Generali Business Solutions per la gestione informatica dei dati e per gli adempimenti legati a necessit&agrave; amministrative e contabili.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">&nbsp;</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Il Sito richiede agli utenti di imputare i dati di autenticazione al momento dell&rsquo;utilizzo e l&rsquo;inserimento di alcune informazioni personali, per il completamento del profilo con i dati necessari all&rsquo;inserimento lavorativo&nbsp;</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">I restanti dati di cui sopra, che non sono imputati direttamente dagli utenti vengono invece sempre recuperati dai dati condivisi con la Societ&agrave; in sede di selezione.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Il Sito raccoglie, anche automaticamente, durante l&apos;uso informazioni di utilizzo, che non contengono tuttavia dati personali.</span></p>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Modalit&agrave; del trattamento dei Dati raccolti</span></h3>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">&nbsp;I dati degli utenti non saranno diffusi e saranno trattati con idonee modalit&agrave; e procedure anche informatizzate, da nostri dipendenti, collaboratori ed altri soggetti anche esterni, designati Responsabili e/o autorizzati al trattamento o, comunque, operanti quali Titolari, che sono coinvolti nella gestione dei rapporti in essere con l&rsquo;utente o che svolgono per nostro conto compiti di natura tecnica, organizzativa, operativa anche all&rsquo;interno ed al di fuori della UE.</span></p>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Informative&nbsp;</span></h3>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Questa Informativa, integra ed aggiorna quanto gi&agrave; contenuto nell&rsquo;informativa gi&agrave; a disposizione del candidato, sulla base del presupposto legittimante dell&rsquo;esecuzione del contratto di lavoro.&nbsp;</span></p>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Tempi&nbsp;</span></h3>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px; line-height: 115%; color: black;">I</span> suoi dati personali sono conservati in conformit&agrave; alla normativa privacy tempo per tempo applicabile.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Per le finalit&agrave; di gestione delle attivit&agrave; di selezione, i suoi dati personali saranno conservati per un periodo di tempo non superiore a 24 mesi decorrenti dall&rsquo;ultimo contatto con la Societ&agrave; dall&rsquo;ultimo aggiornamento da lei effettuato; l&rsquo;aggiornamento comporta il rinnovo del termine di conservazione. In caso di contestazioni o controversie legali, i dati saranno conservati per il termine prescrizionale previsto dalla normativa per la tutela dei diritti connessi. Sono fatti salvi in ogni caso periodi di conservazione maggiori - e relativi termini di prescrizione - correlati a specifiche normative di legge.</span></p>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Finalit&agrave; del Trattamento dei Dati raccolti</span></h3>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">I dati di cui sopra sono trattati dal Titolare per le seguenti finalit&agrave;:</span></p>
          <div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'>
              <ul style="margin-bottom:0cm;list-style-type: disc;">
                  <li style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">gestione documentale del Sito di assunzione&nbsp;</span></li>
              </ul>
          </div>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Registrazione ed autenticazione</span></h3>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Tramite l&rsquo;autenticazione l&rsquo;Utente consente al Sito di identificarlo e di dargli accesso a servizi dedicati.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">L&rsquo;autenticazione avviene tramite la verifica dell&rsquo;indirizzo mail e la creazione di una password da parte dell&rsquo;utente&nbsp;</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;"><br></span></p>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Log di sistema e manutenzione</span></h3>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Per necessit&agrave; legate al funzionamento ed alla manutenzione, questo Sito e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere Log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l&rsquo;indirizzo IP Utente&nbsp;</span></p>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Esercizio dei diritti da parte degli Utenti</span></h3>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">L&rsquo;Utente potr&agrave; conoscere quali sono i suoi dati trattati dal Titolare e, ove ne ricorrano le condizioni, esercitare i diversi diritti relativi al loro utilizzo (diritto di accesso, rettifica, aggiornamento, integrazione, cancellazione, limitazione al trattamento, alla portabilit&agrave;, alla revoca del consenso al trattamento e di ottenere una copia dei propri dati laddove questi siano conservati in paesi al di fuori dell&rsquo;Unione Europea, nonch&eacute; di ottenere indicazione del luogo nel quale tali dati vengono conservati o trasferiti) nonch&eacute; opporsi per motivi legittimi ad un loro particolare trattamento e comunque al loro uso a fini commerciali, in tutto o in parte anche per quanto riguarda l&rsquo;uso di modalit&agrave; automatizzate rivolgendosi a: Responsabile della protezione dei dati (&ldquo;RPD&rdquo;) della tua Societ&agrave;, &nbsp; designato dal Titolare &nbsp;e contattabile via e-mail all&rsquo;indirizzo che si trova in calce alla presente informativa, nonch&eacute; per ogni informazione a privacy.it@generali.com. &nbsp;</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Qualora ravvisi delle irregolarit&agrave; nel trattamento dei dati, potr&agrave; sporgere reclamo al garante per la protezione dei dati personali, con le modalit&agrave; indicate sul sito del Garante stesso (<a href="http://www.garanteprivacy.it" target="_blank">www.garanteprivacy.it</a>).</span></p>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Sicurezza del Sito.</span></h3>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Generali presta la massima attenzione alla riservatezza, alla tutela e alla sicurezza delle informazioni in proprio possesso, ancor pi&ugrave; se relative a dati personali dei soggetti con cui entra in contatto o che raccoglie presso gli utenti.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">&nbsp;<br>&nbsp;Nella presente Privacy Policy sono analizzate le modalit&agrave; di gestione del Sito in riferimento al trattamento dei dati personali degli Interessati che vi accedono. Si tratta di una informativa sul trattamento dei dati personali ai sensi degli articoli 13 e 14 del Regolamento UE n. 679/2016 del 27 aprile 2016, resa a coloro che interagiscono con i servizi erogati, tramite internet, da Generali.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">&nbsp;</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Gli Interessati che utilizzano il Sito sono pertanto invitati a visitare le sezioni dell&apos;area Privacy che raccolgono le linee guida adottate dalla Compagnia in materia di tutela dei dati personali. &nbsp;</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;"><br>L&apos;informativa &egrave; resa ed &egrave; valida per il presente sito di Generali Italia e non anche per altri siti web eventualmente consultabili tramite link esterni.&nbsp;</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Gli Interessati che volessero entrare in contatto con noi in via del tutto facoltativa, dopo aver preso visione della presente Privacy Policy, potranno fornirci i soli dati strettamente necessari all&apos;evasione delle richieste effettuate.&nbsp;Per il trattamento di tali dati non sar&agrave; necessario il consenso degli Interessati in quanto Generali risponder&agrave; a richieste derivanti direttamente da questi ultimi.<br>L&rsquo;accesso al nostro Sito per la sola attivit&agrave; di consultazione non prevede alcuna richiesta di dati personali; utilizziamo tuttavia tecnologie che comportano la memorizzazione di alcuni dati relativi agli strumenti utilizzati, in qualche modo riconducibili a chi vi accede.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Si riportano qui di seguito alcune notizie circa le modalit&agrave; di raccolta attiva e passiva delle informazioni relative ai soggetti/strumenti che interagiscono con questo sito, nonch&eacute; le misure adottate in materia di sicurezza.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Nel corso dell&rsquo;accesso all&apos;interno di un sito internet &egrave; tecnicamente possibile, anche in assenza di una esplicita registrazione da parte degli Utenti e senza un suo ruolo attivo, effettuare una raccolta di informazioni che, per tali motivi, viene definita passiva. In particolare, sono di seguito descritte le modalit&agrave; di utilizzo di Indirizzi IP, Cookies ed altri identificatori di sessione, Internet tags, dati di navigazione, compresa l&apos;eventuale possibilit&agrave; di escluderli e le relative implicazioni.</span></p>
          <p style="margin: 0cm; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 13px; line-height: 115%;">&nbsp;</span></span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Per quanto riguarda la&nbsp;raccolta passiva di informazioni&nbsp;il sito:</span></p>
          <ul style="list-style-type: disc;margin-left:20.75px;">
              <li style="text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">non tratta indirizzi di Protocollo Internet (cd. indirizzi IP) per la raccolta di informazioni, ma li memorizza nei dati di navigazione;</span></li>
              <li style="text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">utilizza&nbsp;Dati di Navigazione&nbsp;solo per fini statistici come dati aggregati;</span></li>
              <li style="text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">utilizza Cookies tecnici di sessione propri e di terze parti.&nbsp;</span></li>
              <li style="text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">I cookie tecnici vengono utilizzati al fine di rendere possibile la navigazione o nella misura strettamente necessaria per fornire un servizio richiesto dall&rsquo;utente, nel rispetto della Direttiva 2002/58/CE, come implementata nella normativa privacy vigente. Per queste finalit&agrave; non viene richiesto il consenso degli Utenti.&nbsp;</span></li>
          </ul>
          <p><br></p>
          <p style="margin: 0cm; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Per quanto riguarda la <strong>raccolta attiva di informazioni</strong> - se prevista - vale quanto segue:&nbsp;</span></p>
          <div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'>
              <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:20.75px;">
                  <li style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">E-Mail: i dati pervenuti tramite il contatto e-mail del sito Web vengono utilizzati esclusivamente per rispondere alle richieste dell&rsquo;Utente; tali dati vengono conservati ai soli fini statistici e di verifica dell&apos;esistenza di eventuali precedenti accessi. <br>&nbsp;Singoli nominativi possono essere inseriti in Mailing List specifiche solo su espresso consenso dell&rsquo;Utente che desideri ricevere regolarmente determinati documenti (es. news, quotazioni dei fondi, informazioni commerciali, ecc.).</span></li>
              </ul>
          </div>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Modifiche a questa privacy policy</span></h3>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento dandone pubblicit&agrave; agli Utenti.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Qualora vi sia una modifica, al primo accesso al Sito successivo alla modifica stessa, verr&agrave; chiesto all&rsquo;utente di accettare la privacy policy modificata.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Nel caso di mancata accettazione delle modifiche apportate alla presente privacy policy, l&rsquo;Utente &egrave; tenuto a cessare l&rsquo;utilizzo di questo Sito e pu&ograve; richiedere al Titolare del Trattamento di rimuovere i propri Dati Personali. Salvo quanto diversamente specificato, la precedente Privacy Policy continuer&agrave; ad applicarsi ai Dati Personali sino a quel momento raccolti.</span></p>
          <p style="margin: 0cm 0cm 10pt; line-height: 115%; font-size: 15px; font-family: Arial, sans-serif; text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">&nbsp;</span></p>
          <h3 style="margin: 24pt 0cm 0cm; font-size: 19px; font-family: Arial, serif; color: rgb(54, 95, 145); text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;">Elenco Societ&agrave;:</span></h3>
          <ul style="list-style-type: undefined;">
              <li style="text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="line-height: 150%; font-size: 15px;">per Generali Business Solutions S.C.p.A via e-mail all&rsquo;indirizzo&nbsp;</span><a href="mailto:RPD.it@generali.com"><span style="color:windowtext;text-decoration:none;font-size:11.0pt;">RPD.it@generali.com</span></a></span></li>
              <li style="text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="line-height: 150%; font-size: 15px;">per Generali Italia S.p.A., via e-mail all&rsquo;indirizzo&nbsp;</span><a href="mailto:RPD.it@generali.com"><span style="color:windowtext;text-decoration:none;font-size:11.0pt;">RPD.it@generali.com</span></a><span style="line-height: 150%; font-size: 15px;">&nbsp;</span></span></li>
              <li style="text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="line-height: 150%; font-size: 15px;">per &nbsp;Generali Jeniot S.p.A. via e-mail all&rsquo;indirizzo&nbsp;</span><a href="mailto:RPD.it@generali.com"><span style="line-height: 150%; font-size: 11pt; color: windowtext; text-decoration: none;">RPD.it@generali.com</span></a><span style="line-height: 150%; font-size: 15px;">&nbsp;</span></span></li>
              <li style="text-align: left;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="line-height: 150%; font-size: 15px;">per &nbsp;Generali Welion S.c.a.r.l. via e-mail all&rsquo;indirizzo&nbsp;</span><a href="mailto:RPD.it@generali.com"><span style="line-height: 150%; font-size: 11pt; color: windowtext; text-decoration: none;">RPD.it@generali.com</span></a><span style="line-height: 150%; font-size: 15px;">&nbsp;</span></span></li>
          </ul>
          <p style='margin: 0cm 0cm 0cm 36pt; font-size: 16px; font-family: "Times New Roman", serif; text-align: left; line-height: 150%;'><span style="font-size: 13px; line-height: 150%; font-family: Arial, Helvetica, sans-serif;">&nbsp;</span></p>`;
        }
        break;
      case 2:
        //this.message = "signUp.sign_up_message_password";
        break;
    }
  }

  changePasswordType(key: "passwordType" | "confirmPasswordType") {
    if (this[key] === "password") {
      this[key] = "text";
    } else {
      this[key] = "password";
    }
  }

  switchPrivacy() {
    this.form.value.privacy = !this.form.value.privacy;
    this.isSubmitEnabled();
  }

  private _getParameters() {
    this._route.params.subscribe((params) => {
      const { userId, validationToken } = params;
      this.form.controls.userId.setValue(userId);
      this.form.controls.validationToken.setValue(validationToken);
    });
  }
}
