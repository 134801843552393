<div class="full-container">
    <div class="top-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <svg-icon (click)="onClose.emit()" class="cursor-pointer"
            src="../../../../assets/img/icons/close-icon-regualar.svg"></svg-icon>
        <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
            <p class="title-container">{{document.fileName}}</p>
        </div>
    </div>

    <div class="preview-container">
        <pdf-viewer *ngIf="document.isPDF && document.globalLink" class="pdf-preview" [src]="document.globalLink"
            [zoom]="1" [original-size]="false" [autoresize]="false"></pdf-viewer>
        <img *ngIf="document.isImage && document.globalLink" class="image-content" [src]="document.globalLink">
    </div>
</div>