import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth/services/auth-guard.service";
import { CompatibilityErrorComponent } from "./core/compatibility-error/compatibility-error.component";
import { CompatibilityGuard } from "./shared/services/compatibility-guard.service";
import { ErrorPageComponent } from "./core/error/error-page.component";
import { HrbpGuard } from "./shared/services/hrbp-guard.service";
import { GuruGuard } from "./shared/services/guru-guard.service";
import { RecruiterGuard } from "./shared/services/recruiter-guard.service";
import { LocalLoginComponent } from "./auth/login/local-login.component";
import { LoginComponent } from "./auth/login/login.component";
import { UtmCampaignComponent } from "./auth/login/utm-campaign.component";
import { LoginGuard } from "./shared/services/login-guard.service";
import { LoginSamlErrorComponent } from "./auth/login/login-saml-error.component";
import { LogoutSsoCompletedComponent } from "./auth/login/logout-sso-completed.component";
import { NgModule } from "@angular/core";
import { ManagerGuard } from "./shared/services/manager-guard.service";
import { PageNotAuthorizedComponent } from "./core/page-not-authorized/page-not-authorized.component";
import { PageNotFoundComponent } from "./core/page-not-found/page-not-found.component";
import { SignUpComponent } from "./auth/sign-up/sign-up.component";
import { SignUpWelcomeComponent } from "./auth/sign-up/sign-up-welcome/sign-up-welcome.component";
import { NewlyHiredRecoveryUserPasswordComponent } from "./users/newlyHiredRecoveryUserPassword/newlyHiredRecoveryUserPassword.component";
import { DefaultPageComponent } from './core/defaultPage/default-page.component';
import { HrbpProGuardService } from "./shared/services/hrbp-pro-guard.service";
import { ZonemanagerGuardService } from "./shared/services/zonemanager-guard.service";

const appRoutes: Routes = [
  { path: 'utmCampaign', component: UtmCampaignComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  {
    path: "localLogin",
    component: LocalLoginComponent,
    canActivate: [LoginGuard, CompatibilityGuard],
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [LoginGuard, CompatibilityGuard],
  },
  { path: 'defaultPage', component: DefaultPageComponent, canActivate: [AuthGuard, CompatibilityGuard] },
  {
    path: "loginSamlError",
    component: LoginSamlErrorComponent,
    canActivate: [LoginGuard, CompatibilityGuard],
  },
  {
    path: "manager",
    loadChildren: () => import("./manager/manager.module").then((m) => m.ManagerModule),
    canActivate: [AuthGuard, CompatibilityGuard, ManagerGuard],
  },
  {
    path: "logoutSsoCompleted",
    component: LogoutSsoCompletedComponent,
    canActivate: [LoginGuard, CompatibilityGuard],
  },
  {
    path: "confirmRegistration/:userId/:validationToken",
    component: SignUpComponent,
    canActivate: [LoginGuard, CompatibilityGuard],
  },
  { path: 'newlyHired/recoveryUserPassword/:userId/:token', component: NewlyHiredRecoveryUserPasswordComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  {
    path: "sign-up-welcome",
    component: SignUpWelcomeComponent,
    canActivate: [LoginGuard, CompatibilityGuard],
  },
  {
    path: "hrbp",
    loadChildren: () => import("./hrbp/hrbp.module").then((m) => m.HrbpModule),
    canActivate: [AuthGuard, CompatibilityGuard, HrbpGuard],
  },
  {
    path: "hrbpPro",
    loadChildren: () => import("./hrbp-pro/hrbp-pro.module").then((m) => m.HrbpProModule),
    canActivate: [AuthGuard, CompatibilityGuard, HrbpProGuardService],
  },
  {
    path: "zonemanager",
    loadChildren: () => import("./zonemanager/zonemanager.module").then((m) => m.ZonemanagerModule),
    canActivate: [AuthGuard, CompatibilityGuard, ZonemanagerGuardService],
  },
  {
    path: "recruiter",
    loadChildren: () => import("./recruiter/recruiter.module").then((m) => m.RecruiterModule),
    canActivate: [AuthGuard, CompatibilityGuard, RecruiterGuard],
  },
  {
    path: "guru",
    loadChildren: () => import("./guru/guru.module").then((m) => m.GuruModule),
    canActivate: [AuthGuard, CompatibilityGuard, GuruGuard],
  },
  {
    path: "users",
    loadChildren: () =>
      import("./users/users.module").then((m) => m.UsersModule),
    canActivate: [AuthGuard, CompatibilityGuard],
  },
  { path: "compatibilityError", component: CompatibilityErrorComponent },
  { path: "error", component: ErrorPageComponent },
  { path: "403", component: PageNotAuthorizedComponent },
  { path: "", redirectTo: "defaultPage", pathMatch: "full" },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
