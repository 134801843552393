<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div class="login-page theme-primary" fxLayout="row" fxFlexFill>
    <div class="login-column" fxFlex.sm="80" fxFlex.gt-sm="60" fxLayout="column" fxLayoutAlign="center center"
        fxLayoutAlign.xs="start center">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
            <!-- header sx (menu, logo)-->
            <button attr.aria-label="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}">
                <img placement="bottom" height="25px" hide-delay="50" tooltip-class="custom-tooltip"
                    tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                    title="{{'accessibilityAttributes.GENERALI_LOGO' | translate}}"
                    alt="{{'accessibilityAttributes.GENERALI_LOGO' | translate}}"
                    src="assets/img/icons/logo_generali.svg">
            </button>

            <!-- header CENTER - LOGO -->
            <div class="header-linkApp-coursemanager" fxLayout="row" fxLayoutAlign="start center">
                <button attr.aria-label="{{ 'header.ONBOARDING_TOOLTIP' | translate }}">
                    <img placement="bottom" height="38px" hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.ONBOARDING_TOOLTIP' | translate }}"
                        title="{{ 'header.ONBOARDING_TOOLTIP' | translate }}"
                        alt="{{ 'header.ONBOARDING_TOOLTIP' | translate }}" src="assets/img/onboarding.png">
                </button>
            </div>
        </div>

        <div class="text-container-dimension" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.gt-xs="center center">
            <h1 *ngIf="currentStep != 2" class="title-page">{{ 'Accedi' | translate }}</h1>
            <h1 *ngIf="currentStep == 2" class="title-page">{{ 'Privacy policy' | translate }}</h1>
            <h2 *ngIf="currentStep == 2" class="subtitle-page">{{ 'Onboarding Up' | translate }}</h2>
            <div class="message" [ngClass]="{'privacy': currentStep == 2}" [innerHTML]="message | translate"></div>
            <div *ngIf="currentStep === 2" class="text-container-dimension checkboxes-container" fxLayout="row"
                fxLayoutAlign="start start">
                <item-document class="full-width" [isCheckbox]="true" [isChecked]="form.value.privacy"
                    (onCheckboxClicked)="switchPrivacy()" [isSignUp]="true" [lowerCaseTitle]="true"
                    [documentName]="('profile.SEEN' | translate)">
                </item-document>
            </div>
        </div>

        <form [formGroup]="form" class="login-wrapper" (submit)="onSubmit()">
            <!-- Email -->
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="currentStep === 0">
                <div class="generali-input small">
                    <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                        <h5 translate="generic.EMAIL"></h5>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <input class="full-width" style="border-radius: 5px;" type="email" id="email"
                            [ngModel]="form.value.email | lowercase"
                            placeholder="{{'accessibilityAttributes.INSERT_MAIL' | translate}}"
                            attr.aria-label="{{'accessibilityAttributes.INSERT_MAIL' | translate}}" name="email"
                            formControlName="email">
                    </div>
                    <!-- Errore di email non valida -->
                    <div *ngIf="!form.controls.email.valid && form.controls.email.touched" class="input-error"
                        fxLayout="row" fxLayoutAlign="start center">
                        <span translate="errors.EMAIL_NOT_VALID"></span>
                    </div>
                </div>
            </div>

            <div class="space15"></div>

            <div *ngIf="currentStep === 1">
                <!-- Password -->
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div class="generali-input small">
                        <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                            <h5 translate="generic.PASSWORD"></h5>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" style="position: relative;">
                            <input class="full-width" style="padding-right: 45px; border-radius: 5px;"
                                [type]="passwordType" id="password"
                                placeholder="{{'accessibilityAttributes.INSERT_PASSWORD' | translate}}"
                                attr.aria-label="{{'accessibilityAttributes.INSERT_PASSWORD' | translate}}"
                                name="password" formControlName="password">
                            <div class="show-password-ie" (click)="changePasswordType('passwordType')">
                                <span *ngIf="passwordType === 'password'" class="strikethrough"></span>
                                <span class="material-icons">
                                    remove_red_eye
                                </span>
                            </div>
                        </div>

                        <!-- Password non inserita -->
                        <div *ngIf="!this.form.controls.password.valid && this.form.controls.password.touched"
                            class="input-error" fxLayout="row" fxLayoutAlign="start center">
                            <span translate="errors.TYPE_PASSWORD_STRENGTH"></span>
                        </div>
                    </div>
                </div>

                <div class="space15"></div>

                <!-- Password confirmation -->
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div class="generali-input small">
                        <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
                            <h5 translate="generic.PASSWORD_CONFIRMATION"></h5>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" style="position: relative;">
                            <input class="full-width" style="padding-right: 45px; border-radius: 5px;"
                                [type]="confirmPasswordType" id="password_confirmation"
                                placeholder="{{'accessibilityAttributes.INSERT_PASSWORD' | translate}}"
                                attr.aria-label="{{'accessibilityAttributes.INSERT_PASSWORD' | translate}}"
                                name="password_confirmation" formControlName="password_confirmation">
                            <div class="show-password-ie" (click)="changePasswordType('confirmPasswordType')">
                                <span *ngIf="confirmPasswordType === 'password'" class="strikethrough"></span>
                                <span class="material-icons">
                                    remove_red_eye
                                </span>
                            </div>
                        </div>

                        <!-- Password non inserita -->
                        <div *ngIf="!this.form.controls.password_confirmation.valid && this.form.controls.password_confirmation.touched"
                            class="input-error" fxLayout="row" fxLayoutAlign="start center">
                            <span translate="errors.TYPE_PASSWORD_MATCH"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="space30"></div>

            <custom-button *ngIf="!showSubmit" dimension="btn-regular" importance="primary"
                text="{{ 'generic.CONTINUE' | translate }}" (click)="continue()" [disabled]="!isContinueBtnEnabled()">
            </custom-button>

            <custom-button *ngIf="showSubmit" dimension="btn-regular" importance="primary"
                text="{{ 'generic.CONTINUE' | translate }}" (click)="continue()" [disabled]="!isSubmitEnabled()">
            </custom-button>


            <div class="link-wrapper margin-top30" fxLayout="column" fxLayoutAlign="center center">
                <p class="label-title">{{ "login.ALREADY_SIGNED" | translate }}</p>
                <p class="link-red" (click)="_router.navigate(['localLogin'])" style="color: #C5281C!important;">
                    {{'generic.LOG-IN' | translate}}
                </p>
            </div>

            <div class="space60"></div>
        </form>

    </div>
</div>