import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from '../../shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
    selector: 'app-document-details-header',
    templateUrl: './document-details-header.component.html'
})
export class DocumentDetailsHeaderComponent {
    @Input() documentName: string;
    @Input() mainActionText: string;
    @Output() onMainActionClicked: EventEmitter<any> = new EventEmitter();

    constructor(public translate: TranslateService,
        public redirectService: RedirectService,
        public authService: AuthService) {
    }

    emitOnMainActionClicked() {
        this.onMainActionClicked.emit();
    }

    goToHome() {
        this.redirectService.goToHome();
    }
}