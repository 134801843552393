import * as CommonClasses from "../../../cm2-commonclasses";
import * as fromApp from "../../ngrx/app.reducers";

import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import { Observable, Subscription, combineLatest, of } from "rxjs";

import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { UrlService } from "src/app/shared/services/url.service";

@Injectable({ providedIn: 'root' })

export class HrbpProService {
  applicationData: GlobalApplicationData;
  result$: Subscription;
  applicationContext: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private urlService: UrlService
  ) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(
      fromApp.getGlobalApplicationData
    );
    const combinedSelectes$ = combineLatest(globalApplicationData$);
    this.result$ = combinedSelectes$.subscribe(([globalApplicationData]) => {
      this.applicationData = globalApplicationData;
      this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
    });
  }

  getNewlyhired(
    fromRecord?,
    numRecords?,
    searchText?,
    activePhases?,
    getOnlyMyNewRecruit?,
    smartHiringStatus?,
    managername?,
    agencyname?,
    userIds?,
    ){
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    httpParams = httpParams.append('searchedText', searchText);
    httpParams = httpParams.append('sorting', 'CREATION_DATE_DESC');
    httpParams = httpParams.append('withManagerLoaded', 'true');
    httpParams = httpParams.append('withRecruiterLoaded', 'false');
    if (activePhases) {
      activePhases.forEach(element => {
        httpParams = httpParams.append('activePhases', element);
      });
    }
    if (smartHiringStatus) {
      smartHiringStatus.forEach(element => {
        httpParams = httpParams.append('smartHiringStatus', element);
      });
    }
    httpParams = httpParams.append('getOnlyMyNewrecruit', getOnlyMyNewRecruit);
    httpParams = httpParams.append('managerName', managername);
    httpParams = httpParams.append('agencyName', agencyname);
    if (userIds) {
      userIds.forEach(element => {
        httpParams = httpParams.append('userIds', element);
      });
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-all-newrecruit-producer/",
      {
        params: httpParams,
      }
    );
  }

  importUsers(sessionStorageId, file){
    let formData = new FormData();
    formData.append("importFile", file);
    formData.append("hasHeadersToSkip", "true");
    formData.append("sessionStorageId", sessionStorageId);

    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/import-newrecruiter-producer-from-file/", formData
    );
  }

  countUserToDoList(getOnlyUnread?: boolean, getOnlyRead?: boolean){
    let httpParams = new HttpParams();
    httpParams = httpParams.append(
      "getOnlyUnread",
      getOnlyUnread ? "true" : "false"
    );
    httpParams = httpParams.append(
      "getOnlyRead",
      getOnlyRead ? "true" : "false"
    );

    httpParams = httpParams.append(
      "getStatusNotification", "true"
    );

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-todolist-counters-producer",
      {
        params: httpParams,
      }
    );
  }

  getUserToDoList(
    fromRecord,
    numRecords,
    searchText?,
    getOnlyUnread?: boolean,
    getOnlyRead?: boolean,
    getStatusNotification?: boolean,
    smartHiringStatus?,
    activePhases?,
    managerName?,
    agencyName?,
    getOnlyMyNewrecruit?
  ) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("fromRecord", fromRecord);
    httpParams = httpParams.append("numRecords", numRecords);
    httpParams = httpParams.append("searchText", searchText)
    httpParams = httpParams.append("getOnlyUnread", getOnlyUnread ? "true" : "false");
    httpParams = httpParams.append("getOnlyRead", getOnlyRead ? "true" : "false");
    httpParams = httpParams.append("getStatusNotification", getStatusNotification ? "true" : "false");
    smartHiringStatus.forEach(element => {
      httpParams = httpParams.append("smartHiringStatus", element);
    });
    activePhases.forEach(element => {
      httpParams = httpParams.append("activePhases", element);
    });
    httpParams = httpParams.append("managerName", managerName);
    httpParams = httpParams.append("agencyName", agencyName);
    httpParams = httpParams.append("getOnlyMyNewrecruit", getOnlyMyNewrecruit ? "true" : "false");

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-todolist-list-producer/" +
      fromRecord +
      "/" +
      numRecords,
      {
        params: httpParams
      }
    );
  }

  getMaritalStatus(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-stato-civile/", {}
    );
  }

  getProvinceList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-province-list/", {}
    );
  }

  getCities(province?){
    let httpParams = new HttpParams();
    httpParams = httpParams.append("provincia", province);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-comuni-list",
      {
        params: httpParams,
      }
    );
  }

  getQualifications(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-titolo-studio/", {}
    );
  }

  getSpecializations(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-specializzazioni/", {}
    );
  }

  createNewlyhired(user, sessionStorageId){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/create-or-update-newrecruit-producer/",
      {
        user: user,
        sessionStorageId: sessionStorageId
      }
    );
  }

  getRecruiterList(numRecords, fromRecord, searchText) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("numRecords", numRecords);
    httpParams = httpParams.append("fromRecord", fromRecord);
    httpParams = httpParams.append("searchText", searchText || '');
    httpParams = httpParams.append("roleName", "ONBOARDING_RECRUITER")

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-user-list-by-role/",
      {
        params: httpParams,
      }
    );
  }

  getDistinctUsersFieldValues(field: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("field", field);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-distinct-users-field-values",
      {
        params: httpParams,
      }
    );
  }

  getUserList() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-tipo-dipendente/"
    );
  }

  getChiefValues(socDistacco?) {
    let httpParams = new HttpParams();
    if(socDistacco) httpParams = httpParams.append("socDistacco", socDistacco);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-distinct-users-chief-values",
      {
        params: httpParams,
      }
    );
  }

  getRemedyManagers( socAssunzione?, includeDisabled?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("includeDisabled", includeDisabled ? 'true' : 'false');
    if(socAssunzione) httpParams = httpParams.append("socDistacco", socAssunzione);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-menudata-manager/",
      {
        params: httpParams,
      }
    );
  }

  getStatus(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-producer-phases/",
      {}
    );
  }

  getSH(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-smart-hiring-list/",
      {}
    );
  }

  lockUser(userId, statusProcessProduction, statusProcessProductionNote){

    let ids = [];
    userId.forEach(element => {
      ids.push(element.userId);
    });

    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/set-status-process-production/",
      {
        statusProcessProduction: statusProcessProduction,
        statusProcessProductionNote: statusProcessProductionNote,
        userId: ids
      }
    );
  }

  exportUsersById(fromRecord, numRecords, searchedText, userIds){
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('sorting', 'CREATION_DATE_DESC');
    userIds.forEach(element => {
      httpParams = httpParams.append('userIds', element.userId);
    });
    httpParams = httpParams.append('skipEmail', 'true');

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/create-report-new-recruit-producer/",
      {
        params: httpParams,
      }
    );
  }


  getJourneyStatus(userId){
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('viewType', 'HRBP');

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-journey-status-by-userid-for-producer/",
      {
        params: httpParams,
      }
    );
  }

  getDocuments(userId, step){
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('step', step);
    httpParams = httpParams.append('searchStatus', 'true');

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-validation-by-step-producer/",
      {
        params: httpParams,
      }
    );
  }

  sendMail(ids){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/send-account-validation-mail-producer/",
      {
        userIds: ids
      }
    );
  }

  getNumberNewRecruit_Dashboard() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-entry-by-months-for-producer/"
    );
  }

  getAverageTimeApproval_Dashboard() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-average-time-approval-for-producer/"
    );
  }

  getNewRecruitCountByPhase_Dashboard() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-count-by-phase-for-producer/"
    );
  }

  getNewRecruitGroupByProperty_Dashboard(propertyName: string) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-group-by-property-for-producer/",
      { params: { propertyName } }
    );
  }

  generateLetters(userId, startDate, endDate){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/send-engagement-letters-for-producer/",
      {
        userId: userId,
        startDate: startDate,
        endDate:endDate
      }
    );
  }

  startSmartHiring(userIds, newRecruit){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-create-smart-hiring-ticket-producer/",
      {
        userIds: userIds,
        newRecruit: newRecruit
      }
    );
  }

  getRecruitById(userId) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("newRecruitId", userId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-newrecruit-by-id/",
      {
        params: httpParams,
      }
    );
  }

    // Take a temporary token for get the external resources
  //this token can only be used once
  getTemporaryToken(validationId: string, uploadId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("validationId", validationId);
    httpParams = httpParams.append("uploadId", uploadId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-masked-url/",
      {
        params: httpParams,
      }
    );
  };

  acceptDocumentValidationForProducer(validationId: string, documentType?: string) {
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/accept-document-validation-by-hrbp-for-producer",
      {
        validationId,
        documentType
      }
    );

  }

  rejectDocumentValidationForProducer(validationId: string, documentType?: string, statusNote?: string) {

    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/reject-document-validation-for-producer/",
      {
        validationId,
        documentType,
        statusNote
      }
    );
  }

  getDocumentsValidationById(validationId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("validationId", validationId);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-document-validation-by-id/",
      {
        params: httpParams,
      }
    );
  }

  sendRequestWithFile(file, userId, fileName, documentType, step, expectedEndDate) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("userId", userId);
    formData.append("documentType", documentType);
    formData.append("step", step);
    formData.append("expectedEndDate", expectedEndDate);

    const req = new HttpRequest(
      "POST",
      this.applicationContext +
      "rest-api/onboarding-mediator/request-and-upload-document-validation-for-producer",
      formData
    );

    return this.http.request(req);
  }

  uploadDocumentValidation(uploadFile, userId, body, filename) {
    let formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("fileName", filename);
    formData.append("userId", userId);

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        formData.append(key, body[key]);
      }
    }

    let params = new HttpParams();
    const options = {
      params: params,
    };
    const req = new HttpRequest(
      "POST",
      this.applicationContext +
      "rest-api/onboarding-mediator/upload-document-validation-for-producer",
      formData,
      options
    );
    return this.http.request(req);
  }

  setDocsNotificationAsRead(notificationQueueId){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/mark-notification-as-read/",
      {
        notificationQueueId
      }
    );
  }

  getDocumentValidationByStep(userId, step: "CONTROLLO-DOCUMENTI" | "LETTERA-ASSUNZIONE") {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("step", step);
    httpParams = httpParams.append("searchStatus", "true");
    httpParams = httpParams.append("userId", userId);


    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
        "rest-api/onboarding-mediator/get-document-validation-by-step-producer/",
      {
        params: httpParams
      }
    );
  }

  getRemedyWorkplace(includeDisabled?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("includeDisabled", includeDisabled ? 'true' : 'false');
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-menudata-workplace/",
      {
        params: httpParams,
      }
    );
  }

  getClassifications(company?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('includeDisabled', 'true');
    if (company) {
      httpParams = httpParams.append('company', company);
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-inquadramento-for-remedy/",
      {
        params: httpParams
      }
    );
  }

  getRemedyEquipmentsProfile(company?: string, equipmentProfile?: string, includeDisabled?: boolean, userType?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("includeDisabled", includeDisabled ? 'true' : 'false');
    httpParams = httpParams.append("company", company);
    httpParams = httpParams.append("equipmentProfile", equipmentProfile);
    if (userType) {
      httpParams = httpParams.append("userType", userType);
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-menudata-equipments-profile/",
      {
        params: httpParams,
      }
    );
  }

  getRequiredEquipments(userType) {
    let httpParams = new HttpParams();
    // if (Array.isArray(userType)) {
    //   userType.forEach(type => {
    //     httpParams = httpParams.append("userType", type);
    //   });
    // } else {
    // }

    httpParams = httpParams.append("userType", userType);

    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-get-menudata-manager/",
      {
        params: httpParams,
      }
    );
  }

  startSH(userIds, newRecruit){
    return this.http.post<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/remedy-create-smart-hiring-ticket-producer-massive/",
      {
        userIds: userIds,
        newRecruit: newRecruit
      }
    );
  }

  getDocumentsOfUserByParameters(parameters) {
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-upload-by-user-and-parameter/",
      {
        params: parameters,
      }
    );
  }

  getZoneManagers(){
    return this.http.get<CommonClasses.SenecaResponse<any>>(
      this.applicationData.applicationContext +
      "rest-api/onboarding-mediator/get-zone-managers/",{}
    );
  }
}
