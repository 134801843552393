<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div class="login-page theme-primary">
    <div class="login-column">
        <div class="logo-container">
            <svg-icon src="assets\img\congratulation.svg"></svg-icon>
        </div>
        <div class="title" fxLayout="column" fxLayoutAlign="center center">
            <h2>{{ 'welcome.welcome' | translate }}</h2>
            <div class="border-div"></div>
        </div>

        <message [text]="message | translate" [closable]="false" imgSrc="assets/img/icons/welcome-icon.png"></message>

        <div id="video-container">
            <kaltura-player [playerVisible]="true" [kalturaScript]="kalturaVideoScript" customId="introKalturaVideo">
            </kaltura-player>
        </div>

        <custom-button class="full-width" fxLayoutAlign="center center" [style.margin-top]="'12px'" [uppercase]="false" dimension="btn-regular" importance="primary" [text]="'welcome.letsGo' | translate" (onButtonClicked)="letsGo()">
        </custom-button>
    </div>
</div>