import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import { TranslateService } from '@ngx-translate/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Lang } from "../../../cm2-commonclasses";
import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-newlyHiredRecoveryUserPassword',
  templateUrl: './newlyHiredRecoveryUserPassword.component.html',
  styleUrls: ['./newlyHiredRecoveryUserPassword.component.scss']
})
export class NewlyHiredRecoveryUserPasswordComponent extends BaseSubscriberComponent implements OnInit, OnDestroy {
  isFetchingLangs: boolean;
  langs: Lang[];
  result$;
  userId: string;
  token: string;
  showLoader: boolean;
  isManagingUser$: Subscription;
  resetPswEmail;
  passwordType: string = "password";

  constructor(private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: ModalService) {
    super();

    const availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
    const isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    const combinedSelectes$ = combineLatest([availableLangs$, isFetchingLangs$]);
    this.result$ = combinedSelectes$.subscribe(
      ([langs, isFetchingLangs]) => {
        this.langs = langs;
        this.isFetchingLangs = isFetchingLangs;
      });
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.userId = params['userId'];
          this.token = params['token'];
        });
  }

  // Dal form passato come parametro, ricavo i dati immessi dall'utente per il recupero della password
  onRecoverPassword(form: NgForm) {
    this.showLoader = true;
    // Chiamo il servizio per inviare la mail
    this.isManagingUser$ = this.authService.recoverUserPassword(form.value.repeatPassword, this.userId, this.token)
      .subscribe(data => {
        this.showLoader = false;
        // Se ci sono errori, li mostor e torno alla lista dei template
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          // Mostro il messaggio di avviso
          this.modalService.open('successModal');
        }
      }
        , (err) => {
          this.toastr.error(this.translate.instant('errors.' + err.message));
          this.showLoader = false;
        });
  }

  changePasswordType() {
    if (this.passwordType === "password") {
      this.passwordType = "text";
    } else {
      this.passwordType = "password";
    }
  }

  // Chiude la modale di operazione avvenuta
  closeSuccessModal() {
    this.modalService.close('successModal');
    // Vado al login
    this.router.navigate(["localLogin"]);
  }

  // Dal form passato come parametro, controllo se le due password nuove sono uguali
  areNewPswSame(form: NgForm) {
    const newPsw = form && form.value && form.value.newPsw;
    const newPswRepeated = form && form.value && form.value.repeatPassword;
    if (newPsw && newPswRepeated && newPsw === newPswRepeated) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.result$) {
      this.result$.unsubscribe();
    }
    if (this.isManagingUser$) {
      this.isManagingUser$.unsubscribe();
    }
  }
}