import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class MessageComponent implements OnInit {
  @Input() text: string;
  @Input() closable?: boolean = false;
  @Input() imgSrc: string;
  @Input() fromRight?: boolean = false;
  @Input() whiteBackground?: boolean = false;
  @Input() customImageStyle?: any = {};
  @Input() containerStyle?: any = {};

  @Output() onIconClicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  clickClose() {
    this.onIconClicked.emit(this.text);
  }
}
