import { Component } from "@angular/core";

import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsService } from "src/app/shared/services/analytics.service";

@Component({
  selector: "app-sign-up-welcome",
  templateUrl: "sign-up-welcome.component.html",
  styleUrls: ["./sign-up-welcome.component.scss"],
})
export class SignUpWelcomeComponent {

  message: string;
  // tslint:disable-next-line: max-line-length
  kalturaVideoScript: string = `<script src="https://cdnapisec.kaltura.com/p/1936311/sp/193631100/embedIframeJs/uiconf_id/42700011/partner_id/1936311?autoembed=true&entry_id=1_2ipmwvw6&playerId=introKalturaVideo&cache_st=1545652499&width=733&height=322"></script>`;
  constructor(private analyticsService: AnalyticsService, private _translate: TranslateService, private _router: Router) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    this.analyticsService.sendVirtualPageViewEvent(this._router.url, "Benvenuto/a");
    this.message = `${this._translate.instant("welcome.hi", {
      user: "Neo Assunta",
    })}<br />${this._translate.instant("welcome.welcome_message")}`;
  }

  letsGo() {
    this._router.navigate(["localLogin"]);
  }
}
