<div class="fixHeader">
    <div class="content">
        <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="start center"
            fxLayoutAlign.gt-sm="space-between center">
    
            <div class="main-content" aria-level="1" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start center" role="heading">
                <!-- Azione principale (modalità mobile)-->
                <div fxHide.gt-xs *ngIf="mainActionText" class="header-linkApp-coursemanager" fxLayout="row"       
                    fxLayoutAlign="start center">
                    <div class="section-profile">
                        <svg-icon class="main-action-text" role="button" tabindex="0" src="../../../assets/img/icons/back-button-icon.svg" (click)="emitOnMainActionClicked()">
                        </svg-icon>
                    </div>
                </div>

                <button fxHide.xs (click)="goToHome()" class="logo-wrapper icon-style-second-header"
                    attr.aria-label="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}">
                    <img placement="bottom" hide-delay="50" tooltip-class="custom-tooltip"
                        tooltip="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        title="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}"
                        alt="{{ 'header.BACK_TO_HOME_TOOLTIP' | translate }}" class="logo-generali"
                        src="assets/img/icons/logo_generali.svg">
                </button>

                <div *ngIf="documentName" class="header-linkApp-coursemanager full-width" fxLayout="row"
                    fxLayoutAlign="center center">
                    <p class="document-title" tabindex="0">{{ documentName | translate }}</p>
                </div>

                <!-- Azione principale (modalità desktop)-->
                <div fxHide.xs *ngIf="mainActionText" class="header-linkApp-coursemanager" fxLayout="row"
                    fxLayoutAlign="start center">
                    <div class="section-profile">
                        <p class="main-action-text" role="button" tabindex="0" (click)="emitOnMainActionClicked()">
                            {{ mainActionText | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>