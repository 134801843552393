import * as CommonClasses from "../../../cm2-commonclasses";

import {
  CommonConstants,
  UserAcknowledges,
  UserOptions,
} from "../../../cm2-commonclasses";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UrlService } from "../../shared/services/url.service";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  applicationContext: string;
  constructor(
    private urlService: UrlService,
    private router: Router,
    private http: HttpClient
  ) {
    this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
  }

  // Servizio che tenta il login
  login(
    email: string,
    password: string,
    device?: string,
    userAgent?: string
  ): Observable<CommonClasses.SenecaResponse<string>> {
    return this.http.post<CommonClasses.SenecaResponse<string>>(
      this.applicationContext +
      "rest-api/onboarding-mediator/login-local-password",
      {
        email,
        password,
        langCode: "it",
        deviceType: device,
        userAgent,
      }
    );
  }

  // Servizio che aggiorna una ack
  updateUserAcknowledges(userAcknowledges: UserAcknowledges): any {
    return this.http.post<CommonClasses.SenecaResponse<string>>(
      this.applicationContext +
      "rest-api/onboarding-mediator/update-userAcknowledges",
      {
        originApplicationName: CommonConstants.APPLICATION_TRAINING_PLATFORM,
        userAcknowledges,
      }
    );
  }

  // Servizio recupera la password dell'utente
  recoverUserPassword(newPassword: string, userId: string, validationToken: string): any {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/onboarding-mediator/recover-user-password', {
      newPassword: newPassword,
      userId: userId,
      validationToken: validationToken
    });
  }

  sendMailReconveryPassword(email) {

    return this.http.get<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/onboarding-mediator/init-password-recovery', {
      params: { email }
    });
  }

  // Servizio che recupera il JWT token dato un tiny token
  getAllUserAcknowledges() {
    return this.http.get<CommonClasses.SenecaResponse<UserAcknowledges>>(
      this.applicationContext +
      "rest-api/onboarding-mediator/get-all-userAcknowledges"
    );
  }

  logout() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/onboarding-mediator/logout', {
    });
  }

  updateUserOptions(userOptions: UserOptions, acceptedPrivacy?: boolean) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(
      this.applicationContext +
      "rest-api/onboarding-mediator/update-userOptions",
      {
        userOptions, acceptedPrivacy
      }
    );
  }

  isAdminMode() {
    // Verifica se sono nella parte amministrativa
    if (this.router.url && this.router.url.indexOf("hrbp") !== -1) {
      return true;
    } else {
      return false;
    }
  }

  isRecruiter() {
    // Verifica se sono nella sezione Recruiter
    if (this.router.url && this.router.url.indexOf("recruiter") !== -1) {
      return true;
    } else {
      return false;
    }
  }

  isManager() {
    // Verifica se sono nella sezione Manager
    if (this.router.url && this.router.url.indexOf("manager") !== -1) {
      return true;
    } else {
      return false;
    }
  }

  isGuru() {
    // Verifica se sono nella sezione Guru
    if (this.router.url && this.router.url.indexOf("guru") !== -1) {
      return true;
    } else {
      return false;
    }
  }

  isHrbpPro() {
    // Verifica se sono nella sezione HrbpPro
    if (this.router.url && this.router.url.indexOf("hrbpPro") !== -1) {
      return true;
    } else {
      return false;
    }
  }

  isZonemanager() {
    // Verifica se sono nella sezione HrbpPro
    if (this.router.url && this.router.url.indexOf("zonemanager") !== -1) {
      return true;
    } else {
      return false;
    }
  }

  // Metodo per la gestione dei diritti e autenticazione
  isUserAuthorized(requiredAuthId: string, auths: string[]) {
    let isAuthorized: boolean = false;

    for (let i = 0, authsLength = auths.length; i < authsLength; i++) {
      const currentAuth = auths[i];
      if (currentAuth === requiredAuthId) {
        isAuthorized = true;
        break;
      }
    }
    return isAuthorized;
  }

  // Servizio che recupera il JWT token dato un tiny token
  getJWTToken(token: string): Observable<CommonClasses.SenecaResponse<string>> {
    return this.http.get<CommonClasses.SenecaResponse<string>>(this.applicationContext +
      'rest-api/onboarding-mediator/get-full-jwt-token',
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + token)
      });
  }

  // Servizio che recupera un token nuovo
  renewToken(sessionStorageToken: string, forceRefreshUser?: boolean): any {
    return this.http.post<CommonClasses.SenecaResponse<string>>(
      this.applicationContext + "rest-api/onboarding-mediator/renew-token",
      { forceRefreshUser },
      {
        headers: new HttpHeaders().set(
          "Authorization",
          "Bearer " + sessionStorageToken
        )
      }
    );
  }

  // Servizio che setta una nuova password
  setNewPassword(oldPassword: string, newPassword: string): any {
    return this.http.post<CommonClasses.SenecaResponse<string>>(
      this.applicationContext + "rest-api/onboarding-mediator/set-new-password",
      { oldPassword, newPassword }
    );
  }

  confirmNewRecruiter(body: any): Promise<any> {
    return this.http
      .post<CommonClasses.SenecaResponse<string>>(
        `${this.applicationContext}rest-api/onboarding-mediator/confirm-new-recruit-account`,
        body
      )
      .toPromise();
  }

  canConfirmNewRecruiter(body: any): Promise<any> {
    return this.http
      .post<CommonClasses.SenecaResponse<string>>(
        `${this.applicationContext}rest-api/onboarding-mediator/can-confirm-new-recruit-account`,
        body
      )
      .toPromise();
  }

  // Ritrova il token dall'ssortkqp
  retrieveTokenAfterLogin(retrieveKey: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('retrieveKey', retrieveKey);
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/onboarding-mediator/retrieve-token-after-login', {
      params: httpParams
    });
  }

  /** Effettua il login sul nodo NewRecruit o Person a seconda di quello attuale */
  switchLoggedNode(params: {
    deviceType: string;
    userAgent: string;
  }): Observable<CommonClasses.SenecaResponse<string>> {
    return this.http
      .post<CommonClasses.SenecaResponse<string>>(
        `${this.applicationContext}rest-api/onboarding-mediator/switch-logged-node`,
        {
          langCode: "it",
          deviceType: params.deviceType,
          userAgent: params.userAgent
        }
      );
  }
}
